.sidebar {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  flex: 0 0 64px;
  flex-shrink: 0;
  box-sizing: border-box;
  /* background: linear-gradient(to bottom, #cb2d3e 0%, #cb2d3e 100%); */
  opacity: 90%;
  height: 100vh;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: visible;
  position: relative;
  z-index: 1050;
}

[data-theme='dark'] .sidebar {
  background: linear-gradient(to bottom, #b01f2e 0%, #b01f2e 100%);
}
[data-theme='light'] .sidebar {
  background: linear-gradient(to bottom, #cb2d3e 0%, #cb2d3e 100%);
}

.logo-item img {
  max-width: 80%; /* Adjust to make the logo smaller */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensures that the img element is block-level for centering */
  margin: 0 auto; /* Center the logo horizontally within the logo-item */
}

.logo-text {
  position: absolute;
  top: 50%; /* Align vertically */
  left: 100%; /* Position to the right of the logo */
  transform: translate(0, -50%); /* Center tooltip vertically relative to the logo */
  white-space: nowrap;
  background-color: #ffffff; /* White background */
  color: #333; /* Dark text for contrast */
  padding: 6px 12px; /* Sufficient padding for the text */
  margin-left: 8px; /* Space between the icon and the tooltip */
  border-radius: 10px; /* Rounded corners for the tooltip */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 0.75rem; /* Appropriate font size */
  z-index: 100; /* Ensure the tooltip is above all other content */
  visibility: hidden; /* Hide tooltip by default */
  opacity: 0;
  transition:
    opacity 0.1s ease,
    visibility 0s linear 0.1s; /* Smooth transition */
  pointer-events: none; /* Prevents tooltip from blocking clicks to other items */
}

.logo-item {
  margin: 4px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.2s ease;
  border-radius: 12px;
}

.logo-item:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

.icon-black {
  color: var(--sidebar-icon-color);
  transition: all 0.2s ease;
  transform: scale(1.6);
  opacity: 0.9;
  display: block;
}

[data-theme='dark'] .icon-black {
  color: #ffffff;
}

.logo-item:hover .icon-black {
  opacity: 1;
  transform: scale(1.7);
}

.icon-pen.icon-pen {
  transform: scale(1.4);
  color: var(--sidebar-icon-color);
}

[data-theme='dark'] .icon-pen.icon-pen {
  color: #ffffff;
}

.logo-item:hover .icon-pen.icon-pen {
  transform: scale(1.5);
  opacity: 1;
}

.logo-item-bottom {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.logout-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.logout-button:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

.logout-button .icon-black {
  transform: scale(1.5);
}

.logout-button:hover .icon-black {
  transform: scale(1.6);
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;
}

.user-info:hover .user-avatar {
  border-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

.user-avatar-logo {
  width: 36px;
  margin: 4px 0;
  transition: all 0.2s ease;
  filter: brightness(0.9);
}

.user-info:hover .user-avatar-logo {
  filter: brightness(1);
  transform: scale(1.9);
}

.user-details {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  color: #333;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  transition:
    visibility 0s linear 0.1s,
    opacity 0.1s ease;
  top: 50%;
  left: calc(100% + 5px);
  transform: translateY(-50%);
}

.user-info:hover .user-details {
  visibility: visible;
  opacity: 1;
}

/* Sidebar.css */
.icon-tooltip {
  position: absolute;
  top: 50%;
  left: 100%; /* Position to the right of the icon */
  transform: translate(10px, -50%); /* Align vertically and add horizontal space */
  white-space: nowrap;
  background-color: #ffffff;
  color: #333;
  padding: 6px 12px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  visibility: hidden; /* Hide tooltip by default */
  opacity: 0;
  transition:
    visibility 0s linear 0.1s,
    opacity 0.1s ease-in-out;
  z-index: 1000; /* Maximal z-index to ensure it's on top */
  pointer-events: none; /* Ensures that the tooltip does not block click events */
}

.logo-item:hover .icon-tooltip {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; /* Show tooltip immediately on hover */
}

.MuiTooltip-tooltip {
  background-color: var(--bg-primary) !important;
  backdrop-filter: blur(8px);
  border-radius: 6px !important;
  padding: 6px 10px !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.3px !important;
  border: 1px solid var(--border-color) !important;
  box-shadow: 0 4px 12px var(--shadow-color) !important;
  color: var(--text-primary) !important;
}

.MuiTooltip-tooltip h2 {
  margin: 0;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  color: var(--text-primary) !important;
}

.MuiTooltip-arrow {
  color: var(--bg-primary) !important;
  width: 10px !important;
  height: 10px !important;
}

/* Add spinning animation for loader icon */
.icon-black.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: scale(1.6) rotate(0deg);
  }
  to {
    transform: scale(1.6) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

/* Remove the old tooltip styles */
.logo-text,
.icon-tooltip,
.user-details {
  display: none;
}

/* Add styles for active menu items */
.logo-item.active {
  background: rgba(255, 255, 255, 0.15);
  position: relative;
}

.logo-item.active::before {
  content: '';
  position: absolute;
  left: -8px; /* Align with sidebar edge */
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 3px;
  background: white;
  border-radius: 0 2px 2px 0;
}

/* Adjust hover effect for active items */
.logo-item.active:hover {
  transform: none;
}

.theme-toggle {
  margin: 4px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.2s ease;
  border-radius: 12px;
}

.theme-toggle .icon-black {
  transform: scale(1.5);
  transition: all 0.2s ease;
}

.theme-toggle:hover .icon-black {
  transform: scale(1.6);
  color: #ffffff;
}

.theme-toggle:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

/* Add transition for theme changes */
:root {
  transition: all 0.2s ease;
}

/* Add disabled styles */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Update Menu component styles with better hover effects */
.MuiMenu-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: none !important;
  box-shadow: 0 4px 12px var(--shadow-color) !important;
}

.MuiMenuItem-root {
  color: var(--text-primary) !important;
  padding: 8px 16px !important;
  transition: background-color 0.2s ease !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--hover-bg) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--hover-bg) !important;
  font-weight: 500 !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--hover-bg) !important;
}

/* Remove the old tooltip styles */
.logo-text,
.icon-tooltip,
.user-details {
  display: none;
}

/* Add styles for active menu items */
.logo-item.active {
  background: rgba(255, 255, 255, 0.15);
  position: relative;
}

.logo-item.active::before {
  content: '';
  position: absolute;
  left: -8px; /* Align with sidebar edge */
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 3px;
  background: white;
  border-radius: 0 2px 2px 0;
}

/* Adjust hover effect for active items */
.logo-item.active:hover {
  transform: none;
}

.theme-toggle {
  margin: 4px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.2s ease;
  border-radius: 12px;
}

.theme-toggle .icon-black {
  transform: scale(1.5);
  transition: all 0.2s ease;
}

.theme-toggle:hover .icon-black {
  transform: scale(1.6);
  color: #ffffff;
}

.theme-toggle:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

/* Add transition for theme changes */
:root {
  transition: all 0.2s ease;
}

/* Add disabled styles */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Update Menu component styles with better hover effects */
.MuiMenu-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: none !important;
  box-shadow: 0 4px 12px var(--shadow-color) !important;
}

.MuiMenuItem-root {
  color: var(--text-primary) !important;
  padding: 8px 16px !important;
  transition: background-color 0.2s ease !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--hover-bg) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--hover-bg) !important;
  font-weight: 500 !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--hover-bg) !important;
}

/* Remove the old tooltip styles */
.logo-text,
.icon-tooltip,
.user-details {
  display: none;
}

/* Add styles for active menu items */
.logo-item.active {
  background: rgba(255, 255, 255, 0.15);
  position: relative;
}

.logo-item.active::before {
  content: '';
  position: absolute;
  left: -8px; /* Align with sidebar edge */
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 3px;
  background: white;
  border-radius: 0 2px 2px 0;
}

/* Adjust hover effect for active items */
.logo-item.active:hover {
  transform: none;
}

.theme-toggle {
  margin: 4px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.2s ease;
  border-radius: 12px;
}

.theme-toggle .icon-black {
  transform: scale(1.5);
  transition: all 0.2s ease;
}

.theme-toggle:hover .icon-black {
  transform: scale(1.6);
  color: #ffffff;
}

.theme-toggle:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

/* Add disabled styles */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Update Menu component styles with better hover effects */
.MuiMenu-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: none !important;
  box-shadow: 0 4px 12px var(--shadow-color) !important;
}

.MuiMenuItem-root {
  color: var(--text-primary) !important;
  padding: 8px 16px !important;
  transition: background-color 0.2s ease !important;
}

.MuiMenuItem-root:hover {
  background-color: var(--hover-bg) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--hover-bg) !important;
  font-weight: 500 !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--hover-bg) !important;
}
