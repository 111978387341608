/* src/styles/AlertPopup.css */
.alert-popup {
  width: 100%;
  max-width: 530px;
  background-color: var(--bg-primary);
  overflow: hidden;
}

.video-popup {
  height: fit-content;
  padding-bottom: 0px;
}

.alert-header {
  padding: 5px 15px 10px;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.alert-info {
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
}

.alert-assigned-to {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 5px;
  width: fit-content;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.alert-timestamp {
  font-size: 0.8em;
  color: var(--text-secondary);
  margin: 0 !important;
  /* margin-bottom: 10px; */
}

.alert-video {
  width: 100%;
}

.alert-body {
  padding: 10px 15px 15px;
  background-color: var(--bg-primary);
}

/* Video player styles */
.alert-video .video-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.description,
.keyword-description {
  color: var(--text-primary);
  margin: 0;
}

.keyword-description {
  font-weight: bold;
}

/* Mobile styles */
@media (max-width: 768px) {
  .alert-popup {
    width: 100%;
    max-width: none;
  }

  .alert-header {
    padding: 10px 10px 5px;
  }

  .alert-assigned-to {
    font-size: 0.7em;
  }

  .alert-timestamp {
    font-size: 0.7em;
  }

  .alert-body {
    padding: 0 10px 10px;
  }

  .video-container {
    width: 100%;
    max-width: none;
  }
}

/* Leaflet popup overrides */
.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 8px;
  background-color: var(--bg-primary);
  box-shadow: 0 2px 8px var(--shadow-color);
}

.leaflet-popup-content {
  margin: 0;
  width: 100% !important;
}

.leaflet-popup-tip {
  background-color: var(--bg-primary);
}

.video-popup .leaflet-popup-content-wrapper {
  width: 530px;
}

@media (max-width: 768px) {
  .video-popup .leaflet-popup-content-wrapper {
    width: calc(100vw - 10px);
  }
}

@media (max-width: 768px) {
  .plyr--video {
    --plyr-control-spacing: 5px;
    --plyr-control-icon-size: 12px;
    --plyr-font-size-base: 12px;
    --plyr-font-size-small: 11px;
    --plyr-font-size-large: 13px;
  }

  .plyr__controls {
    padding: 5px;
  }

  .plyr__control {
    padding: 5px;
  }

  .plyr__time {
    font-size: 10px;
  }

  .plyr__progress__container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .plyr__volume {
    max-width: 50px;
  }
}

.video-placeholder {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 500px;
  background-color: var(--bg-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-placeholder .placeholder-icon {
  font-size: 48px;
  color: var(--text-secondary);
}

.video-placeholder p {
  margin-top: 10px;
  color: var(--text-secondary);
  font-size: 14px;
}
