.metrics-container {
  display: flex;
  flex-direction: column;
  /* overflow: visible; */
  /* height: 100%; */
  padding: 20px;
  /* width: 100%; */
  background-color: var(--bg-primary);
}

.metrics-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

/* Keep the responsive layout here since it's about grid layout */
@media (max-width: 1200px) {
  .metrics-card {
    max-width: calc(33.33% - 15px);
  }
}

@media (max-width: 900px) {
  .metrics-card {
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 600px) {
  .metrics-card {
    max-width: 100%;
  }
}
