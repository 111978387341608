/* Styles for PlatformAccessControls component */

.platform-access-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.platform-access-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.platform-access-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

/* Personnel info section */
.platform-access-personnel-info {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  margin-bottom: 16px;
}

.platform-access-personnel-icon {
  font-size: 18px;
  margin-right: 16px;
  color: var(--primary-color);
}

.platform-access-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Card styles */
.platform-access-card {
  width: 100%;
  /* max-width: 600px; */
  background-color: var(--bg-primary) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 6px !important;
}

.platform-access-card-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.platform-access-card-header h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: var(--text-primary);
}

.platform-access-card-header p {
  font-size: 14px;
  color: var(--text-secondary);
}

.platform-access-card-content {
  padding: 16px;
}

/* Switch styles */
.platform-access-switch.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  color: var(--button-bg) !important;
}

.platform-access-switch.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked:hover {
  background-color: rgba(203, 45, 62, 0.08) !important;
}

.platform-access-switch.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--button-bg) !important;
}

.platform-access-limited .MuiFormControlLabel-label {
  color: var(--text-primary) !important;
}

.platform-access-save-container {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.platform-access-save-button {
  min-width: 120px;
  padding: 8px 16px;
  background-color: var(--button-bg) !important;
  color: white !important;
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.platform-access-save-button:hover {
  background-color: var(--button-hover-bg) !important;
}

/* Add these new styles for disabled state */
.platform-access-save-button:disabled {
  background-color: var(--button-disabled-bg) !important;
  color: var(--text-primary) !important;
}

.platform-access-save-button:disabled:hover {
  background-color: var(--button-disabled-bg) !important;
}

/* Make form controls consistent with other settings sections */
.platform-access-card .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
  padding: 8px 0;
}

.platform-access-card .MuiSwitch-root {
  margin-right: 12px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .platform-access-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .platform-access-header h2 {
    margin-bottom: 8px;
  }
}
