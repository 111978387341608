.dashboard-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  height: fit-content;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.dashboard-controls-left {
  display: flex;
  gap: 20px;
}

.dashboard-controls-right {
  margin-left: auto;
}

/* View selector styles */
.view-selector-controls {
  display: inline-flex;
  height: 40px;
  align-items: center;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
  border: none;
}

.view-toggle-btn {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.view-toggle-btn:hover {
  background: var(--hover-bg);
  color: var(--text-secondary);
}

.view-toggle-btn.active {
  background: var(--bg-primary);
  color: var(--text-primary);
  box-shadow: 0 1px 3px var(--shadow-color);
}

/* Date control styles */
.date-range-control {
  display: inline-flex;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
  align-items: center;
  height: 40px;
  border: none;
}

.date-range-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  background: var(--hover-bg);
  border-radius: 6px;
  min-width: 120px;
}

.date-range {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1;
}

.week-label {
  font-size: 10px;
  color: var(--text-secondary);
  line-height: 1;
  margin-top: 1px;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.arrow-button:hover:not(:disabled) {
  background: var(--hover-bg);
  color: var(--text-secondary);
}

.arrow-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: var(--text-secondary);
  background: transparent;
}

.arrow-button svg {
  font-size: 12px;
}
