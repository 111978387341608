/* GeofenceControls.css */
/* Using the same styling as MapControls for consistency */

.geofence-popup {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 9000; /* Higher z-index to ensure it's above site assignment */
  padding: 35px 20px 15px 20px !important; /* Increased top padding for X button */
  width: 360px;
  max-width: 90vw;
}

/* View mode specific styles */
.geofence-popup.view-mode {
  padding: 25px 20px 15px 20px !important;
}

.geofence-header {
  margin-bottom: 20px;
  width: 100%;
}

.geofence-header.view-mode {
  margin-bottom: 15px;
}

.geofence-title {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 0;
  border-radius: 4px;
}

.geofence-title h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: var(--text-primary);
}

.geofence-title .edit-icon {
  font-size: 14px;
  color: var(--text-secondary);
  opacity: 0;
  transition: opacity 0.2s ease;
}

/* .geofence-title:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
} */

/* .geofence-title:hover .edit-icon {
  opacity: 0.7;
} */

.geofence-title-input {
  width: 100%;
  padding: 10px 12px;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.03);
  transition: all 0.2s ease;
  box-sizing: border-box;
  margin-top: 10px;
}

.geofence-title-input:focus {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
  outline: none;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: var(--text-secondary);
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9001; /* Ensure it's above everything else */
}

.close-button:hover {
  color: var(--text-primary);
}

.geofence-address {
  font-size: 0.8em;
  color: var(--text-secondary);
}

.geofence-control {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.geofence-control label {
  font-size: 0.9em;
  color: var(--text-secondary);
  font-weight: bold;
}

.geofence-control input[type='range'] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  margin: 10px 0;
}

.geofence-control input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: linear-gradient(
    to right,
    var(--button-bg) 0%,
    var(--button-bg) var(--progress),
    var(--border-color) var(--progress),
    var(--border-color) 100%
  );
  border-radius: 4px;
}

.geofence-control input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--button-bg);
  cursor: pointer;
  margin-top: -6px;
}

.geofence-control input[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  background: var(--border-color);
  border-radius: 4px;
}

.geofence-control input[type='range']::-moz-range-progress {
  background: var(--button-bg);
  height: 8px;
  border-radius: 4px;
}

.geofence-control input[type='range']::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--button-bg);
  cursor: pointer;
}

.radius-value {
  font-weight: normal;
  margin-left: 10px;
}

.geofence-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.right-actions {
  display: flex;
  gap: 8px;
}

.delete-button {
  background-color: var(--danger-color, #ff4d4f);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button:hover {
  background-color: var(--danger-hover-color, #ff7875);
}

.edit-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-button:hover {
  background-color: var(--button-hover-bg);
}

.save-button,
.cancel-button {
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 2px;
}

.cancel-button {
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid rgba(var(--border-color-rgb, 0, 0, 0), 0.15);
}

.cancel-button:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
}

.save-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.save-button:disabled {
  background-color: var(--bg-disabled);
  color: var(--text-secondary);
  cursor: not-allowed;
  opacity: 0.7;
}

.save-button:disabled:hover {
  background-color: var(--bg-disabled);
}

.save-button:hover:not(:disabled) {
  background-color: var(--button-hover-bg);
}

/* Hide on mobile devices */
@media (max-width: 768px) {
  .geofence-popup {
    display: none;
  }
}
