.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-primary);
}

.auth0-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #cb2d3e;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logout-section {
  margin-top: 20px;
  text-align: center;
  max-width: 300px;
}

.logout-button {
  font-size: 0.8em;
  padding: 4px 8px !important;
  width: fit-content;
  margin: 8px auto !important;
  display: block;
}

.logout-button:hover {
  background-color: #b52735;
}

.logout-description {
  color: var(--text-primary);
  font-size: 0.9em;
}

/* Animation */

.compass-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.compass-animation {
  position: absolute;
  top: 0;
  left: 0;
  animation: compass-calibrate 6s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
  transform-origin: center;
}

@keyframes compass-calibrate {
  0% {
    transform: rotate(-30deg);
  }
  5% {
    transform: rotate(20deg);
  }
  15% {
    transform: rotate(-50deg);
  }
  25% {
    transform: rotate(10deg);
  }
  35% {
    transform: rotate(-40deg);
  }
  45% {
    transform: rotate(0deg);
  }
  55% {
    transform: rotate(-35deg);
  }
  65% {
    transform: rotate(-25deg);
  }
  75% {
    transform: rotate(-32deg);
  }
  85% {
    transform: rotate(-28deg);
  }
  95% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

/* Option 1: Pulse and Rotate */
/* .logo-animation {
  animation: shadow-morph 2s ease-in-out infinite;
  animation: pulse-rotate 2s ease-in-out infinite;
}

@keyframes pulse-rotate {
  0% {
    transform: scale(1) rotate(0deg);
    filter: drop-shadow(0 0 0 #cb2d3e);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
    filter: drop-shadow(0 0 10px #cb2d3e);
  }
  100% {
    transform: scale(1) rotate(360deg);
    filter: drop-shadow(0 0 0 #cb2d3e);
  }
}

@keyframes shadow-morph {
  0% {
    filter: drop-shadow(0 0 0 #cb2d3e);
    transform: scale(1);
  }
  50% {
    filter: drop-shadow(0 0 15px #cb2d3e);
    transform: scale(1.05);
  }
  100% {
    filter: drop-shadow(0 0 0 #cb2d3e);
    transform: scale(1);
  }
} */

/* Fill animation */
.fill-animation {
  animation: fill 2s ease-in-out infinite;
}

.animate-stop {
  animation: gradientFlow 2s ease-in-out infinite;
}

@keyframes gradientFlow {
  0% {
    offset: 0%;
  }
  50% {
    offset: 100%;
  }
  100% {
    offset: 0%;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  0% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 0;
  }
}
