.newdashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  /* overflow: hidden; */
}

[data-theme='light'] .newdashboard-container {
  background-color: #fff;
}

.newdashboard-container::-webkit-scrollbar {
  display: none;
}
.newdashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  box-shadow: 0 2px 4px var(--shadow-color);
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.newdashboard-header-left {
  display: flex;
  align-items: center;
}

.newdashboard-header-right {
  font-size: 14px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.newdashboard-header-right span {
  cursor: default;
}

.newdashboard-header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .newdashboard-header {
    padding: 10px;
    height: 50px;
  }
}

.newdashboard-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  background-color: var(--bg-primary);
}

.newdashboard-beta-tag {
  display: inline-block;
  background: #ff5a6b;
  color: white;
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 12px;
  border-radius: 3px;
}

.last-updated-tooltip {
  position: relative;
  cursor: help;
}

.last-updated-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  border: 1px solid var(--border-color);
}

.last-updated-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.media-status-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Common styles for both recording and photo badges */
.recording-duration-badge,
.photo-count-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  min-width: 50px;
  min-height: 17px;
  gap: 4px;
}

/* Icon styles */
.recording-icon,
.photo-icon {
  font-size: 14px;
  color: #cb2d3e;
  flex-shrink: 0;
}

/* Text styles */
.recording-duration,
.photo-count {
  font-weight: 500;
  color: var(--text-primary);
  text-align: center;
}

/* Recording status specific styles */
.recording-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: -20px;
}

.recording-text {
  color: var(--text-primary);
  text-align: center;
}

.recording-icon.blinking {
  animation: blink 3s ease-in-out infinite;
}

@keyframes blink {
  0%,
  83.33%,
  100% {
    opacity: 1;
  }
  91.67% {
    opacity: 0.2;
  }
}

/* Timeline photo event styles */
.MuiTimelineDot-root.photo {
  background-color: #4fc3f7;
}

.timeline-item-title.photo {
  color: #4fc3f7;
}
