.date-control-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-range-control {
  display: inline-flex;
  background: #f0f0f0;
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
  align-items: center;
  height: 40px;
}

.date-range-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  background: white;
  border-radius: 6px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  min-width: 120px;
}

.date-range {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  line-height: 1;
}

.week-label {
  font-family: Arial, sans-serif;
  font-size: 10px;
  color: #666;
  line-height: 1;
  margin-top: 1px;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.arrow-button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.5);
  color: #000;
  height: 100%;
}

.arrow-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: #999;
  background: transparent;
}

.arrow-button svg {
  font-size: 12px;
}
