.day-separator {
  position: relative;
  text-align: center;
  margin: 16px 0 16px;
}

.day-separator-line {
  border: none;
  border-top: 1px solid var(--border-color);
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 1;
}

.day-separator-bubble {
  position: relative;
  z-index: 2;
  background-color: var(--bg-primary);
  padding: 4px 16px;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--text-secondary);
  display: inline-block;
  font-weight: 500;
}

.skeleton-bubble {
  width: 120px;
  height: 28px;
  background-color: var(--bg-secondary);
  animation: shimmer 0.8s infinite linear;
}

.skeleton-text {
  width: 80%;
  height: 14px;
  margin: 7px auto;
  background-color: var(--bg-secondary);
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton-bubble,
.skeleton-text {
  background-image: linear-gradient(
    to right,
    var(--bg-secondary) 0%,
    var(--hover-bg) 20%,
    var(--bg-secondary) 40%,
    var(--bg-secondary) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
}

.day-separator-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.day-separator-dropdown button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.day-separator-dropdown button:hover {
  background-color: #f5f5f5;
}
