/* In SiteAssignment.css */

.site-assignment-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 400px;
  background-color: var(--bg-secondary);
  overflow: hidden;
  color: var(--text-primary);
  width: 100%;
  height: 100%;
}

.site-assignment-search-container {
  display: flex;
  align-items: center;
  padding: 0 15px;
  overflow: hidden;
}

.site-assignment-autocomplete-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.site-search-input {
  width: 100%;
  margin-top: 10px;
  padding: 8px 12px 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  box-sizing: border-box;
  overflow: hidden;
}

/* Remove the highlight/outline when focused */
.site-search-input:focus {
  outline: none;
  border-color: var(--border-color);
  box-shadow: 0 0 0 1px var(--border-color);
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
}

.clear-search-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 4px;
  height: 24px;
  width: 24px;
}

.clear-search-button:hover {
  color: var(--text-primary);
  background-color: rgba(0, 0, 0, 0.05); /* Subtle hover effect */
}

/* Table styling */
.site-assignment-table {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  font-family: Arial, sans-serif; /* Use Arial font */
}

.site-assignment-table table {
  width: 100%;
  table-layout: fixed; /* Fixed layout for controlled column widths */
  border-collapse: separate;
  border-spacing: 0;
  overflow-y: visible;
}

.site-assignment-table thead {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--bg-secondary);
}

/* Column width management */
.site-assignment-table th,
.site-assignment-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
  vertical-align: middle;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Column widths - adjust percentages based on your specific needs */
.site-assignment-table th:first-child,
.site-assignment-table td:first-child {
  width: 45%; /* Site name column */
  white-space: normal; /* Allow text wrapping */
  overflow: visible; /* Show all content */
  word-break: break-word; /* Break long words if needed */
  line-height: 1.3; /* Add spacing between wrapped lines */
}

.site-assignment-table th:nth-child(2),
.site-assignment-table td:nth-child(2) {
  width: 35%; /* Address column - typically needs more space */
}

.site-assignment-table th:nth-child(3),
.site-assignment-table td:nth-child(3) {
  width: 20%; /* Type column */
}

.site-assignment-table th {
  font-weight: 500;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.site-assignment-table th .MuiTableSortLabel-root {
  color: var(--text-secondary);
}

.site-assignment-table th .MuiTableSortLabel-root.Mui-active {
  color: var(--text-primary);
}

.site-assignment-table th .MuiTableSortLabel-icon {
  color: var(--text-primary) !important;
}

.site-assignment-table tbody {
  background-color: var(--bg-primary);
}

.site-assignment-table tr:last-child td {
  border-bottom: none;
}

.site-assignment-table tr {
  cursor: pointer;
  transition: background-color 0.1s ease;
  position: relative;
  z-index: 10;
  background-color: var(--bg-primary);
}

.site-assignment-table tr:hover {
  background-color: var(--bg-secondary);
}

.site-assignment-table td:first-child {
  font-weight: 500;
  padding-left: 12px;
}

/* Device count info */
.site-assignment-count-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px 10px;
}

.site-assignment-count {
  color: var(--text-secondary);
  font-size: 14px;
}

/* Preserve existing styles for other components */
.site-assignment-container .address-search-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--bg-primary);
  border-top: 1px solid var(--border-color);
  position: relative;
  z-index: 1000;
}

@media (max-width: 768px) {
  .site-assignment-container .address-search-container {
    display: none;
  }

  .site-assignment-container {
    height: calc(100vh - 100px);
    /* Adjust this value based on your mobile panel's header height */
  }
}

.site-assignment-container .address-search-container {
  position: relative;
  z-index: 1000;
}

/* Address autocomplete container for drawing mode */
.address-autocomplete-container {
  flex-grow: 1;
  position: relative;
}

.mapboxgl-ctrl-geocoder,
.address-autocomplete-container input {
  width: 100% !important;
  max-width: none !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* Remove the highlight/outline when focused */
.mapboxgl-ctrl-geocoder input:focus,
.address-autocomplete-container input:focus {
  outline: none !important;
  box-shadow: 0 0 0 1px var(--border-color) !important;
  border-color: var(--border-color) !important;
  -webkit-appearance: none;
}

/* Still keep the site-specific versions for those within the container */
.site-assignment-container .mapboxgl-ctrl-geocoder,
.site-assignment-container .address-autocomplete-container input {
  width: 100% !important;
  max-width: none !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* Google maps */
.site-assignment-container .pac-container {
  position: absolute;
  z-index: 1500;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.site-assignment-container .pac-item {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-color: var(--border-color);
}

.site-assignment-container .pac-item:hover {
  background-color: var(--hover-bg);
}

.site-assignment-container .pac-item-query {
  color: var(--text-primary);
}

/* Ensure the Mapbox geocoder and its suggestions are visible */
.site-assignment-container .mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: none !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.site-assignment-container .mapboxgl-ctrl-geocoder input {
  color: var(--text-primary) !important;
  background-color: var(--bg-secondary) !important;
}

.site-assignment-container .mapboxgl-ctrl-geocoder input::placeholder {
  color: var(--text-secondary) !important;
}

.add-geofence-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
  z-index: 1002;
  position: relative;
}

.add-geofence-button:hover {
  background-color: var(--button-hover-bg);
}

.add-geofence-button:disabled {
  background-color: var(--bg-disabled);
  cursor: not-allowed;
}

.site-assignment-container .mapboxgl-ctrl-geocoder .suggestions {
  position: absolute;
  z-index: 1500;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
  color: var(--text-primary);
}

.site-assignment-container .mapboxgl-ctrl-geocoder .suggestions > * {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.site-assignment-container .mapboxgl-ctrl-geocoder .suggestions > *:hover {
  background-color: var(--hover-bg);
}

@media (min-width: 769px) {
  .tab {
    flex-direction: row;
    justify-content: flex-start;
    padding: 8px 15px;
  }

  .tab svg {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .tab span {
    font-size: 14px;
  }
}

/* Now add general styles for suggestions outside the container */
.mapboxgl-ctrl-geocoder .suggestions {
  position: absolute;
  z-index: 1500;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
  color: var(--text-primary);
}

.mapboxgl-ctrl-geocoder .suggestions > * {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.mapboxgl-ctrl-geocoder .suggestions > *:hover {
  background-color: var(--hover-bg);
}

/* Ensure the Mapbox geocoder and its suggestions are visible */

/* Add general styles for Google Maps autocomplete outside the container */
.pac-container {
  position: absolute;
  z-index: 1500;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.pac-item {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-color: var(--border-color);
}

.pac-item:hover {
  background-color: var(--hover-bg);
}

.pac-item-query {
  color: var(--text-primary);
}

/* Remove the old sortable-header styles */
.sortable-header {
  cursor: pointer;
  position: relative;
  user-select: none;
}

.sortable-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
