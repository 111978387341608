/* Base styles */
.video-popup .leaflet-popup-content-wrapper {
  width: 650px;
  padding: 10px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.leaflet-popup-tip {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
}

.video-popup-inner {
  width: 600px;
  height: fit-content;
  max-width: 100%;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.video-popup-inner > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.video-popup-inner span {
  display: inline-flex;
  align-items: center;
}

/* Video styles */
.hls-video,
.webrtc_player,
.ovenplayer {
  width: 100%;
  height: auto;
  max-height: 650px;
  aspect-ratio: 1 / 1;
}

.hls-video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* Device info styles */
.live-popup-header {
  margin-bottom: 10px;
}

.device-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.assigned-to,
.device-id,
.device-status,
.last-located-time,
.device-temperature {
  display: flex;
  align-items: center;
}

.device-status .fa-battery-full,
.device-status .fa-battery-three-quarters,
.device-status .fa-battery-half,
.device-status .fa-battery-quarter,
.device-status .fa-battery-empty,
.device-status .fa-charging-station,
.last-located-time .fa-map-marker-alt,
.device-temperature .fa-temperature-half {
  margin-right: 5px;
}

.charging-battery {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 5px;
}

.charging-battery .charging-frame {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: charging-frames 1.5s steps(1, end) infinite;
}

.charging-battery .charging-frame:nth-child(1) {
  animation-delay: 0s;
}
.charging-battery .charging-frame:nth-child(2) {
  animation-delay: 0.3s;
}
.charging-battery .charging-frame:nth-child(3) {
  animation-delay: 0.6s;
}
.charging-battery .charging-frame:nth-child(4) {
  animation-delay: 0.9s;
}
.charging-battery .charging-frame:nth-child(5) {
  animation-delay: 1.2s;
}

@keyframes charging-frames {
  0%,
  100% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}

.assigned-to {
  font-weight: bold;
  color: var(--text-primary);
}

.device-id {
  font-size: 1em;
  color: var(--text-primary);
}

.last-located-time {
  display: flex;
  align-items: center;
  color: var(--text-primary);
}

.pulsating-icon-wrapper {
  display: inline-block;
  margin-right: 4px;
  font-size: 10px;
}

.pulsating-icon {
  animation: blink 3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes blink {
  0%,
  83.33%,
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
  91.67% {
    opacity: 0.2;
    /* transform: scale(0.8); */
  }
}

.time-text {
  margin-left: 0px;
  color: var(--text-primary);
}

/* Toggle Switch */
.controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: var(--text-primary);
}

.shift-toggle,
.stream-toggle {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: var(--text-primary);
}

.shift-status,
.stream-status {
  margin-left: 5px;
  font-size: 0.9em;
  color: var(--text-primary);
}

.stream-status.cooldown {
  font-size: 0.9em;
  color: var(--text-secondary);
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: var(--text-secondary);
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #cb2d3e;
  border-color: #cb2d3e;
}

input:checked + .slider:before {
  transform: translateX(26px);
  background-color: white;
}

input:disabled + .slider {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--bg-disabled);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.offline-message {
  margin-bottom: 10px;
}

.offline-message p {
  color: var(--text-primary);
  margin-bottom: 10px;
}

.shift-toggle {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: var(--text-primary);
}

.shift-status {
  font-size: 0.9em;
  margin-left: 5px;
  color: var(--text-primary);
}

.stream-status.cooldown {
  font-size: 0.9em;
  color: var(--text-secondary);
}

/* Overlay styles */
.loading-overlay,
.error-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50%;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  border-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-overlay p,
.error-overlay p {
  margin-top: 10px;
  text-align: center;
}

.retry-button {
  background-color: #cb2d3e;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  transition: background-color 0.1s ease;
}

.retry-button:hover {
  background-color: #ef473a;
}

/* Signal strength styles */
.signal-strength {
  display: inline-block;
  height: 10px;
  width: 18px;
  margin-left: 5px;
}

.signal-strength .bar-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 3px;
}

.signal-strength .bar {
  width: 2px;
  background-color: #ccc;
  margin-right: 1px;
  transition: height 0.2s ease;
}

.signal-strength .bar:nth-child(1) {
  height: 20%;
}
.signal-strength .bar:nth-child(2) {
  height: 40%;
}
.signal-strength .bar:nth-child(3) {
  height: 60%;
}
.signal-strength .bar:nth-child(4) {
  height: 80%;
}
.signal-strength .bar:nth-child(5) {
  height: 100%;
}

.signal-strength-1 .bar:nth-child(-n + 1) {
  background-color: #f90000;
}
.signal-strength-2 .bar:nth-child(-n + 2) {
  background-color: #ff8000;
}
.signal-strength-3 .bar:nth-child(-n + 3) {
  background-color: #ffc000;
}
.signal-strength-4 .bar:nth-child(-n + 4) {
  background-color: #66cc66;
}
.signal-strength-5 .bar:nth-child(-n + 5) {
  background-color: #33cc33;
}
.signal-strength-unknown .bar {
  background-color: #ccc;
}

/* Error container */
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #f9f9f9;
}

.error-message {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  color: #cb2d3e;
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
}

.error-message .error-icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #cb2d3e;
}

.error-message p {
  margin: 0 0 15px 0;
  color: #cb2d3e;
  line-height: 1.4;
}

/* Audio and video indicators */
.audio-only-indicator,
.video-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.switch-to-video-button,
.switch-to-audio-button {
  margin-left: 10px;
  background-color: #cb2d3e;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.1s ease;
}

.switch-to-video-button:hover,
.switch-to-audio-button:hover {
  background-color: #ef473a;
}

.audio-only-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.audio-only-overlay img {
  width: 80px;
  height: 80px;
  opacity: 0.7;
}

.audio-only-overlay.hidden {
  display: none;
}

.fallback-message {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  max-width: 300px;
  text-align: center;
  z-index: 10;
}

.loader-text {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  padding: 20px 0;
  font-weight: 500;
}

/* Animations */
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .alerts-container,
  .devices-container {
    display: none;
  }

  .video-popup .leaflet-popup-content-wrapper {
    width: calc(100vw - 10px);
    padding: 0px !important;
  }

  .leaflet-popup-content {
    margin: 0px;
  }

  .video-popup-inner {
    width: 100%;
    padding: 0px !important;
  }

  .hls-video-container {
    width: 100%;
  }

  .live-popup-header {
    margin: 0px !important;
    padding: 10px;
    height: fit-content !important;
  }

  .device-info {
    gap: 10px;
  }

  .assigned-to,
  .device-id,
  .device-status,
  .last-located-time,
  .device-temperature {
    font-size: 10px;
  }

  .device-status .fa-battery-full,
  .device-status .fa-battery-three-quarters,
  .device-status .fa-battery-half,
  .device-status .fa-battery-quarter,
  .device-status .fa-battery-empty,
  .device-status .fa-charging-station,
  .last-located-time .fa-map-marker-alt,
  .device-temperature .fa-temperature-half {
    font-size: 12px;
  }

  /* .pulsating-icon {
    font-size: 10px;
  } */

  .time-text {
    font-size: 8px;
  }

  .device-signal-strength {
    margin-left: 5px !important;
  }

  .controls-wrapper {
    margin-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    font-size: 9px; /* Reduced font size for mobile */
  }

  .switch {
    width: 33px; /* Reduced width */
    height: 19px; /* Reduced height */
    margin-right: 5px;
  }

  .slider:before {
    height: 13px; /* Reduced height of the toggle circle */
    width: 13px; /* Reduced width of the toggle circle */
    left: 3px;
    bottom: 3px;
  }

  input:checked + .slider:before {
    transform: translateX(14px); /* Adjusted for smaller toggle */
  }

  .shift-status,
  .stream-status {
    font-size: 9px; /* Smaller font size for status text */
  }

  .stream-status.cooldown {
    font-size: 8px; /* Even smaller font size for cooldown text */
  }

  .shift-toggle,
  .stream-toggle {
    margin-right: 15px; /* Reduced margin between toggles */
  }

  .retry-button {
    padding: 5px 10px;
    font-size: 9px;
  }

  .switch-to-video-button,
  .switch-to-audio-button {
    padding: 3px 6px;
    font-size: 8px;
  }
}

/* Overrides */
.op-live-badge-lowlatency {
  visibility: hidden;
}

.op-live-badge-lowlatency::after {
  content: '<p>Hello</p>';
}

.reload-button {
  color: #666666; /* Darker gray color */
  padding: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reload-button:hover {
  color: #333333; /* Even darker on hover */
}

.reload-button .fa-sync {
  font-size: 1rem;
}

/* Mocha specific styles */
.Mocha .op-media-element-container video {
  transform: none !important; /* Remove flip */
  object-fit: contain !important; /* Preserve aspect ratio */
}

/* Gillette specific styles */
.Gillette .op-media-element-container video {
  transform: scaleX(-1); /* Keep flip for Gillette */
  object-fit: cover; /* Keep cover for Gillette */
  width: 100%;
  height: 100%;
}

.location-accuracy-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  text-align: center !important;
  padding: 5px 0px;
  background-color: var(--bg-primary);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.location-accuracy-message p {
  font-size: 14px;
  color: var(--text-primary);
  text-align: center !important;
  margin: 0px auto !important;
  font-style: italic;
}
.accuracy-circle {
  transition: all 0.3s ease-out;
  will-change: transform;
}
