/* Map Controls Styles */

.draw-control {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

/* New container for the drawing mode controls */
.draw-control-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

/* Ensure individual components within the container are still clickable */
.draw-control-container > * {
  pointer-events: auto;
}

/* Add a new wrapper for the done button to position it at top right */
.done-button-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

/* Position the address autocomplete at the top left with enhanced styling */
.draw-edit-bar-address-autocomplete-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1001;
  pointer-events: auto;
  width: 350px;
  /* background-color: rgba(255, 255, 255, 0.9); */
  background: none;
  border: none;
  /* border-radius: 8px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  transition: all 0.2s ease;
  border-radius: 30px;
}

/* Dark mode support for address autocomplete */
[data-theme='dark'] .draw-edit-bar-address-autocomplete-container {
  /* background-color: rgba(50, 50, 50, 0.9); */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  background: none;
  border: none;
  border-radius: 30px;
}

/* Add search icon styling */
.address-search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  z-index: 1002;
  font-size: 16px;
}

/* Adjust input padding for search icon */
.draw-edit-bar-address-autocomplete-container input {
  padding-left: 45px !important;
  height: 40px !important;
  font-size: 16px !important;
}

.draw-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 30px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.draw-button:hover {
  background-color: var(--button-hover-bg);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.draw-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: var(--button-bg);
}

.draw-button:disabled:hover {
  background-color: var(--button-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.draw-button.active {
  background-color: var(--button-hover-bg);
  color: var(--button-text);
}

/* Horizontal editing bar */
.geofence-edit-bar {
  background-color: var(--bg-primary);
  border-radius: 4px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
  z-index: 1000;
  display: flex;
  flex-direction: row;
  padding: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  pointer-events: auto;
  background: none;
  margin: 0;
}

.edit-bar-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
  pointer-events: auto;
  font-size: 14px;
  font-weight: 500;
  min-width: 36px;
  height: 36px;
}

.edit-bar-button:last-child {
  margin-right: 0;
}

.edit-bar-button:hover {
  background-color: var(--button-hover-bg);
}

.edit-bar-button.active {
  background-color: var(--button-hover-bg);
  color: var(--button-text);
}

.edit-bar-button.clear-button {
  background-color: var(--button-bg);
  color: var(--button-text);
}

.edit-bar-button.clear-button:hover {
  background-color: var(--button-hover-bg);
}

.edit-bar-button.clear-button.active {
  background-color: var(--button-hover-bg);
  color: var(--button-text);
}

.edit-bar-button svg {
  width: 18px;
  height: 18px;
}

.edit-bar-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--button-disabled-bg, #e0e0e0);
  color: var(--button-disabled-text, #888);
}

/* Geofence popup */
.geofence-popup {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 2000;
  padding: 20px;
  width: 360px;
  max-width: 90vw;
}

.new-site {
  padding-top: 10px !important;
}

.geofence-header {
  margin-bottom: 20px;
}

.geofence-title {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 0;
  border-radius: 4px;
}

.geofence-title h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: var(--text-primary);
}

.geofence-title .edit-icon {
  font-size: 14px;
  color: var(--text-secondary);
  opacity: 0;
  transition: opacity 0.2s ease;
}

/* .geofence-title:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
}

.geofence-title:hover .edit-icon {
  opacity: 0.7;
} */

.geofence-title-input {
  width: 100%;
  padding: 10px 12px;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  background-color: transparent;
  transition: all 0.2s ease;
}

.geofence-title-input:focus {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
  outline: none;
}

.geofence-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.edit-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.right-actions {
  display: flex;
  gap: 12px;
}

.delete-button {
  background-color: transparent;
  color: #ea4335;
  border: 1px solid rgba(234, 67, 53, 0.3);
  border-radius: 20px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button:hover {
  background-color: rgba(234, 67, 53, 0.05);
  border-color: rgba(234, 67, 53, 0.5);
}

.cancel-button,
.save-button {
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid rgba(var(--border-color-rgb, 0, 0, 0), 0.15);
}

.cancel-button:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
}

.save-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.save-button:hover {
  background-color: var(--button-hover-bg);
}

/* Site name editing */
.site-name-edit-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.site-name-display {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
}

.site-name-display:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
}

.site-name-edit {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.site-name-input {
  flex-grow: 1;
  padding: 6px 10px;
  border: 1px solid rgba(var(--border-color-rgb, 0, 0, 0), 0.15);
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-primary);
  background-color: var(--input-bg);
}

.site-name-input:focus {
  border-color: var(--button-bg);
  outline: none;
}

.site-action-buttons {
  display: flex;
  gap: 8px;
}

.site-edit-button,
.site-delete-button,
.site-save-button,
.site-cancel-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
}

.site-edit-button:hover,
.site-delete-button:hover,
.site-save-button:hover,
.site-cancel-button:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.1);
}

.site-delete-button {
  color: #ea4335;
}

.site-save-button {
  color: var(--button-bg);
}

/* Done button */
.done-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 20px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.done-button:hover {
  background-color: var(--button-hover-bg);
}

/* Drawing instructions tooltip - make it very transparent */
.drawing-instructions {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(var(--bg-primary-rgb, 255, 255, 255), 0.4); /* Much more transparent by default */
  color: var(--text-primary);
  padding: 12px 16px;
  border-radius: 8px;
  z-index: 400;
  width: 280px;
  text-align: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  border: 1px solid rgba(var(--border-color-rgb, 0, 0, 0), 0.1);
  backdrop-filter: blur(4px); /* Reduced blur effect */
  transition: all 0.3s ease;
  pointer-events: none;
}

/* Area calculation display */
.area-calculation {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
}

/* Minimum area warning */
.minimum-area-warning {
  margin-top: 4px;
  color: #ea4335;
  font-size: 13px;
  font-weight: 500;
}

.drawing-instructions:hover {
  background-color: rgba(var(--bg-primary-rgb, 255, 255, 255), 0.2); /* Even more transparent on hover */
}

.drawing-instructions p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.drawing-instructions strong {
  color: var(--button-bg);
}

/* Dark theme support */
[data-theme='dark'] .drawing-instructions {
  background-color: rgba(var(--bg-primary-rgb, 30, 30, 30), 0.4);
}

[data-theme='dark'] .drawing-instructions:hover {
  background-color: rgba(var(--bg-primary-rgb, 30, 30, 30), 0.2);
}

/* Make the draw control buttons more visible */
.leaflet-draw-toolbar a {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

/* Hide other UI elements when in drawing mode */
.leaflet-draw-section {
  display: none;
}

/* Style for the polygon drawing */
.leaflet-draw-tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
  z-index: 1000;
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

/* Styles for Geoman tooltips */
.leaflet-pm-tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
  z-index: 1000;
}

/* Make sure the draw control is visible */
.leaflet-draw {
  display: block !important;
}

/* Style for the geofence highlight tooltips */
.geofence-highlight-tooltip {
  background-color: rgba(255, 255, 0, 0.7);
  border: none;
  box-shadow: none;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
  color: #333;
}

.geofence-highlight-tooltip::before {
  display: none;
}

/* Error notification */
.geofence-error-notification {
  position: fixed;
  top: 80px;
  left: calc(50% + 25px);
  transform: translateX(-50%);
  background-color: #fdeded;
  color: #5f2120;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 12px 16px;
  z-index: 9999;
  max-width: 350px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.geofence-error-notification-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.geofence-error-notification-icon {
  color: #ea4335;
  font-size: 20px;
  flex-shrink: 0;
}

.geofence-error-notification-message {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.geofence-error-notification-close {
  background: none;
  border: none;
  color: #5f2120;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  margin-left: 12px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.geofence-error-notification-close:hover {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Auto-panning indicators */
.autopan-indicator {
  position: absolute;
  z-index: 1000;
  background-color: rgba(255, 0, 0, 0.3);
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.autopan-indicator.active {
  opacity: 1;
}

.autopan-indicator-left {
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
}

.autopan-indicator-right {
  right: 0;
  top: 0;
  width: 10px;
  height: 100%;
}

.autopan-indicator-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
}

.autopan-indicator-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
}
