.help-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  box-sizing: border-box;
}

.help-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  min-height: 40px;
  height: 40px;
  box-shadow: 0 2px 4px var(--shadow-color);
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.help-header-left h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.help-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.help-sections {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  height: 40px;
}

.help-section-button {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.help-section-button:hover {
  background: var(--hover-bg);
}

.help-section-button.active {
  background: var(--bg-primary);
  color: var(--text-primary);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.help-section-button i {
  font-size: 16px;
  opacity: 0.8;
}

.help-section-button.active i {
  opacity: 1;
}

.help-content {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
  padding: 20px 0;
  width: 100%;
  box-sizing: border-box;
}

.help-item {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px var(--shadow-color);
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.help-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px var(--shadow-color);
}

.help-item h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  word-wrap: break-word;
}

.help-item p {
  margin: 0 0 15px 0;
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  height: 42px; /* Fixed height for 2 lines at 1.5 line height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.help-video-preview {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-top: 15px;
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--bg-primary);
}

.help-video-preview iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  pointer-events: none; /* Disable interaction in preview mode */
}

.help-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  padding: 20px;
  box-sizing: border-box;
}

.help-popup {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 120vh;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.help-popup-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: none;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.help-popup-close:hover {
  background-color: var(--bg-hover);
}

.help-popup h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 16px 0;
  padding-right: 40px;
  color: var(--text-primary);
}

.help-popup p {
  margin: 0 0 24px 0;
  color: var(--text-secondary);
  font-size: 16px;
  line-height: 1.6;
}

.help-video-expanded {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-top: 15px;
  overflow: hidden;
}

.help-video-expanded iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .help-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .help-popup {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .help-sections {
    width: 100%;
    flex-wrap: wrap;
  }

  .help-section-button {
    flex: 1 1 auto;
    justify-content: center;
  }

  .help-content {
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
  }

  .help-body {
    padding: 16px;
  }

  .help-item {
    padding: 16px;
  }

  .help-popup {
    padding: 16px;
    margin: 16px;
  }

  .help-popup-close {
    top: 12px;
    right: 12px;
  }
}
