.settings-dialog .MuiDialog-paper {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  border-radius: 8px !important;
  min-width: 500px !important;
}

.settings-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px !important;
  background-color: var(--bg-primary) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.settings-dialog-title h2 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-primary) !important;
}

.settings-dialog-content {
  padding: 24px !important;
  background-color: var(--bg-primary) !important;
}

.settings-dialog-section {
  margin-bottom: 24px;
}

.settings-dialog-section:last-child {
  margin-bottom: 0;
}

.settings-dialog-section-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 16px;
  color: var(--text-primary) !important;
}

.settings-dialog-row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.settings-dialog-actions {
  padding: 16px 24px !important;
  background-color: var(--bg-primary) !important;
  border-top: 1px solid var(--border-color) !important;
}

/* Form Controls */
.settings-dialog .MuiFormControl-root {
  width: 100%;
}

.settings-dialog .MuiInputLabel-root {
  color: var(--text-secondary) !important;
}

.settings-dialog .MuiOutlinedInput-root {
  background-color: var(--bg-secondary) !important;
}

.settings-dialog .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

/* Add styles for disabled input fields */
.settings-dialog .Mui-disabled .MuiInputBase-input {
  color: var(--text-secondary) !important;
  -webkit-text-fill-color: var(--text-secondary) !important;
  opacity: 0.7;
}

.settings-dialog .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
  opacity: 0.5;
}

.settings-dialog .MuiRadio-root {
  color: var(--text-secondary) !important;
}

.settings-dialog .MuiRadio-root.Mui-checked {
  color: var(--button-bg) !important;
}

.settings-dialog .MuiFormControlLabel-label {
  color: var(--text-primary) !important;
}

/* Close button styling */
.settings-dialog-close-button {
  color: var(--text-primary) !important;
}

.settings-dialog-close-button:hover {
  background-color: var(--bg-secondary) !important;
}

.settings-dialog-actions .MuiButton-containedPrimary:disabled {
  background-color: var(--button-disabled-bg) !important;
  color: var(--text-primary) !important;
}
