.reports-list-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg-primary);
}

.reports-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.reports-date-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

/* Date separator styling */
.report-date-separator {
  position: relative;
  text-align: center;
  margin: 32px 0 16px;
}

.report-date-separator-line {
  border: none;
  border-top: 1px solid var(--border-color);
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 1;
}

.report-date-separator-bubble {
  position: relative;
  z-index: 2;
  background-color: var(--bg-primary);
  padding: 4px 16px;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--text-secondary);
  display: inline-block;
  font-weight: 500;
}

/* Report card styling */
.report-card {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-card:hover {
  background-color: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.report-card.unreviewed {
  border-left: 4px solid var(--button-bg);
  background-color: var(--bg-secondary);
}

.report-card.unreviewed:hover {
  background-color: var(--hover-bg);
}

.report-card-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.report-assigned-to {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 15px;
}

.report-timestamp {
  display: flex;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 13px;
}

.report-author {
  color: var(--text-secondary);
  font-size: 13px;
}

.unreviewed-badge {
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.delete-button {
  color: var(--text-secondary);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  color: var(--button-bg);
  transform: scale(1.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .reports-list-container {
    padding: 8px;
  }

  .report-card {
    padding: 12px;
  }

  .report-assigned-to {
    font-size: 14px;
  }

  .report-timestamp,
  .report-author {
    font-size: 12px;
  }

  .report-date-separator {
    margin: 24px 0 12px;
  }

  .report-date-separator-bubble {
    font-size: 13px;
    padding: 3px 12px;
  }
}
