.settings-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  height: 48px;
  min-height: 48px;
}

.settings-controls-left {
  display: flex;
  gap: 20px;
}

.settings-controls-right {
  margin-left: auto;
}
