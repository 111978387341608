.shift-collapsed-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  /* border-radius: 4px; */
  cursor: pointer;
  transition:
    background-color 0.05s ease,
    transform 0.05s ease;
}

.shift-collapsed-container:hover {
  background-color: var(--hover-bg);
}

/* .shift-collapsed-container:active {
  transform: scale(0.99);
} */

.shift-collapsed-personnel,
.shift-collapsed-date,
.shift-collapsed-escalations,
.shift-collapsed-time {
  margin-right: 10px;
}

.shift-collapsed-expand {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.shift-collapsed-expand:hover {
  color: var(--text-primary);
}

.shift-collapsed-left,
.shift-collapsed-right {
  display: flex;
  align-items: center;
}

.shift-collapsed-left {
  width: 80%;
  justify-content: flex-start;
  gap: 50px; /* Increase gap to match right side spacing */
}

.shift-collapsed-right {
  width: 15%;
  justify-content: flex-end;
  gap: 20px; /* Add gap to match left side */
  margin-right: 20px;
}

.shift-collapsed-personnel {
  font-weight: bold;
}

.shift-collapsed-personnel {
  width: 20%; /* Adjust width to maintain alignment */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}
.shift-collapsed-date {
  width: fit-content; /* Adjust width to maintain alignment */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.duration-badge {
  margin-left: 0; /* Remove left margin */
  font-size: 0.9em;
  color: var(--text-primary);
  white-space: nowrap; /* Prevent wrapping */
}

.shift-collapsed-escalations {
  display: flex;
  min-width: 40px;
  align-items: center;
  justify-content: flex-start;
}

.shift-collapsed-time {
  position: relative;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Added gap between time elements */
  z-index: 1;
}

.shift-collapsed-time sup {
  position: absolute;
  top: 2px;
  right: 18px;
  font-size: 0.8em;
  line-height: 1;
}

.custom-tooltip-wrapper {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  box-shadow: 0 2px 10px var(--shadow-color) !important;
  padding: 8px !important;
  font-size: 14px !important;
  border: 1px solid var(--border-color) !important;
}

.custom-tooltip-time {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tooltip-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tooltip-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.tooltip-dot.online {
  background-color: #e74d3ce4; /* Use the online time color */
  border: 1px solid #e74d3ce4;
}

.tooltip-dot.offline {
  background-color: var(--bg-secondary);
  border: 1px solid #e74d3ce4;
}

.tooltip-label {
  flex-grow: 1;
}

.tooltip-value {
  color: var(--text-secondary);
}
