.event-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  width: 100%;
  height: 100%;
}

.event-detail-toggle {
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
}

.event-detail-toggle button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.event-detail-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}
