.shift-card-list-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: var(--bg-primary);
  /* padding-left: 300px;
  padding-right: 300px; */
}

.shift-card-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: var(--text-primary);
  /* margin-right: 40px; */
}

/* .shift-card-list-header-left {
  display: flex;
  align-items: center;
}

.shift-card-list-header-right {
  display: flex;
  align-items: center;
} */

.shift-card-list-header-left,
.shift-card-list-header-right {
  display: flex;
  align-items: center;
}
.shift-card-list-header-left {
  width: 50%;
  justify-content: flex-start;
}

.shift-card-list-header-right {
  width: 50%;
  gap: 140px;
  justify-content: flex-end;
  margin-right: 40px;
}

.shift-card-list-header button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text-secondary);
}

.shift-card-list-header button:hover {
  color: var(--text-primary);
}

.shift-group-header {
  font-size: 18px;
  font-weight: 600;
  color: #cb2d3e;
  margin: 20px 0 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}

.load-more-button {
  background-color: #cb2d3e;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition:
    background-color 0.1s,
    transform 0.2s;
  display: block;
  margin: 20px auto;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.load-more-button:hover {
  background-color: #a82432;
  transform: translateY(-2px);
}

.load-more-button:active {
  transform: translateY(0);
}

.shift-card-skeleton {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.skeleton-header {
  height: 24px;
  width: 40%;
  background-color: var(--bg-secondary);
  margin-bottom: 16px;
  border-radius: 4px;
}

.skeleton-body .skeleton-line {
  height: 16px;
  background-color: var(--bg-secondary);
  margin-bottom: 8px;
  border-radius: 4px;
}

.skeleton-body .skeleton-line:last-child {
  width: 60%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.shift-card-skeleton,
.skeleton-header,
.skeleton-line {
  background-image: linear-gradient(
    to right,
    var(--skeleton-base) 0%,
    var(--skeleton-highlight) 20%,
    var(--skeleton-base) 40%,
    var(--skeleton-base) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.shift-collapsed-container.skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  cursor: default;
}

.shift-collapsed-container.skeleton:hover {
  background-color: var(--bg-secondary);
}

.skeleton-personnel,
.skeleton-time,
.skeleton-duration,
.skeleton-escalations,
.skeleton-flag {
  background-color: var(--bg-secondary);
  border-radius: 4px;
  animation: shimmer 0.8s infinite linear;
}

.skeleton-personnel {
  width: 20%;
  height: 24px;
  margin-right: 10px;
}

.skeleton-time {
  width: 60px;
  height: 24px;
  margin-right: 10px;
}

.skeleton-duration {
  width: 80px;
  height: 24px;
  margin-right: 10px;
}

.skeleton-escalations {
  width: 40px;
  height: 24px;
}

.skeleton-flag {
  width: 24px;
  height: 24px;
}

.shift-collapsed-expand.skeleton-expand {
  background: none;
  border: none;
  font-size: 20px;
  cursor: default;
  color: var(--text-secondary);
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton-personnel,
.skeleton-time,
.skeleton-duration,
.skeleton-escalations,
.skeleton-flag {
  background-image: linear-gradient(
    to right,
    var(--skeleton-base) 0%,
    var(--skeleton-highlight) 20%,
    var(--skeleton-base) 40%,
    var(--skeleton-base) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
}

.no-shifts-message {
  text-align: center;
  font-size: 18px;
  color: var(--text-secondary);
  margin-top: 20px;
  padding: 20px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}
