.mobile-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease-in-out;
  height: 70px; /* Increased to accommodate the central button */
  overflow: visible; /* Changed from hidden to visible */
  z-index: 1000;
}

.mobile-panel.expanded {
  height: 50vh;
}

.mobile-panel-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  height: 70px; /* Fixed height */
  position: relative; /* Added for z-index to work */
  z-index: 1001; /* Ensure tabs are above the panel */
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font-size: 12px;
  cursor: pointer;
  flex: 1;
  border: none;
  background: none;
  height: 100%; /* Full height of tabs */
}

.tab svg {
  font-size: 20px;
  margin-bottom: 5px;
}

.tab.central {
  transform: translateY(calc(-30px * 576 / 512));
  background-color: #cb2d3e;
  border-radius: 50%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    0 -4px 6px rgba(0, 0, 0, 0.1),
    0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1002;
  width: 70px !important;
  height: 70px !important;
  flex: none;
}

.tab.central .central-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  padding-block: 0;
  padding-inline: 0;
}

.tab.central .fa-trophy {
  color: white;
  font-size: 24px;
  line-height: 1;
  margin: 0 !important;
  padding: 0 !important;
}

.tab.central .days-left {
  color: rgba(255, 255, 255, 0.7);
  font-size: 8px;
  line-height: 1;
  margin-top: 4px;
  text-align: center;
  margin-bottom: 0;
}

.tab span {
  font-size: 10px;
}

.tab.active {
  color: #cb2d3e;
}

.tab.central.active {
  background-color: hsl(354, 84%, 59%);
}

.mobile-panel-content {
  height: calc(100% - 70px); /* Adjusted to match new panel height */
  overflow-y: auto;
}

.alerts-list {
  padding: 10px;
}

@media (min-width: 769px) {
  .mobile-panel {
    display: none;
  }
}

/* Mobile warning popup */

/* .mobile-warning-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.mobile-warning-overlay.fading {
  opacity: 0;
}

.mobile-warning-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
} */

.plix-logo {
  width: 32px;
  height: 32px;
  margin-bottom: 15px;
}

/* .mobile-warning-popup h2 {
  margin-bottom: 15px;
  color: #333;
}

.mobile-warning-popup p {
  margin-bottom: 10px;
  color: #666;
}

.try-mobile-button {
  background-color: #b52d2e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 15px;
  transition: background-color 0.1s;
}

.try-mobile-button:hover {
  opacity: 0.8;
}

@media (min-width: 769px) {
  .mobile-warning-overlay {
    display: none;
  }
} */

/* GuardActionBanner.css */
.guard-action-banner {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 40px;
}

.logo-container {
  /* flex: 0 0 24px; Fixed width for the logo container */
  width: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plix-logo {
  width: 36px !important;
  height: 36px;
  transition: filter 0.5s ease;
}

.plix-logo.black {
  filter: brightness(0);
}

.plix-logo.red {
  filter: hue-rotate(0deg) saturate(100%) brightness(100%);
}

.plix-logo.green {
  filter: hue-rotate(120deg) saturate(100%) brightness(100%);
}

.text-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  overflow: visible;
}

.action-text {
  font-size: 14px;
  /* white-space: nowrap; */
  overflow: visible;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.action-text.fade-in {
  animation: fadeIn 0.1s ease-in-out forwards;
}

.action-text.fade-out {
  animation: fadeOut 0.1s ease-in-out forwards;
}
