.personnel-stats-container {
  padding: 20px;
  padding-top: 0;
  background: var(--bg-secondary);
  border-radius: 8px;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
}

.personnel-stats-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--text-primary);
}

.stats-table {
  width: 100%;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 8px 16px;
  background: var(--hover-bg);
  border-radius: 4px;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--text-primary);
}

.table-header::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--hover-bg);
  z-index: -1;
}

.table-header > span:not(:first-child),
.table-row > span:not(:first-child) {
  text-align: center;
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
  color: var(--text-primary);
}

.table-row:hover {
  background: var(--hover-bg);
}

.status {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  width: fit-content;
}

.status.online {
  background: #e6f4ea;
  color: #1e8e3e;
}

.status.offline {
  background: #fce8e6;
  color: #d93025;
}

.sortable {
  cursor: pointer;
  position: relative;
  user-select: none;
}

.header-main {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.table-header > span:not(:first-child) .header-main {
  justify-content: center;
}

.header-main::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.sortable.asc .header-main::after {
  border-bottom: 4px solid var(--text-secondary);
  border-top: none;
}

.sortable.desc .header-main::after {
  border-top: 4px solid var(--text-secondary);
  border-bottom: none;
}

.personnel-stats-container.skeleton {
  pointer-events: none;
}

.skeleton-header-text {
  height: 24px;
  width: 200px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 20px;
}

.personnel-stat-card.skeleton {
  display: flex;
  padding: 20px;
  gap: 20px;
  background-color: var(--bg-secondary);
}

.skeleton-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.skeleton-content {
  flex: 1;
}

.skeleton-name {
  height: 20px;
  width: 60%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-stats {
  display: flex;
  gap: 20px;
}

.skeleton-stat {
  height: 16px;
  width: 80px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton-header-text,
.skeleton-avatar,
.skeleton-name,
.skeleton-stat {
  animation: shimmer 0.8s infinite linear;
  background-image: linear-gradient(
    to right,
    var(--bg-secondary) 0%,
    var(--hover-bg) 20%,
    var(--bg-secondary) 40%,
    var(--bg-secondary) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
}

.table-header.skeleton,
.table-row.skeleton {
  background: none;
  pointer-events: none;
}

.skeleton-text,
.skeleton-cell {
  background-image: linear-gradient(
    to right,
    var(--bg-secondary) 0%,
    var(--hover-bg) 20%,
    var(--bg-secondary) 40%,
    var(--bg-secondary) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 0.8s infinite linear;
  border-radius: 4px;
}

.skeleton-text {
  height: 20px;
  width: 80%;
  display: block;
}

.skeleton-cell {
  height: 16px;
  width: 70%;
  display: block;
}

.table-row.skeleton {
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.table-row.skeleton {
  background: none;
  pointer-events: none;
}

.skeleton-cell {
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 0.8s infinite linear;
  border-radius: 4px;
  height: 20px;
}

.skeleton-cell.personnel {
  width: 60%;
}

.skeleton-cell.hours,
.skeleton-cell.rate,
.skeleton-cell.battery,
.skeleton-cell.ranking {
  width: 40%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.table-header span {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header-main {
  flex: 1;
  font-weight: 600;
  color: var(--text-primary);
}

.header-subtext {
  padding-right: 20px;
  font-size: 11px;
  color: var(--text-secondary);
  font-weight: normal;
}

.info-tooltip {
  color: var(--text-secondary);
  cursor: help;
  position: static;
  font-size: 14px;
  margin-left: 8px;
}

/* Average */

.table-row.average-row {
  background-color: var(--bg-secondary);
  font-weight: 600;
}

.table-row.average-row:hover {
  background-color: var(--hover-bg);
}
