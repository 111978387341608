.advanced-settings-container {
  padding: 20px;
  width: 100%;
}

.advanced-settings-card {
  padding: 20px;
  margin-bottom: 20px;
}

.device-management-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: var(--button-bg, #cb2d3e);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.device-management-link:hover {
  background-color: var(--button-hover-bg, #b52d2e);
  text-decoration: none;
}
