.single-map-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.single-map-view-container.large {
  height: 600px;
  width: 600px;
}

.single-map-view-container.small {
  height: 300px;
}

.single-leaflet-container {
  height: 100%;
  width: 100%;
}
