/* Styles for ActivityReports component */

.activity-reports-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.activity-reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-reports-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

/* Update button styling to match other components */
.activity-reports-header button {
  padding: 8px 16px;
  background-color: var(--button-bg);
  color: white !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.activity-reports-header button:hover {
  background-color: var(--button-hover-bg);
  color: white !important;
}

/* Update table container styles to include scrolling */
.activity-reports-table {
  width: 100%;
  /* max-height: 370px; */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--bg-secondary);
}

.activity-reports-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.activity-reports-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-secondary);
}

.activity-reports-table th,
.activity-reports-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.activity-reports-table th {
  font-weight: 500;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.activity-reports-table th:first-child {
  border-top-left-radius: 6px;
}

.activity-reports-table th:last-child {
  border-top-right-radius: 6px;
}

.activity-reports-table tr:last-child td {
  border-bottom: none;
}

.activity-reports-table tr:hover {
  background-color: var(--hover-bg);
}

/* Action buttons styling */
.action-buttons {
  display: flex;
  gap: 8px;
}

/* Dialog styles */
.MuiDialog-paper {
  background-color: var(--bg-primary) !important;
}

.MuiDialogTitle-root {
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
}

.MuiDialogContent-root {
  padding: 24px !important;
}

/* Form control styles */
.MuiFormControl-root {
  width: 100%;
}

.MuiInputLabel-root {
  color: var(--text-secondary) !important;
}

.MuiOutlinedInput-root {
  background-color: var(--bg-secondary);
}

/* Button styles */
.MuiButton-containedPrimary {
  color: white !important;
}

.MuiButton-containedPrimary:hover {
  color: white !important;
}

/* .edit-btn .MuiIconButton-root {
  color: var(--border-inverse-color);
}

.edit-btn .MuiIconButton-root:hover {
  background-color: var(--shadow-color);
} */

/* Add report count info styles */
.report-count-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.report-count {
  color: var(--text-secondary);
  font-size: 14px;
}
