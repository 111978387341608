/* src/components/DeviceAssignment.css */
.device-assignment-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  background-color: var(--bg-secondary);
  overflow: hidden;
  color: var(--text-primary);
  width: 100%;
}

/* Search input styling */
.device-assignment-controls {
  display: flex;
  align-items: center;
  padding: 0 15px;
  /* width: 100%; */
  overflow: hidden;
}

.device-assignment-search-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.device-assignment-search-input {
  width: 100%;
  margin-top: 10px;
  padding: 8px 12px 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  box-sizing: border-box;
  overflow: hidden;
}

/* Remove the highlight/outline when focused */
.device-assignment-search-input:focus {
  outline: none;
  border-color: var(--border-color);
  box-shadow: 0 0 0 1px var(--border-color);
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
}

.device-assignment-search-clear {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 4px;
  height: 24px;
  width: 24px;
}

.device-assignment-search-clear:hover {
  color: var(--text-primary);
}

/* Table styling */
.device-assignment-devices-table {
  width: 100%;
  flex: 1;
  overflow-y: visible;
  overflow-x: hidden;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  font-family: Arial, sans-serif; /* Use Arial font */
}

.device-assignment-devices-table table {
  width: 100%;
  table-layout: fixed; /* Fixed layout for controlled column widths */
  border-collapse: separate;
  border-spacing: 0;
  overflow-y: visible;
}

.device-assignment-devices-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-secondary);
}

.device-assignment-devices-table th,
.device-assignment-devices-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
  vertical-align: middle;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Column widths - 50/50 split */
.device-assignment-devices-table th:first-child,
.device-assignment-devices-table td:first-child {
  width: 50%;
  white-space: normal; /* Allow text wrapping */
  overflow: visible; /* Show all content */
  word-break: break-word; /* Break long words if needed */
  line-height: 1.3; /* Add spacing between wrapped lines */
}

.device-assignment-devices-table th:nth-child(2),
.device-assignment-devices-table td:nth-child(2) {
  width: 50%;
}

.device-assignment-devices-table th {
  font-weight: 500;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.device-assignment-devices-table th .MuiTableSortLabel-root {
  color: var(--text-secondary);
}

.device-assignment-devices-table th .MuiTableSortLabel-root.Mui-active {
  color: var(--text-primary);
}

.device-assignment-devices-table th .MuiTableSortLabel-icon {
  color: var(--text-primary) !important;
}

.device-assignment-devices-table tbody {
  background-color: var(--bg-primary);
}

.device-assignment-devices-table tr:last-child td {
  border-bottom: none;
}

.device-assignment-devices-table tr {
  cursor: pointer;
  transition: background-color 0.1s ease;
  background-color: var(--bg-primary);
}

.device-assignment-devices-table tr:hover {
  background-color: var(--bg-secondary);
}

.device-assignment-devices-table tr.device-assignment-active-shift {
  background-color: var(--bg-primary);
}

.device-assignment-devices-table tr.device-assignment-active-shift:hover {
  background-color: var(--bg-secondary) !important;
}

.device-assignment-devices-table td:first-child {
  font-weight: 500;
  padding-left: 12px;
}

.device-assignment-shift-status-container {
  display: flex;
  align-items: center;
}

/* Device count info */
.device-assignment-count-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px 10px;
}

.device-assignment-count {
  color: var(--text-secondary);
  font-size: 14px;
}

/* Recording dot */
@keyframes device-assignment-blink {
  0%,
  83.33%,
  100% {
    opacity: 1;
  }
  91.67% {
    opacity: 0.2;
  }
}

.device-assignment-recording-dot {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  color: red;
  margin-left: 6px;
  animation: device-assignment-blink 3s ease-in-out infinite;
  font-size: 0.75em;
}

.device-assignment-shift-dot {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  color: green;
  margin-left: 6px;
  font-size: 0.75em;
}

/* Styles for the pull-out pane */
.device-assignment-pane {
  position: fixed;
  top: 0;
  left: -300px; /* Hidden by default */
  width: 300px;
  height: 100%;
  background-color: var(--bg-primary);
  box-shadow: 2px 0 5px var(--shadow-color);
  transition: left 0.1s ease;
  z-index: 1000;
  color: var(--text-primary);
}

.device-assignment-pane.device-assignment-open {
  left: 0; /* Slide in when open */
}

.device-assignment-toggle-device-pane-button {
  position: fixed;
  top: 50%;
  left: 60px; /* Start position when pane is closed */
  transform: translateY(-50%);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  transition: left 0.1s ease; /* Ensure the button moves with the pane */
}

.device-assignment-toggle-device-pane-button:hover {
  background-color: var(--hover-bg);
}

/* Custom header styles */

.css-yrdy0g-MuiDataGrid-columnHeaderRow,
.MuiDataGrid-columnHeader {
  height: 40px !important; /* Adjust the header height */
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent,
.MuiDataGrid-columnHeaderTitle {
  font-size: 0.875em !important; /* Adjust the font size */
  font-weight: bold !important;
  color: var(--text-primary) !important;
}

.MuiTablePagination-toolbar {
  padding: 0px !important;
  color: var(--text-primary) !important;
}

/* FAB */

.device-assignment-zoom-out-fab {
  position: absolute !important;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  background-color: var(--button-bg) !important;
  color: var(--button-text) !important;
}

.device-assignment-zoom-out-fab svg {
  transform: scale(1.3);
}

.device-assignment-zoom-out-fab:hover {
  background-color: var(--button-hover-bg) !important;
}

/* Ensure the FAB doesn't overlap with the DataGrid pagination */
.MuiDataGrid-footerContainer {
  padding-right: 64px !important;
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
}

.MuiTooltip-popper {
  z-index: 1300 !important;
}

/* DataGrid dark mode styles */
.MuiDataGrid-root {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  border-color: var(--border-color) !important;
}

.MuiDataGrid-overlay {
  background-color: var(--bg-secondary) !important;
  color: var(--text-secondary) !important;
}

.MuiDataGrid-main {
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-virtualScroller {
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-row {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
}

.MuiDataGrid-row:hover {
  background-color: var(--hover-bg) !important;
}

.MuiDataGrid-cell {
  border-color: var(--border-color) !important;
  color: var(--text-primary) !important;
  background-color: var(--bg-primary) !important;
}

.MuiDataGrid-columnHeaders {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  border-color: var(--border-color) !important;
  border-bottom: none !important;
  min-height: 40px !important;
}

.MuiDataGrid-columnHeader {
  color: var(--text-primary) !important;
}

.MuiDataGrid-footerContainer {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  border-color: var(--border-color) !important;
}

.MuiDataGrid-columnSeparator {
  color: var(--border-color) !important;
}

.MuiDataGrid-menuIcon button {
  color: var(--text-secondary) !important;
}

.MuiDataGrid-sortIcon {
  color: var(--text-secondary) !important;
}

/* GuardActionBanner styles */
.device-assignment-guard-action-banner {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 40px;
  z-index: 10;
}

.device-assignment-logo-container {
  width: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.device-assignment-plix-logo {
  width: 36px !important;
  height: 36px;
  transition: filter 0.5s ease;
}

.device-assignment-plix-logo.black {
  filter: brightness(0);
}

.device-assignment-plix-logo.green {
  filter: hue-rotate(120deg) brightness(0.8) saturate(2);
}

.device-assignment-plix-logo.red {
  filter: none;
}

.device-assignment-text-container {
  flex: 1;
  padding-left: 10px;
  overflow: hidden;
}

.device-assignment-action-text {
  display: block;
  transition: opacity 0.1s ease-in-out;
  font-size: 14px;
  color: #333;
}

.device-assignment-fade-in {
  opacity: 1;
}

.device-assignment-fade-out {
  opacity: 0;
}

/* Remove the old sortable-header styles */
.sortable-header {
  cursor: pointer;
  position: relative;
  user-select: none;
}

.sortable-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
