.personnel-selector-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: fit-content;
}

.personnel-selector-controls {
  display: inline-flex;
  align-items: center;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
  position: relative;
  height: 40px;
  border: none;
}

.personnel-toggle-btn {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.personnel-toggle-btn:hover:not(.active) {
  background: var(--hover-bg);
  /* color: var(--text-primary); */
}

.personnel-toggle-btn.active {
  background: var(--bg-secondary);
  /* color: var(--text-primary); */
  box-shadow: 0 1px 3px var(--shadow-color);
}

.personnel-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 20px var(--shadow-color);
  z-index: 11;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.dropdown-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-secondary);
  padding: 5px 10px;
  transition: all 0.2s ease;
}

.dropdown-actions button:hover {
  background-color: var(--hover-bg);
  color: var(--text-primary);
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: var(--hover-bg);
}

.dropdown-item.selected {
  background-color: var(--hover-bg);
  font-weight: 600;
  color: var(--button-bg);
}

.dropdown-item-check {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  color: var(--button-bg);
}

.dropdown-item-name {
  flex-grow: 1;
}

/* Custom scrollbar */
.personnel-dropdown::-webkit-scrollbar {
  width: 8px;
}

.personnel-dropdown::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.personnel-dropdown::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
  border-radius: 4px;
}

.personnel-dropdown::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
}
