.no-access-container {
  padding: 2rem;
  text-align: center;
  color: var(--text-primary);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary);
  box-sizing: border-box;
}

.no-access-card {
  background: var(--card-bg);
  border-radius: 12px;
  padding: 2.5rem;
  box-shadow: 0 4px 12px var(--shadow-color);
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}

.no-access-icon-container {
  width: 60px;
  height: 60px;
  margin: 0 auto 1.5rem;
  background: var(--bg-error);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-access-icon path {
  fill: var(--error-color);
}

.no-access-title {
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.no-access-message {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: var(--text-primary);
}

.no-access-note {
  line-height: 1.6;
  font-style: italic;
  color: var(--text-secondary);
}
