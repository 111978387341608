/* Styles for DeviceManagement component */

.device-management-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.device-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.device-management-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.configure-all-btn {
  padding: 8px 16px;
  background-color: var(--button-bg);
  color: white !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.configure-all-btn:hover {
  background-color: var(--button-hover-bg);
  color: white !important;
}

/* Group Activation switch styles */
.group-activation-switch .MuiSwitch-switchBase.Mui-checked {
  color: var(--button-bg) !important;
}

.group-activation-switch .MuiSwitch-switchBase.Mui-checked:hover {
  background-color: rgba(203, 45, 62, 0.08) !important;
}

.group-activation-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--button-bg) !important;
}

.group-activation-switch.MuiFormControlLabel-root .Mui-checked ~ .MuiFormControlLabel-label {
  color: var(--button-bg) !important;
}

.device-management-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.device-management-search-container {
  width: 300px;
}

.device-management-search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.devices-table {
  width: 100%;
  /* max-height: 370px; */
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--bg-secondary);
}

.devices-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.devices-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-secondary);
}

.devices-table th,
.devices-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.devices-table th {
  font-weight: 500;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.devices-table th:first-child {
  border-top-left-radius: 6px;
}

.devices-table th:last-child {
  border-top-right-radius: 6px;
}

.devices-table tr:last-child td {
  border-bottom: none;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.configure-btn .MuiIconButton-root {
  color: var(--border-inverse-color);
}

.configure-btn .MuiIconButton-root:hover {
  background-color: var(--shadow-color);
}

.device-count-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.device-count {
  color: var(--text-secondary);
  font-size: 14px;
}

/* MUI Button Override */
.MuiButton-containedPrimary {
  color: white !important;
}

.pagination-btn:hover {
  background: var(--hover-bg);
}

.MuiButton-containedPrimary:hover {
  color: white !important;
}
