/* Styles for TriggerPhrases component */

.trigger-phrases-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  background: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.trigger-phrases-section,
.stop-phrase-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.trigger-phrases-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  /* margin-bottom: 16px; */
}

.trigger-phrases-header h2,
.stop-phrase-section h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.trigger-phrases-container .MuiListItem-root {
  background: var(--bg-primary);
  color: var(--text-primary);
}

.trigger-phrases-description {
  color: var(--text-secondary);
}

.stop-phrase-section h3 {
  margin-bottom: 16px;
}

.trigger-phrases-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.trigger-phrase-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--bg-primary);
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

.trigger-phrase-text {
  color: var(--text-primary);
  font-size: 14px;
}

/* Empty state */
.no-phrases {
  text-align: center;
  padding: 32px;
  color: var(--text-secondary);
}

.add-phrase-button {
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 6px 16px !important;
}
