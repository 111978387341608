.event-detail-video-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 600px;
  min-height: 600px;
}

.event-detail-video-player-container {
  width: 100%;
  height: 100%;
}

.video-placeholder {
  width: auto;
  background-color: var(--bg-secondary);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px var(--shadow-color);
  position: relative;
}

@media (max-width: 768px) {
  .event-detail-video-view-container.mobile {
    width: 100%;
  }

  .event-detail-video-player-container {
    width: 100%;
    padding-top: 100%;
    /* Creates a square aspect ratio */
    position: relative;
    overflow: hidden;
  }

  .event-detail-video-player-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-container {
    width: 100%;
    height: 100%;
  }

  .video-container .plyr {
    width: 100%;
    height: 100%;
  }

  .video-container .plyr video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
