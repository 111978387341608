.location-info-container {
  background-color: var(--bg-secondary);
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: auto;
}

.location-info-container h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--text-primary);
}

.location-info-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}

.title-container {
  display: flex;
  align-items: center;
}

.location-info-header h3 {
  margin: 0;
  font-size: 18px;
  color: var(--text-primary);
  margin-right: 15px; /* Add some spacing between title and button */
}

.title-container {
  display: flex;
  flex-direction: column;
}

.location-info-header h3 {
  margin: 0;
  font-size: 18px;
  color: var(--text-primary);
}

.last-seen-description {
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 2px;
}

.location-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.location-detail-row {
  display: flex;
  align-items: flex-start;
}

.location-detail-icon {
  width: 30px;
  color: var(--text-secondary);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-detail-value {
  flex: 1;
  font-size: 14px;
  color: var(--text-primary);
  word-break: break-word;
  padding-left: 10px;
}

.last-seen {
  font-size: 12px;
  color: var(--text-secondary);
  font-weight: normal;
}
