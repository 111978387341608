.timeline-container {
  width: 80%;
  max-height: 600px;
  overflow-y: auto;
  padding: 0;
}

.timeline-header {
  padding: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.timeline-item {
  cursor: pointer;
  transition: opacity 0.1s ease;
}

.timeline-item:not(.selected) {
  opacity: 0.7;
}

.timeline-item.selected {
  opacity: 1;
  font-weight: bold !important;
}

.selected-title {
  font-weight: bold !important;
}

.timeline-item-title {
  font-size: 14px;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.timeline-item-title.deactivated,
.timeline-item-title.activated {
  font-size: 14px;
  color: var(--text-primary);
}

.timeline-item-title.site-enter {
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--text-primary);
}

.timeline-item-duration {
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 4px;
}

.timeline-item-description {
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 4px;
  display: block;
}

[data-theme='light'] .timeline-item-description {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 4px 8px;
}

.timeline-container .MuiTimelineItem-root:before {
  flex: 0;
  padding: 0;
}

.timeline-item:first-child {
  opacity: 1;
}

.timeline-item-clicked {
  animation: click-animation 0.3s ease;
}

@keyframes click-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.battery-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
  vertical-align: middle;
  line-height: 1;
}

.battery-percentage {
  font-size: 12px;
  color: var(--text-secondary);
  line-height: 1;
  display: inline-flex;
  align-items: center;
}

.battery-info {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
}

.activated {
  color: var(--text-secondary);
  font-size: 14px;
}

.deactivated {
  color: var(--text-secondary);
  font-size: 14px;
}

/* Make the icons larger and properly aligned */
.MuiTimelineDot-root {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 20px !important;
  min-height: 20px !important;
}

/* For activation/deactivation dots */
.MuiTimelineDot-root.small-dot {
  min-width: 24px !important;
  min-height: 24px !important;
}

.MuiTimelineDot-root svg {
  width: 24px !important;
  height: 24px !important;
  color: var(--timeline-icon-color) !important;
}

.MuiTimelineDot-root.small-dot svg {
  width: 12px !important;
  height: 12px !important;
  color: var(--timeline-icon-color) !important;
}

.MuiTimelineOppositeContent-root {
  color: var(--text-secondary) !important;
}

/* Target MUI Typography in timeline content */
.MuiTimelineContent-root {
  padding-top: 0 !important;
  margin-top: 6px !important;
  min-height: 40px !important;
}

.MuiTimelineDot-root {
  margin-top: 4px !important;
}

.MuiTimelineContent-root .MuiTypography-root {
  font-size: 18px !important;
  line-height: 1.2 !important;
}

/* Keep existing styles for activated/deactivated states */
.timeline-item.activated .MuiTypography-root {
  color: #66bb6a !important;
}

.timeline-item.deactivated .MuiTypography-root {
  color: var(--text-secondary) !important;
}

/* Update the timeline connector thickness */
.MuiTimelineConnector-root {
  width: 3px !important; /* Increased from 2px to 3px */
  min-height: 40px !important;
}
