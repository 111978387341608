.shiftreview-container {
  display: flex;
  flex-direction: column;
  /* overflow: visible; */
  height: 100%;
  margin-bottom: 50px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.shiftreview-header {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 40px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 2px solid var(--border-color);
  margin: 25px 25px 0 25px;
  color: var(--text-primary);
}

.shiftreview-controls {
  display: flex;
  flex-direction: row;
  background-color: var(--bg-secondary);
  align-items: center;
  justify-content: left;
  height: auto;
  padding: 20px 20px 20px 25px;
  position: relative;
  z-index: 1;
}

.shiftreview-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0;
  flex: 1;
  background-color: var(--bg-primary);
}

.no-flagged-shifts-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

.no-shifts-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: var(--text-secondary);
}
