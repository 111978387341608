.alert-description {
  font-size: 0.9em;
  margin: 0;
  /* border-radius: 10px; */
  /* margin-bottom: 50px; */
}

.alert-description.in-shift-expanded .transcript-box {
  height: 150px;
}

.description {
  color: var(--text-primary);
  font-style: italic;
  margin: 0;
}

.keyword-description {
  color: var(--text-primary);
  font-weight: bold;
  margin: 0;
  /* font-size: 1.3em; */
}

.description:hover {
  color: #555;
}

.transcript-box {
  overflow-y: auto;
  border: 1px solid var(--border-color);
  padding: 5px 0px;
  border-radius: 60 !important;
  /* margin-bottom: 50px; */
  /* padding-bottom: 10px; */
  /* height: auto; */
  max-height: 200px !important;
  background-color: var(--bg-primary);
}

.transcript-line {
  display: flex;
  align-items: flex-start;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  color: var(--text-primary);
}

.transcript-line:hover {
  background-color: var(--hover-bg);
}

.escalation-text {
  font-weight: bold;
  color: #ff0000;
}

.view-transcript-button {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: auto;
  margin-top: 10px;
  display: block;
}

.view-transcript-button:hover {
  color: #cb2d3e;
}

.alert-description-items {
  margin: 0;
  padding: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.transcript-line.active {
  background-color: rgba(203, 45, 62, 0.2);
}

.transcript-line {
  display: flex;
  align-items: flex-start;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
}

.transcript-line:hover {
  background-color: var(--hover-bg);
}

.transcript-timestamp {
  flex: 0 0 50px;
  color: var(--text-secondary);
  font-size: 0.9em;
  margin-right: 5px;
}

.transcript-speaker {
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.5px;
  margin-right: 5px;
  color: #666;
}

.transcript-speaker.officer {
  color: #cb2d3e;
}

.transcript-text {
  flex: 1;
  margin: 0 !important;
  line-height: 1.5;
  padding-right: 80px; /* Add padding to prevent overlap with event pill */
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: var(--text-primary);
}

.escalation-text {
  font-weight: bold;
  color: #ff0000;
}

.event-pill {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f5e5e6;
  color: #cb2d3e;
  font-size: 0.8em;
  padding: 2px 6px;
  border-radius: 10px;
  white-space: nowrap;
}

.event-dot {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  background-color: rgba(203, 45, 62, 0.2);
  border-radius: 50%;
}

.view-transcript-button {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: auto;
  margin-top: 10px;
  display: block;
}

.view-transcript-button:hover {
  color: #cb2d3e;
}

@media (max-width: 768px) {
  .transcript-box {
    max-height: 150px;
  }

  .transcript-timestamp {
    flex: 0 0 40px;
    font-size: 10px;
  }

  .transcript-speaker {
    flex: 0 0 20px;
    font-size: 10px;
  }

  .transcript-text {
    font-size: 10px;
    padding-right: 70px; /* Adjust padding for smaller screens */
  }

  .event-pill {
    font-size: 9px;
    padding: 1px 4px;
  }

  .event-dot {
    width: 4px;
    height: 4px;
    right: 3px;
  }
}

/* Special styles when transcript is inside event-info-content */
.event-info-content .alert-description {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.event-info-content .transcript-box {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  max-height: none !important;
}

@media (max-width: 768px) {
  .transcript-box {
    max-height: 150px;
  }

  .event-info-content .transcript-box {
    flex: 1;
    min-height: 0;
  }
}

/* Pre-event */

.transcript-line.pre-event {
  cursor: default;
  color: var(--text-secondary);
}

.transcript-line.pre-event:hover {
  background-color: transparent;
}

.transcript-line.pre-event .transcript-timestamp {
  visibility: hidden;
}

/* Event timeline */
.alert-transcript-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.alert-timeline-container {
  position: relative;
  height: 40px;
  overflow-x: auto;
  margin: 10px 0;
  padding: 10px 0;
}

.alert-timeline-scroll {
  display: inline-flex;
  align-items: center;
  min-width: 100%;
}

.alert-timeline {
  position: relative;
  height: 40px;
  /* background-color: #ffebee; */
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.alert-timeline::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #cb2d3e;
  transform: translateY(-50%);
}

.alert-timeline-event {
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #cb2d3e;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 1.2em;
  color: #333;
  white-space: nowrap;
  transition: all 0.3s ease;
  margin: 0 10px; /* Add spacing between events */
  z-index: 1; /* Ensure events are above the line */
}

.alert-timeline-event:hover {
  /* background-color: #cb2d3e; */
  background-color: #eee;
  /* color: #fff; */
}

.alert-timeline-event.active {
  background-color: #cb2d3e;
  color: #fff;
}

/* .transcript-box {
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #ededed;
  padding: 5px 0px;
  border-radius: 6px !important;
} */

/* Scrollbar */

/* .alert-timeline-container::-webkit-scrollbar {
  height: 2px;
}

.alert-timeline-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.alert-timeline-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.alert-timeline-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.alert-timeline-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.alert-timeline-container:hover {
  scrollbar-color: #888 #f1f1f1;
}
.alert-timeline-container {
  scrollbar-width: thin;
} */

/* Event choice chips */

.event-type-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* margin-top: 10px; */
  margin-bottom: 5px;
  align-items: center;
  padding: 5px 12.5px 5px 12.5px;
}

.event-type-chip {
  position: relative;
  cursor: pointer;
  background-color: var(--bg-primary);
  border: 0.5px solid #cb2d3e;
  /* border: none; */
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 1.2em;
  color: var(--text-primary);
  white-space: nowrap;
  transition: all 0.1s ease;
  z-index: 1;
  display: flex;
  align-items: center;
}

.event-type-chip:hover {
  background-color: var(--hover-bg);
}

.event-type-chip.selected {
  background-color: #cb2d3e;
  color: #fff;
}

.event-type-count {
  margin-left: 6px;
  font-size: 0.8em;
  opacity: 0.7;
}

.event-type-count i {
  font-size: 0.8em;
  margin-right: 2px;
}

.see-all-transcripts {
  font-size: 0.9em;
  background: none;
  border: none;
  color: #cb2d3e;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 12px;
  transition: all 0.1s ease;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.see-all-transcripts.visible {
  opacity: 1;
  visibility: visible;
  display: block;
  /* height: fit-content; */
}

.see-all-transcripts:hover {
  background-color: rgba(203, 45, 62, 0.1);
}

/* No video recording */

.transcript-line.pre-event .transcript-timestamp {
  visibility: visible;
}

.pre-event-icon {
  color: #bbb;
  opacity: 0;
  font-size: 1.1em;
  transition: opacity 0.1s ease-in-out;
}

.transcript-line.pre-event:hover .pre-event-icon {
  opacity: 1;
}

/* Custom styles for Material-UI Tooltip */
.custom-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: white !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
}

/* Ensure the tooltip arrow matches the tooltip style */
.custom-tooltip .MuiTooltip-arrow {
  /* color: rgba(0, 0, 0, 0.8) !important; */
  display: none;
}

/* Transcript line */

.transcript-line {
  /* ... existing styles ... */
  position: relative;
}

.event-pill {
  /* ... existing styles ... */
  z-index: 2; /* Ensure the pill is above the line */
}

.event-line {
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #f5e5e6;
}

.event-dot {
  position: absolute;
  right: -3.5px;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 9px;
  background-color: #f5e5e6;
  border-radius: 50%;
}

.transcript-line.first-in-event .event-line {
  top: 50%;
}

.transcript-line.last-in-event .event-line {
  bottom: 50%;
}

.transcript-line.in-event:not(.first-in-event):not(.last-in-event) .event-line {
  top: 0;
  bottom: 0;
}

/* Tab styling */
.transcript-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  padding: 0 12.5px;
}

.tab-button {
  padding: 4px 10px;
  border: none;
  background: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease;
  color: #666;
  background-color: #eee;
}

.tab-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tab-button.active {
  background-color: #cb2d3e;
  color: white;
}

.tab-button:not(.active):hover:not(:disabled) {
  background-color: #d5d5d5;
}

/* Detected Persons styling */
.detected-persons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px 12.5px;
  margin-bottom: 5px;
}

.person-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 56px; /* Thumbnail + padding */
}

.person-card.selected {
  max-width: 300px;
  background-color: var(--hover-bg);
  border-color: var(--border-color);
}

.person-card:not(.selected):hover {
  background-color: var(--hover-bg);
}

.person-thumbnail {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--bg-secondary);
}

.person-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.placeholder-thumbnail {
  width: 100%;
  height: 100%;
  background-color: var(--bg-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
}

.placeholder-thumbnail i {
  font-size: 20px;
}

.person-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.person-timestamp {
  font-size: 12px;
  color: var(--text-secondary);
}

.person-description {
  font-size: 13px;
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .event-type-chip {
    font-size: 10px;
  }

  .event-type-chips {
    margin-bottom: 0;
  }

  .transcript-box {
    height: 100px;
  }

  .see-all-transcripts {
    font-size: 10px;
  }

  .report-editor-fullpage .event-type-chips {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
