/* Theme variables */
:root {
  transition: all 0.2s ease;
}

[data-theme='light'] {
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --text-primary: #333333;
  --text-secondary: #666666;
  --border-color: #e0e0e0;
  --border-inverse-color: #404040;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-bg: #ffffff;
  --table-header-bg: #ffffff;
  --table-row-hover: #f5f5f5;
  --input-bg: #ffffff;
  --button-bg: #cb2d3e;
  --button-hover-bg: #b52d2e;
  --button-text: #ffffff;
  --card-bg: #ffffff;
  --error-color: #ff0000;
  --modal-backdrop: rgba(0, 0, 0, 0.5);
  --bg-error: #fff2f2;
  --bg-disabled: #f0f0f0;
  --in-shift-bg: #e8f5e9;
  --recording-bg: #ffebee;
  --skeleton-base: #f0f0f0;
  --skeleton-highlight: #f8f8f8;
  --skeleton-animation-color: rgba(0, 0, 0, 0.1);
  --sidebar-icon-color: black;
  --scrollbar-bg: #f0f0f0;
  --scrollbar-thumb: #c1c1c1;
  --scrollbar-thumb-hover: #a8a8a8;
  --bg-primary-rgb: 255, 255, 255;
  --border-color-rgb: 0, 0, 0;
  --timeline-icon-color: #333333;
  --button-disabled-bg: #e0e0e0;
}

[data-theme='dark'] {
  --bg-primary: #1e1e1e;
  --bg-secondary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #e0e0e0;
  --border-color: #404040;
  --border-inverse-color: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --hover-bg: #1e1e1e;
  --table-header-bg: #1e1e1e;
  --table-row-hover: #3d3d3d;
  --input-bg: #2d2d2d;
  --button-bg: #cb2d3e;
  --button-hover-bg: #b52d2e;
  --button-text: #ffffff;
  --card-bg: #2d2d2d;
  --error-color: #ff6b6b;
  --modal-backdrop: rgba(0, 0, 0, 0.7);
  --bg-error: #3d2929;
  --bg-disabled: #3d3d3d;
  --in-shift-bg: #1b3320;
  --recording-bg: #3d2929;
  --skeleton-base: #2d2d2d;
  --skeleton-highlight: #3d3d3d;
  --skeleton-animation-color: rgba(255, 255, 255, 0.1);
  --sidebar-icon-color: #ffffff;
  --scrollbar-bg: #2d2d2d;
  --scrollbar-thumb: #404040;
  --scrollbar-thumb-hover: #4a4a4a;
  --bg-primary-rgb: 30, 30, 30;
  --border-color-rgb: 255, 255, 255;
  --timeline-icon-color: #ffffff;
  --button-disabled-bg: #404040;
}
