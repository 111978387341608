.shared-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 20px);
  background-color: white;
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-bottom: 40px; */
}

.shared-video-player-wrapper {
  width: auto;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 200px);
  gap: 10px;
}

.shared-video-container {
  width: 48.2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.shared-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
}

.shared-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.shared-transcript-container {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.shared-transcript-container .alert-description {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.shared-transcript-container .transcript-box {
  flex: 1;
  max-height: none !important;
  margin-bottom: 0;
}

.shared-transcript-container .view-transcript-button {
  display: none;
}

@media screen and (max-width: 768px) {
  .shared-video-player-wrapper {
    flex-direction: column;
    height: auto;
  }

  .shared-video-container,
  .shared-transcript-container {
    width: 100%;
    height: auto;
  }

  .shared-transcript-container .transcript-box {
    max-height: 200px !important;
  }
}

.shared-video-display {
  width: 100%;
  height: auto;
  padding: 20px;
  flex-direction: column;
  align-items: stretch;
  overflow-y: visible;
  padding-bottom: 20px;
}

.shared-video-metadata {
  width: 100%;
  background-color: #f2f2f2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
  margin-bottom: 20px;
  max-height: 20vh;
}

.shared-metadata-content {
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  padding: 15px;
}

.shared-metadata-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
  color: #191919;
}

.shared-metadata-header .filename {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.shared-metadata-header .trash-icon {
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.1s;
}

.shared-metadata-header .trash-icon:hover {
  color: #f2f2f2;
}

.shared-metadata-details {
  flex: 1 1 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px 0 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.shared-metadata-item {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.shared-metadata-label {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

.shared-metadata-value {
  font-size: 1.1em;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.shared-metadata-value svg {
  margin-left: 5px;
}

.shared-metadata-tags-container {
  grid-column: span 2;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 120px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.password-prompt-container {
  background-color: #cb2d3e;
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.password-prompt {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.password-prompt h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}

.password-prompt form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.password-prompt input {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

.password-prompt input:focus {
  outline: none;
  border-color: #cb2d3e;
}

.password-prompt button {
  background-image: linear-gradient(to right, #cb2d3e 0%, #ef473a 100%);
  border: none;
  color: white;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.password-prompt button:hover {
  transform: translateY(-2px);
}

.error {
  color: #cb2d3e;
  margin-top: 10px;
  font-size: 14px;
}

.shared-download-button {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.shared-download-button:hover {
  background-color: #e0e0e0;
}

.shared-download-button:active {
  transform: scale(0.98);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.shared-download-button.disabled {
  pointer-events: none;
}

.shared-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  height: auto;
}

.shared-plix-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 15px; */
  width: 120px;
  height: 40px;
}

.shared-plix-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: black;
  letter-spacing: 1px;
}

.expired-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.expired-icon {
  font-size: 48px;
  color: #ff6b6b;
  margin-bottom: 1.5rem;
}

.expired-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.expired-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.expired-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #666;
  margin: 0;
}

.not-found-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.not-found-icon {
  font-size: 48px;
  color: #6b7280;
  margin-bottom: 1.5rem;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.not-found-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.not-found-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #666;
  margin: 0;
}

.shared-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0;
}

.shared-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shared-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.shared-header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

/* Specific styles for video player within shared container */
.shared-video-container .video-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.shared-video-container .plyr {
  /* width: 100%; */
  height: 100%;
}

.shared-video-container .plyr video {
  /* width: 100%; */
  height: 100%;
  /* object-fit: contain; */
}
