.view-selector-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.view-selector-controls {
  display: inline-flex;
  height: 40px;
  align-items: center;
  background: #f0f0f0;
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
}

.view-toggle-btn {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #666;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-toggle-btn:hover {
  background: rgba(255, 255, 255, 0.5);
}

.view-toggle-btn.active {
  background: white;
  color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
