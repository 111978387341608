.icon-box {
  background-color: var(--bg-secondary);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 0px;
}

.icon {
  color: #ec453a;
  font-size: 1.5em;
}

.time-since {
  font-size: 1.25em;
  margin-top: 15px;
  color: var(--text-secondary);
  text-align: center;
  align-self: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 5px 15px 5px 5px;
  width: calc(100% - 20px);
}

.card-label {
  font-weight: bold;
  font-size: 1.1em;
  margin: 0;
  color: var(--text-primary);
}

.card-description {
  font-size: 1em;
  color: var(--text-secondary);
  margin: 0;
}

.card-thumbnail {
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 10px;
  width: auto;
  height: 100%;
  margin-right: 0;
  transition: transform 0.1s ease-out;
  height: auto;
  border-radius: 10px;
  width: 100%;
}

.card:hover {
  box-shadow: 0 4px 6px var(--shadow-color);
  cursor: pointer;
  background-color: var(--hover-bg);
}

.card {
  position: relative;
  /* Ensures internal absolute positioning works correctly */
  display: flex;
  /* Ensures flexible layout of internal elements */
  flex-direction: column;
  /* Stacks children vertically */
  align-items: flex-start;
  /* Aligns items to the start, making space for absolute elements */
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  background-color: var(--bg-secondary);
  transition:
    box-shadow 0.1s,
    background-color 0.1s;
}

.card-body {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  width: 100%;
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

/* .time-info {
  text-align: right;
} */

.card-details {
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--success-bg) !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  color: var(--text-primary) !important;
  cursor: pointer;
}

.card-details[style*='background-color'] {
  background-color: var(--success-bg) !important;
  color: var(--text-primary) !important;
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 10px;
  transition: box-shadow 0.1s;
}

.card-top {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.star-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  /* Default color is light gray */
}

.star-icon {
  color: inherit;
  font-size: 1.5em;
}

.card .time-info {
  margin-top: 10px;
}

.card .time-info .date {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.card .time-info .time {
  font-size: 1.4rem;
  color: var(--text-primary);
}

.card .time-info .am-pm {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.card.not-customer-visible,
.card.deleted {
  background-color: var(--bg-disabled);
}

.card.not-customer-visible .card-content .card-body,
.card.deleted .card-content .card-body {
  opacity: 0.5;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: opacity 0.2s;
  color: var(--text-secondary);
}

.action-button:hover {
  opacity: 0.7;
  color: var(--button-bg);
}

.delete-button {
  color: var(--text-secondary);
}

.delete-button:hover {
  color: var(--error-color);
}

.delete-button-active {
  color: var(--error-color);
}

/* Remove these styles if they exist */
.action-button.inactive {
  opacity: 1;
  cursor: pointer;
}

.action-button.inactive:hover {
  background-color: transparent;
}

.card.deleted {
  background-color: var(--bg-error);
}

.models-fired {
  font-size: 0.9em;
  color: var(--text-secondary);
  margin-top: 5px;
}

.thumbs-container {
  position: relative;
  display: flex;
}

.classification-tooltip {
  position: absolute;
  top: 100%;
  /* Changed from bottom: 100% to top: 100% */
  right: 0%;
  /* Changed from left: 50% to right: 100% */
  transform: translateY(5px);
  /* Slight offset from the bottom */
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px var(--shadow-color);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  /* Prevents the tooltip from interfering with other elements */
}

.thumbs-container:hover .classification-tooltip {
  opacity: 1;
  pointer-events: auto;
  /* Re-enables pointer events when visible */
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.75em;
}

.tooltip-row:last-child {
  margin-bottom: 0;
}

.tooltip-row.highlighted {
  color: #d32f2f;
  font-weight: bold;
}

.model-name {
  margin-right: 10px;
}

.classification-icon {
  font-size: 0.9em;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: auto;
  /* Push the footer to the bottom */
}

.category-label {
  position: relative;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  color: #333;
  cursor: pointer;
}

.category-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-top: 5px;
  /* Same distance as classification tooltip */
  min-width: 200px;
}

.category-label:hover .category-tooltip {
  opacity: 1 !important;
}

.category-tooltip .tooltip-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
  font-size: 0.75em;
  /* Same font size as classification tooltip */
}

.category-tooltip .tooltip-row:last-child {
  margin-bottom: 0;
}

.category-tooltip .model-name {
  margin-bottom: 5px;
  color: #333;
}

.category-tooltip .tooltip-row.highlighted .model-name {
  color: #d32f2f;
  font-weight: bold;
}

.category-tooltip .category-pills {
  display: flex;
  flex-wrap: nowrap;
  /* Keep pills on one line */
  gap: 5px;
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
  width: 100%;
}

.category-tooltip .category-pill {
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.9em;
  /* Slightly larger than the model name for readability */
  color: white;
  white-space: nowrap;
  /* Prevent pill text from wrapping */
}

.category-selector {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-bottom: 5px;
  min-width: 200px;
}

.category-selector .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
  color: #666;
}

.category-selector .category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.category-selector .category-pill {
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.75em;
  color: #333;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.05s;
}

.category-selector .category-pill.active {
  opacity: 1;
  font-weight: bold;
}

.category-selector .category-pill:hover {
  opacity: 0.8;
}

.alert-deviceId {
  width: fit-content !important;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Link to reports page */

.report-link {
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 1;
}

.report-icon {
  color: #bbb !important; /* Light gray color */
  font-size: 1.2em;
  transition:
    color 0.1s ease,
    transform 0.1s ease;
}

.report-icon:hover {
  color: #333 !important; /* Darker gray on hover */
  /* animation: writing 1s ease-in-out infinite; */
}

.report-link.is-report .report-icon {
  color: hsl(354, 64%, 79%) !important;
}

.report-link.is-report:hover .report-icon {
  color: #cb2d3e !important; /* A slightly darker shade for hover effect */
}

.dictation-pill {
  display: inline-block;
  background-color: #333;
  color: white;
  /* background-color: #eee;
  color: #333; */
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 6px;
}

@keyframes writing {
  0%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  30% {
    transform: rotate(4deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(3deg);
  }
  60% {
    transform: rotate(-3deg);
  }
  70% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(1deg);
  }
}
