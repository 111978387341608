.shift-card-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 8px;
  transition: background-color 0.1s ease;
}

.shift-card-container:hover {
  background-color: var(--hover-bg);
}

.escalation-badge,
.battery-badge {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  width: auto;
  min-width: 50px;
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-primary);
}

/* Other icons and badges */

.battery-badge {
  min-width: 25px;
  justify-content: center;
  border: none;
}

.escalation-icon,
.battery-icon {
  color: #cb2d3e;
  flex-shrink: 0;
  margin-right: 4px;
}

.battery-icon {
  color: #ffa500;
  margin-right: 4px;
}

.escalation-count,
.battery-level {
  flex-grow: 1;
  text-align: center;
  padding: 5px;
  color: var(--text-primary);
}

.time-badge,
.date-badge {
  /* background-color: #f9f9f9; */
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  width: auto;
  min-width: 70px;
  text-align: center;
  display: inline-block;
  color: var(--text-primary);
}

.date-badge {
  border: none;
}

.duration-badge {
  margin-left: 8px;
  font-size: 0.9em;
  color: var(--text-secondary);
  min-width: 70px;
}

.time-badge.in-progress {
  color: #e74d3ce4;
  font-weight: 600;
  animation: pulse 3s infinite;
  font-size: 0.7em;
}

.time-badge.offline {
  color: var(--text-secondary);
  font-weight: 600;
  position: relative;
  cursor: pointer;
  font-size: 0.7em;
}

.time-badge.offline:hover::after,
.time-badge.in-progress:hover::after {
  content: attr(data-offline-since);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.8em;
  white-space: nowrap;
  z-index: 1000;
  font-weight: normal;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.shift-flags {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.flag-icon {
  font-size: 16px;
  margin-right: 5px;
  cursor: pointer;
}

.flag-icon.outlined {
  color: var(--text-secondary);
}

.flag-icon.filled {
  color: #f0ad4e;
}

/* .flag-icon.outlined:hover {
  color: #f0ad4e;
} */

.time-ratio-bar {
  width: 100px;
  height: 10px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: flex-start;
}

.online-time {
  height: 100%;
  background-color: #e74d3ce4;
  border-radius: 3px 0 0 3px;
}

.offline-time {
  height: 100%;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 0 3px 3px 0;
  min-width: 2.5px;
  margin-left: 1.5px;
}

/* Remove the following styles as they're no longer needed */
.time-ratio-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}

.time-ratio-bar:hover .time-ratio-tooltip {
  opacity: 1;
}

.online-time-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.online-time-decimal {
  font-size: 0.69em;
  color: var(--text-secondary);
}

.start-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.end-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.duration-divider {
  width: 120%;
  border: none;
  border-top: 1px solid #e74d3ce4;
  margin: 4px 0 30px 0; /* Added vertical margin */
}

.duration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px; /* Set a fixed width */
  /* font-weight: bold; */
}

.custom-tooltip-time {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.tooltip-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  color: var(--text-primary);
}

.tooltip-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.tooltip-dot.online {
  background-color: #e74d3ce4;
}

.tooltip-dot.offline {
  background-color: var(--text-secondary);
}
