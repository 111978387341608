html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* Prevents scrollbars if not necessary */
  font-family: 'Montserrat', sans-serif;
}

.app-container,
.home-container {
  background-color: var(--bg-secondary);
  /* Light gray */
  min-height: calc(100vh - 0px);
  /* Ensures it covers the full height of the viewport */
  display: flex;
  font-family: 'Montserrat', sans-serif;
}

.modern-button {
  font-family: 'Montserrat', sans-serif;
  background-image: var(--primary-gradient);
  /* Muted red gradient */
  border: none;
  color: var(--button-text);
  padding: 8px 20px;
  /* Smaller padding for a smaller button */
  border-radius: 5px;
  /* Slightly adjusted for the smaller size */
  margin: 5px;
  /* Adds space around the button */
  cursor: pointer;
  font-size: 14px;
  /* Smaller text size */
  letter-spacing: 0.5px;
  box-shadow: 0 3px 10px var(--shadow-color);
  /* Slightly lighter shadow for a subtler effect */
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  outline: none;
}

.modern-button:hover {
  transform: translateY(-2px);
  /* Slightly less movement for a subtler hover effect */
  box-shadow: 0 4px 12px var(--shadow-color-hover);
  /* Adjusted shadow for hover */
}

.modern-button:active {
  transform: translateY(-1px);
  /* Keeping the press effect subtle */
  box-shadow: 0 3px 10px var(--shadow-color);
  /* Reverting to the original shadow on press */
}

.cards-container {
  overflow-y: auto;
  transition: all 0.5s ease;
  width: 100%;
  flex-grow: 1;
  /* Take up all available space */
}

.cards-container.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: 90%;
  padding: 10px;
  transition: all 0.1s ease;
}

.checklist-container {
  overflow-y: auto;
  transition: all 0.5s ease;
  width: 100%;
  flex-grow: 1;
  /* Take up all available space */
  display: flex;
  flex-direction: column;
}

.checklist-card {
  margin-top: calc(100% - 50vh);
}

.card-container .card {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: var(--animation-delay, 0s);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Default layout (left side) */
.devices-container {
  position: absolute;
  top: 10px;
  width: 25%;
  max-width: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  box-shadow: 0px 4px 8px var(--shadow-color);
  z-index: 1000;
  border-radius: 8px;
  transition: max-height 0.1s ease-in-out;
  border: 1px solid var(--border-color);
}

.devices-container {
  left: 10px;
}

/* Search experimental layout (right side) */
.devices-container.search-experimental {
  left: auto;
  right: 10px;
  top: 10px;
  max-height: calc(50vh - 10px);
}

/* When devices container is collapsed in search experimental layout */
.devices-container.collapsed.search-experimental + .alerts-container.search-experimental {
  top: 80px;
}

/* Ensure content is scrollable but doesn't overflow */
.devices-container.search-experimental .content {
  overflow-y: auto;
  max-height: calc(100% - 50px); /* Account for header height */
}

/* Google Maps-style search bar */
.search-container-real-time {
  position: absolute;
  top: 15px;
  left: 24px;
  z-index: 1000;
  width: 100px;
  max-width: calc(100% - 48px);
  transition: width 0.1s ease;
}

.search-box {
  position: relative;
  width: 100%;
  background: white;
  border-radius: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease;
  padding: 12px;
}

.search-box.focused {
  width: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
}

.search-box.expanded {
  width: 500px;
}

.search-container-real-time:has(.search-box.expanded) {
  width: 500px;
}

.search-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 18px;
}

.search-input-real-time {
  width: 100%;
  padding: 4px;
  padding-left: 10px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  color: #333;
}

.search-input-real-time::placeholder {
  color: #444;
  opacity: 0.9;
  font-weight: 500;
}

.search-suggestions {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow-y: auto;
  transition: width 0.1s ease;
}

.search-container-real-time:has(.search-box.expanded) .search-suggestions {
  width: 524px;
}

.suggestion-item {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover,
.suggestion-item.selected {
  background: #f5f5f5;
}

.suggestion-icon {
  margin-right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  flex-shrink: 0;
}

.suggestion-content {
  flex: 1;
  min-width: 0;
}

.suggestion-text {
  color: #333;
  font-size: 15px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion-subtext {
  color: #666;
  font-size: 13px;
}

.search-alerts-option {
  border-top: 1px solid #eee;
  color: #666;
}

.search-alerts-option .suggestion-text {
  color: #666;
  font-size: 14px;
}

.alerts-container .content,
.devices-container .content {
  flex-grow: 1;
}

.devices-container .content {
  overflow-y: visible;
}

.alerts-container .content {
  overflow-y: auto;
}

.alerts-container .card {
  margin: 10px;
}

.devices-container.expanded,
.alerts-container.expanded.search-experimental {
  max-height: 45vh;
}

.devices-container.collapsed,
.alerts-container.collapsed {
  max-height: 70px;
}

.alerts-header,
.devices-header {
  position: sticky;
  top: 0;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  z-index: 1001;
  cursor: pointer;
  color: var(--text-primary);
}

.alerts-header {
  display: flex;
  align-items: center;
  padding: 13px;
}

.devices-header {
  display: flex;
  align-items: stretch;
}

.devices-header .tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  transition: background-color 0.1s ease;
}

.devices-header .tab.active {
  background-color: rgba(203, 45, 62, 0.1);
}

.devices-header .tab h3 {
  font-size: 1.25rem;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.devices-container.collapsed .content,
.alerts-container.collapsed .content {
  display: none;
}

/* Remove these as they're no longer needed */
/* 
.collapse-icon {
  margin-left: 10px;
  transition: transform 0.1s ease;
}

.collapsed .collapse-icon {
  transform: rotate(-90deg);
}
*/

@keyframes blink {
  0% {
    opacity: 0.1;
  }

  4% {
    opacity: 1;
    /* Start and hold opacity at 1 for the first 10% of the animation */
  }

  100% {
    opacity: 0.1;
    /* Fade to 0.1 by the end of the animation */
  }
}

.dot {
  animation: blink 5s ease-out infinite;
  /* Apply the defined animation */
}

.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.empty-container img {
  width: 20%;
  /* Sets image width to 20% of its container */
  margin-bottom: 10px;
  /* Adds space between the image and any text below it */
}

.empty-container p {
  margin: 0;
  color: var(--text-secondary);
}

.alerts-controls {
  padding-top: 10px;
}

.alerts-container h3 {
  font-size: 1.25rem;
  color: var(--text-primary);
}

.alerts-container .faCircleCheck,
.faBell {
  font-size: 1.5rem;
}

.modern-button {
  margin: 0;
}

.show-more-select {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}

.show-more-select option {
  padding: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Map */

/* Add this CSS to your stylesheets */
.circle-icon-wrapper {
  width: 64px !important;
  height: 64px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: none !important;
  border: none !important;
  position: relative;
}

.circle-icon {
  width: 32px;
  height: 32px;
  border: 2px solid lightgrey;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transform: translate(0%, -40%);
}

.circle-icon img {
  width: 32px;
  height: 32px;
  filter: invert(90%);
}

.pulsing-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transform: translate(-50%, -95%) scale(1);
  animation: pulseCircle 2s infinite ease-out;
}

.pulsing-circle-recording {
  background-color: rgba(203, 45, 62, 0.4); /* Red */
}

.pulsing-circle-in-shift {
  background-color: rgba(0, 128, 0, 0.4); /* Green */
}

@keyframes pulseCircle {
  0% {
    transform: translate(-50%, -95%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -95%) scale(5);
    opacity: 0;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Status-specific styles */
.circle-icon.tailscale-disconnected {
  background-color: #333333; /* Dark background */
  border-color: #aaa; /* Darker border */
}
.circle-icon.tailscale-disconnected img {
  filter: invert(40%); /* Less inverted to appear darker */
}

.circle-icon.in-shift {
  background-color: rgba(0, 128, 0); /* Green background */
}

.circle-icon.recording {
  background-color: rgba(203, 45, 62); /* Red background */
}

.modal-close {
  position: 'absolute';
  top: '10px';
  right: '10px';
  border: 'none';
  background: 'none';
  font-size: '1.5rem';
  color: 'black';
  padding: '5px 8px';
  /* Adequate padding around the button */
  margin: '0';
  /* Adjust if necessary */
  cursor: 'pointer';
  /* Indicates it's clickable */
}

.no-alerts-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-primary);
}

.no-alerts-message svg {
  color: var(--text-secondary);
}

.no-alerts-message p {
  font-size: 16px;
  margin: 0;
  color: var(--text-secondary);
}

.alert-marker {
  z-index: 1000;
}

.alert-popup {
  min-width: 200px;
}

.offline-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  text-align: center !important;
  padding: 15px 0px 15px 0px;
  background-color: var(--bg-primary);
}

.offline-message p {
  font-size: 16px;
  color: var(--text-secondary);
  text-align: center !important;
  margin: 0px auto 0px auto !important;
}

/* Log out button */

.mobile-user-controls {
  display: none;
}

@media (max-width: 768px) {
  .mobile-user-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 998;
  }

  .mobile-user-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--bg-primary);
    box-shadow:
      0 4px 10px var(--shadow-color-hover),
      0 0 20px var(--shadow-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
  }

  .mobile-user-logo img {
    width: 50%;
    height: 50%;
    object-fit: cover;
  }

  .mobile-user-logo .user-avatar {
    width: 50%;
    height: 50%;
    border-radius: 50%;
  }

  .mobile-user-logo .user-avatar-logo {
    width: 50%;
    height: 50%;
  }
}

/* Ensure popups are above the mobile user controls */
.leaflet-popup {
  z-index: 999 !important;
}

.video-popup {
  z-index: 999 !important;
}

.alert-popup {
  z-index: 999 !important;
}

/* Announcement */

.main-content-area {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.floating-announcement-banner {
  position: absolute;
  top: 12.5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  /* Adjust as needed */
}

.announcement-banner {
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  padding: 7.5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: var(--text-primary);
  text-decoration: none;
  transition: box-shadow 0.1s ease;
  box-shadow: 0 2px 10px var(--shadow-color);
  cursor: pointer;
  z-index: 2001;
  justify-content: space-between;
}

.announcement-count {
  background-color: var(--primary-color);
  color: var(--button-text);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 10px;
}

.announcement-banner:hover {
  box-shadow: 0 4px 15px var(--shadow-color-hover);
}

.arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  margin-left: 10px;
}

.arrow-wrapper:hover {
  background-color: var(--shadow-color);
}

.announcement-arrow {
  color: var(--text-primary);
  margin: 0 !important;
}

.new-label {
  background-color: var(--new-label-bg);
  color: var(--new-label-text);
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
  text-transform: uppercase;
}

.announcement-arrow {
  margin-left: 8px;
}

/* Hide on mobile devices */
@media (max-width: 768px) {
  .announcement-banner {
    display: none;
  }
}

/* Mobile button */

@media (max-width: 768px) {
  .mobile-user-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 998;
  }

  /* ... existing styles ... */

  .mobile-logout-button,
  .mobile-settings-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bg-primary);
    border: none;
    padding: 7.5px 12.5px;
    border-radius: 20px;
    box-shadow: 0 4px 10px var(--shadow-color-hover);
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    opacity: 0;
    transition:
      opacity 0.1s ease-in-out,
      transform 0.1s ease-in-out;
    z-index: 998;
    pointer-events: none;
    text-decoration: none;
    color: var(--text-primary);
    font-family: 'Montserrat', sans-serif;
  }

  .mobile-logout-button {
    top: 50px;
  }

  .mobile-settings-button {
    top: 90px;
  }

  .mobile-logout-button.show,
  .mobile-settings-button.show {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    pointer-events: auto;
  }
}

/* Map style control */
.map-style-control {
  position: absolute;
  bottom: 30px;
  right: 60px; /* Positioned after the zoom control */
  z-index: 1000;
}

.map-style-button {
  width: 40px;
  height: 40px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  padding: 0;
  overflow: hidden;
}

.map-style-button:hover {
  background-color: var(--hover-bg);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.map-style-preview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
}

.satellite-preview,
.monochrome-preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border: 2px solid var(--border-inverse-color);
  box-sizing: border-box;
}

/* Fallback patterns if images fail to load */
.satellite-preview:not([style*='background-image']) {
  background: linear-gradient(135deg, #1a2a32 25%, #244a61 25%, #244a61 50%, #1a2a32 50%, #1a2a32 75%, #244a61 75%);
  background-size: 8px 8px;
}

.monochrome-preview:not([style*='background-image']) {
  background: linear-gradient(135deg, #f5f5f5 25%, #e0e0e0 25%, #e0e0e0 50%, #f5f5f5 50%, #f5f5f5 75%, #e0e0e0 75%);
  background-size: 8px 8px;
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .map-style-control {
    bottom: 80px; /* Move up to avoid overlap with mobile panel */
    left: auto;
    right: 10px;
  }
}

/* Only target specific search input elements in SiteAssignment and DeviceAssignment */
.site-search-input:focus,
.device-assignment-search-input:focus,
.mapboxgl-ctrl-geocoder input:focus,
.address-autocomplete-container input:focus {
  outline: none !important;
  box-shadow: 0 0 0 1px var(--border-color) !important;
  border-color: var(--border-color) !important;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important; /* Removes tap highlight on mobile */
}

/* Remove focus highlight from all button elements */
button:focus {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

/* Only target specific search input elements in SiteAssignment and DeviceAssignment */
.site-search-input:focus,
.device-assignment-search-input:focus,
.mapboxgl-ctrl-geocoder input:focus,
.address-autocomplete-container input:focus {
  outline: none !important;
  box-shadow: 0 0 0 1px var(--border-color) !important;
  border-color: var(--border-color) !important;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important; /* Removes tap highlight on mobile */
}

.search-input-real-time {
  width: 100%;
  padding: 4px;
  padding-left: 10px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  color: #333;
}
