.unsaved-changes-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.unsaved-changes-content {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.unsaved-changes-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.unsaved-changes-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: var(--text-primary);
}

.warning-icon {
  color: #f59e0b;
  font-size: 24px;
}

.unsaved-changes-content p {
  margin: 0 0 20px 0;
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 1.5;
}

.unsaved-changes-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.discard-button,
.go-back-button {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discard-button {
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid rgba(var(--border-color-rgb, 0, 0, 0), 0.15);
}

.discard-button:hover {
  background-color: rgba(var(--border-color-rgb, 0, 0, 0), 0.05);
}

.go-back-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.go-back-button:hover {
  background-color: var(--button-hover-bg);
}
