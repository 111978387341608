.reports-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.reports-container::-webkit-scrollbar {
  display: none;
}

.reports-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  box-shadow: 0 2px 4px var(--shadow-color);
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.reports-header-left {
  display: flex;
  align-items: center;
}

.reports-header-right {
  font-size: 14px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
}

.reports-header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
}

.reports-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  padding: 20px;
  padding-top: 0;
  background-color: var(--bg-primary);
}

/* FAB */

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: fit-content;
  height: 50px;
  padding: 0 20px 0 15px;
  border-radius: 25px;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px var(--shadow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.fab:hover {
  background-color: #a82432;
}

.fab:active {
  transform: scale(0.95);
}

.fab .icon {
  margin-right: 10px;
  color: var(--button-text);
  transform: scale(0.9);
}

.fab .text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transform: translateY(2px);
}

/* Mobile */

/* ... existing styles ... */

@media (max-width: 768px) {
  .mobile-header {
    margin-left: 10px;
  }

  .reports-container {
    left: 0;
  }

  .reports-header {
    padding: 10px;
  }

  .reports-header-left h1 {
    font-size: 20px;
  }

  .back-button {
    color: var(--text-secondary);
  }

  .reports-body {
    padding: 0;
  }

  .report-card-list {
    padding: 10px;
  }

  .report-card {
    padding: 10px;
  }

  .report-info {
    display: flex;
    flex-direction: column;
  }

  .report-name {
    font-size: 16px;
    font-weight: bold;
  }

  .report-time {
    font-size: 14px;
    color: #666;
  }
}

.no-reports-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 20px;
  background-color: var(--bg-primary);
}

.no-reports-content {
  text-align: center;
  max-width: 400px;
  padding: 40px;
  background: var(--bg-secondary);
  border-radius: 12px;
  box-shadow: 0 2px 8px var(--shadow-color);
}

.no-reports-icon {
  font-size: 48px;
  color: #cb2d3e;
  margin-bottom: 20px;
}

.no-reports-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: var(--text-primary);
  margin-bottom: 16px;
}

.no-reports-content p {
  color: var(--text-secondary);
  margin-bottom: 16px;
}

.no-reports-content ol {
  text-align: left;
  color: var(--text-secondary);
  padding-left: 20px;
  line-height: 1.6;
}

.no-reports-content li {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .no-reports-container {
    min-height: 300px;
  }

  .no-reports-content {
    padding: 20px;
  }

  .no-reports-icon {
    font-size: 36px;
  }

  .no-reports-content h2 {
    font-size: 20px;
  }
}

.reports-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  color: var(--text-secondary);
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 768px) {
  .reports-loading-container {
    min-height: 300px;
  }
}

/* Update tooltip styles */
.configuration-wrapper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  box-shadow: 0 2px 6px var(--shadow-color) !important;
}

.configuration-wrapper .MuiTooltip-arrow {
  color: var(--bg-secondary) !important;
}
