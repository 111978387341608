.video-container,
.video-placeholder {
  position: relative;
  width: 100%;
  padding-top: 0;
  background-color: black;
}

/* Ensure controls are visible */
.plyr__controls {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 5;
}

/* Adjust control colors for better visibility */
.plyr--full-ui input[type='range'] {
  color: #cb2d3e;
}

.plyr__control--overlaid {
  background: rgba(203, 45, 62, 0.8);
}

.plyr--video .plyr__control:hover,
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: #cb2d3e;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(203, 45, 62, 0.5);
}

.plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #cb2d3e;
}

.plyr__captions {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.plyr__video-wrapper {
  pointer-events: none;
}

.plyr__controls {
  pointer-events: auto;
}

.plyr__progress {
  z-index: 6;
}

/* Marker styles */
.plyr__progress__marker {
  width: 4px;
  height: 20px;
  background-color: hsl(17, 10%, 40%);
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translate(-2px, -50%);
}

/* .plyr__progress__marker::before {
  content: attr(data-plyr-marker-title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.plyr__progress__marker:hover::before {
  opacity: 1;
} */

.plyr__progress input[type='range'] {
  z-index: 6;
}

/* Style the background (unfilled part) of the progress bar */
/* .plyr__progress__buffer {
  color: #434342 !important;
  background-color: #434342 !important;
} */

/* Mobile-specific styles */
@media (max-width: 768px) {
  .plyr__control[data-plyr='pip'] {
    display: none !important;
  }

  .plyr__volume {
    max-width: none !important;
  }

  .plyr__controls button {
    padding: 5px !important;
  }

  .plyr__progress__container {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .plyr__time {
    font-size: 10px !important;
  }

  .plyr__progress__marker {
    width: 8px;
    height: 20px;
  }
}
