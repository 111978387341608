.metrics-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px var(--shadow-color);
  min-width: 250px;
  flex: 1;
  transition: all 0.3s ease;
}

.metrics-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.metrics-card-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 15px;
}

.metrics-card-header h3 {
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.metrics-card-body {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.metrics-value {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
}

.metrics-trend {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
}

.metrics-trend.positive {
  color: var(--success-text, #2ecc71);
  background-color: var(--success-bg, rgba(46, 204, 113, 0.15));
}

.metrics-trend.negative {
  color: var(--error-text, #e74c3c);
  background-color: var(--error-bg, rgba(231, 76, 60, 0.15));
}

.metrics-card-footer {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.metrics-card.skeleton {
  pointer-events: none;
}

.skeleton-title,
.skeleton-value,
.skeleton-description {
  background-color: var(--bg-secondary);
  border-radius: 4px;
  animation: shimmer 0.8s infinite linear;
}

.skeleton-title {
  height: 16px;
  width: 60%;
}

.skeleton-value {
  height: 32px;
  width: 40%;
  margin: 10px 0;
}

.skeleton-description {
  height: 14px;
  width: 80%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton-title,
.skeleton-value,
.skeleton-description {
  background-image: linear-gradient(
    to right,
    var(--skeleton-base) 0%,
    var(--skeleton-highlight) 20%,
    var(--skeleton-base) 40%,
    var(--skeleton-base) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
}

.metrics-icon {
  color: var(--text-secondary);
  font-size: 1.2rem;
  margin-right: 10px;
  /* margin-left: 8px; */
}
