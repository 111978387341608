.event-info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  margin-left: 5px;
  width: 100%;
  height: 600px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  /* padding-right: 10px; */
}

.event-info-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.event-info-map {
  width: 100%;
  height: 300px;
  flex-shrink: 0;
}

/* Create report button */

.create-report-link {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  width: 40px;
  height: 40px;
  background-color: var(--bg-secondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px var(--shadow-color);
  backdrop-filter: blur(4px);
  transition: all 0.2s ease;
}

.create-report-link:hover {
  background-color: var(--hover-bg);
  box-shadow: 0 4px 8px var(--shadow-color);
  /* transform: translateY(-2px); */
}

.create-report-icon {
  color: var(--text-secondary);
  font-size: 1.2em;
  transition: color 0.1s ease;
}

.create-report-link:hover .create-report-icon {
  color: var(--text-primary);
}
