.event-detail-image-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 600px;
  min-height: 600px;
}

.event-detail-image-container {
  width: 100%;
  height: 100%;
}

.image-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-placeholder {
  width: auto;
  background-color: var(--bg-secondary);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px var(--shadow-color);
  position: relative;
}

.placeholder-icon {
  font-size: 48px !important;
  margin-bottom: 16px;
  opacity: 0.5;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 2;
  color: white;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateY(-50%) scale(1.1);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: translateY(-50%) scale(1);
}

.nav-button.prev {
  left: 16px;
}

.nav-button.next {
  right: 16px;
}

.nav-button svg {
  color: white;
  font-size: 24px;
}

.image-counter {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  z-index: 2;
  backdrop-filter: blur(4px);
}

@media (max-width: 768px) {
  .event-detail-image-view-container.mobile {
    width: 100%;
  }

  .event-detail-image-container {
    width: 100%;
    padding-top: 100%;
    /* Creates a square aspect ratio */
    position: relative;
    overflow: hidden;
  }

  .event-detail-image-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-view-container {
    width: 100%;
    height: 100%;
  }

  .event-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .nav-button {
    width: 32px;
    height: 32px;
  }

  .nav-button svg {
    font-size: 20px;
  }

  .image-counter {
    font-size: 12px;
    padding: 3px 10px;
  }
}

/* Dark mode enhancements */
@media (prefers-color-scheme: dark) {
  .event-detail-image-view-container {
    background-color: #2a2a2a;
  }

  .image-placeholder {
    color: #999;
  }
}
