.report-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.report-card.unreviewed {
  border-left: 4px solid var(--button-bg);
  background-color: var(--bg-secondary);
}

.report-card-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  min-height: 44px;
}

.report-card-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.report-assigned-to {
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1.2;
}

.report-timestamp {
  display: flex;
  gap: 8px;
  color: var(--text-secondary);
  font-size: 0.9em;
  line-height: 1.2;
}

.report-author {
  color: var(--text-secondary);
  font-size: 0.9em;
  line-height: 1.2;
}

.report-card-right {
  display: flex;
  align-items: center;
}

.delete-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  transition: color 0.2s ease;
}

.delete-button:hover {
  color: var(--button-bg);
}

.unreviewed-badge {
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  height: fit-content;
}

/* Hover states */
.report-card:hover {
  background-color: var(--hover-bg);
}

.report-card.unreviewed:hover {
  background-color: var(--hover-bg);
}

/* Mobile styles */
@media (max-width: 768px) {
  .report-card {
    padding: 12px;
  }

  .report-timestamp {
    font-size: 0.8em;
  }

  .unreviewed-badge {
    font-size: 11px;
    padding: 2px 6px;
  }
}
