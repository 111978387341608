/* Leaderboard.css */
.leaderboard {
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Add these new styles for the loading state */
.leaderboard-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2em;
  color: #666;
}

.leaderboard-loading svg {
  font-size: 2em;
  margin-bottom: 10px;
}

/* Style for no data message */
.leaderboard-no-data {
  text-align: center;
  font-size: 1.1em;
  color: #666;
  margin-top: 20px;
}

/* Existing styles... */
.date-range-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #333;
}

.arrow-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.arrow-button-placeholder {
  visibility: hidden;
}

.date-range {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.days-left {
  text-align: center;
  font-size: 0.9em;
  color: #666;
  margin-bottom: 20px;
}

.rankings {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rank-row {
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.rank-row:hover {
  background-color: #e0e0e0;
}

.rank-row.expanded .rank-row-top {
  background-color: #f0f0f0;
}

.rank-row-top {
  display: flex;
  align-items: center;
  padding: 10px;
}

.rank-number {
  font-weight: bold;
  margin-right: 10px;
  min-width: 30px;
}

.rank-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.guard-name-container {
  display: flex;
  align-items: center;
}

.guard-name {
  margin-right: 5px;
  font-size: 1.1em;
}

.crown-icon {
  color: var(--crown-color);
  font-size: 1.1em;
  margin-left: 5px;
  animation:
    float 1s ease-in-out infinite,
    sparkle 2s linear infinite;
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.7);
  background-color: var(--bg-primary);
  border-radius: 50%;
  padding: 2px;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

@keyframes sparkle {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

.score-container {
  display: flex;
  align-items: center;
}

.recent-score {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 8px;
}

.recent-score-value {
  font-size: 0.8em;
  font-weight: bold;
  color: var(--success-text);
}

.recent-score-label {
  font-size: 0.6em;
  color: var(--text-secondary);
}

.total-score {
  font-weight: bold;
  font-size: 1.2em;
  color: var(--text-primary);
}

.expanded-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  opacity: 0;
}

.rank-row.expanded .expanded-content {
  max-height: 300px;
  opacity: 1;
}

.score-details-container {
  display: flex;
  justify-content: space-around;
  background-color: var(--bg-secondary);
  width: 100% !important;
  padding: 20px 0px 10px 0px;
}

.score-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.score-detail svg {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: var(--text-primary);
}

.score-value {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 0.9em;
  color: var(--text-primary);
}

.score-description {
  font-size: 0.6em;
  color: var(--text-secondary);
  max-width: 80px;
  line-height: 1.1;
}

.todays-gains-box {
  background-color: var(--bg-secondary);
  padding: 10px 0px 12.5px 0px;
  width: 100%;
}

.fractions-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.score-fraction {
  font-size: 0.7em;
  color: #333;
  padding: 3px 6px;
}

.todays-gains {
  text-align: center;
  font-size: 0.7em;
  color: #666;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@keyframes calendarPulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes lightningShake {
  0%,
  90%,
  100% {
    transform: rotate(0deg);
  }
  92% {
    transform: rotate(30deg);
  }
  94% {
    transform: rotate(-30deg);
  }
  96% {
    transform: rotate(15deg);
  }
  98% {
    transform: rotate(-15deg);
  }
}

@keyframes batteryMove {
  0% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-3px);
  }
}

.score-detail svg {
  font-size: 1.5em;
  margin-bottom: 5px;
}

.score-detail:nth-child(1) svg {
  animation: calendarPulse 2s infinite;
}

.score-detail:nth-child(2) svg {
  animation: lightningShake 3s infinite;
}

.score-detail:nth-child(3) svg {
  animation: batteryMove 3s ease-in-out infinite;
}
