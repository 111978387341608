/* VideosList.css */
.videos-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.data-grid-container {
  flex-grow: 1;
  overflow: auto;
  height: calc(100% - 52px);
}

.MuiDataGrid-root {
  height: 100% !important;
  border: none !important;
}

.MuiDataGrid-columnHeaders {
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: #fff !important;
  border-bottom: none !important;
}

.MuiDataGrid-virtualScroller {
  background-color: #eeeeee;
}

.MuiDataGrid-footerContainer {
  position: sticky !important;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
}

.MuiDataGrid-cell {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 8px !important; /* Increase top padding */
  padding-bottom: 8px !important; /* Increase bottom padding */
}

.MuiDataGrid-row {
  background-color: #eeeeee;
  min-height: unset !important; /* Allow the row height to adjust based on cell content */
}

.custom-header {
  height: 30px;
  font-size: 0.875rem;
  line-height: 30px;
  font-weight: bold;
}

.owner-cell {
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
