/* src/styles/AnnouncementPopup.css */
.announcement-popup-overlay,
.announcement-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1900;
}

.announcement-popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  position: relative;
}

.announcement-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #666;
}

.announcement-popup-icon {
  text-align: center;
  font-size: 3em;
  color: #cb2d3e;
  margin-bottom: 20px;
}

.announcement-popup-content h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.announcement-popup-text {
  font-size: 1em;
  color: #666;
  line-height: 1.5;
}

.announcement-popup-text ul {
  padding-left: 20px;
  margin-top: 10px;
}

/* Notification center */

.notification-center {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  height: 400px; /* Fixed height */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 2001;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.notification-center-title {
  text-align: center;
  padding: 12px 0;
  margin: 0;
  font-size: 1em;
  border-bottom: 1px solid #eee;
  font-weight: normal;
  color: #666;
}

.notification-center-content {
  overflow-y: auto;
  padding: 10px 0;
  flex-grow: 1;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 20px 20px; /* Increased vertical padding from 15px to 20px */
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.notification-item-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px; /* Increased from 15px to 20px for more horizontal spacing */
  min-width: 50px; /* Increased from 40px to 50px to accommodate padding */
  height: 100%;
  padding: 0 5px; /* Added horizontal padding */
}

.notification-icon {
  font-size: 1.2em;
  color: #cb2d3e;
  margin-bottom: 5px;
}

.notification-date {
  font-size: 0.75em;
  color: #888;
  white-space: nowrap;
  text-align: center; /* Ensure date is centered if it wraps */
}

.notification-content {
  flex-grow: 1;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification-text {
  font-size: 1em;
  margin-bottom: 5px; /* Increased from 3px to 5px for more spacing */
}

.notification-description {
  font-size: 0.85em;
  color: #666;
  line-height: 1.4;
}

.notification-arrow {
  color: #cb2d3e;
  font-size: 1em;
  align-self: center;
}

.notification-item.active .notification-text,
.notification-item.active .notification-date {
  font-weight: bold;
}

.notification-item.active .notification-icon {
  color: #cb2d3e;
}
