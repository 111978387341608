.map-view-main-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.map-view-main-container .leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #333333;
}
