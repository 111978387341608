.settings-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.settings-container::-webkit-scrollbar {
  display: none;
}

.settings-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  box-shadow: 0 2px 4px var(--shadow-color);
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.settings-header-left {
  display: flex;
  align-items: center;
}

.settings-header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.settings-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  /* padding: 20px; */
  background-color: var(--bg-primary);
}

.settings-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-radius: 8px;
}

/* Dark mode specific overrides */
[data-theme='dark'] .settings-container {
  background-color: var(--bg-primary);
}

[data-theme='dark'] .settings-header {
  background-color: var(--bg-primary);
  border-color: var(--border-color);
}

[data-theme='dark'] .settings-body {
  background-color: var(--bg-primary);
}

[data-theme='dark'] .settings-content {
  background-color: var(--bg-primary);
}

/* Scrollbar styling */
.settings-body::-webkit-scrollbar {
  width: 8px;
}

.settings-body::-webkit-scrollbar-track {
  background: var(--bg-primary);
}

.settings-body::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
  border-radius: 4px;
}

.settings-body::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
}

.device-dialog-heading {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: var(--text-primary) !important;
}

.device-dialog-heading .MuiTypography-h6 {
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--text-primary) !important;
}

.device-dialog-subheading {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: var(--text-primary) !important;
}

.device-dialog-subheading .MuiTypography-subtitle1 {
  font-size: 0.9rem !important;
  margin: 0 !important;
  color: var(--text-primary) !important;
}

[data-theme='dark'] .MuiSvgIcon-root[data-testid='InfoIcon'] {
  color: var(--text-secondary);
  opacity: 0.9;
}
/* Ensure consistent sizing for all device ID variants */
[class*='device-id'] {
  height: 24px !important;
  line-height: 1 !important;
  display: inline-flex !important;
  align-items: center !important;
  padding: 4px 12px !important;
  border-radius: 16px !important;
  font-size: 0.875rem !important;
  white-space: nowrap !important;
}

div[style*='background-color: rgb(240, 240, 240)'] {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--border-color) !important;
}

/* Only remove focus styles from search inputs in Settings */
.settings-container input[type='text'][placeholder*='search' i]:focus,
.settings-container input[type='text'][placeholder*='Search' i]:focus,
.settings-container input[type='search']:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--border-color);
  border-color: var(--border-color);
}
