.map-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #333333;
  border-radius: 4px;
  overflow: hidden;
}

.map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #333333;
}

.map {
  width: 100%;
  height: 100%;
}

.map-view-container .leaflet-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #333333;
}

.map-overlay {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  pointer-events: none;
}

.map-controls-top {
  display: flex;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  pointer-events: auto;
}

.map-button {
  background-color: white;
  color: #333;
  border: none;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.map-button:not(.circular) {
  border-radius: 20px; /* Fully rounded ends for non-circular buttons */
}

.map-button.circular {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.map-button:hover {
  background-color: #f0f0f0;
}

.map-button.active {
  background-color: #e74d3ce4;
  color: white;
}

.forecast-header {
  text-align: center;
  margin-bottom: 20px;
}

.forecast-header h2 {
  margin: 0;
  font-size: 24px;
}

.forecast-header p {
  margin: 5px 0 0;
  font-size: 16px;
  color: #bdc3c7;
}

.map-view-container.large {
  height: 600px;
  width: 600px;
}

.map-view-container.small {
  height: 300px;
}

.map-controls {
  display: flex;
  /* color: #e74d3ce4; */
  flex-direction: column;
  align-items: center;
  width: 95%; /* Reduced from 100% */
  max-width: 700px; /* Added max-width for larger screens */
  padding: 15px;
  margin: 0 auto; /* Center the controls */
}

.map-controls button {
  background-color: #e74d3ce4;
  border: none;
  color: white;
  /* padding: 10px 20px; */
  /* border-radius: 5px; */
  cursor: pointer;
  font-size: 15px;
  /* position: relative;
  z-index: 100; */
  /* margin-bottom: 10px; */
}

.map-controls input[type='range'] {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background: #555;
  height: 5px;
  border-radius: 2.5px;
}

.event-marker-callout {
  position: absolute;
  bottom: 135%;
  transform: translateX(-50%);
  background-color: #e74d3ce4;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 5;
}

.event-marker-callout::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #e74d3ce4 transparent transparent transparent;
}

.map-controls-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  max-width: 700px;
  padding: 30px 10px 20px 10px;
  margin: 0 auto;
}

.slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.slider-container input[type='range'] {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background: #555;
  height: 5px;
  border-radius: 2.5px;
}

.slider-container input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #e74d3ce4;
  cursor: pointer;
  border-radius: 50%;
}

.slider-container input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #e74d3ce4;
  cursor: pointer;
  border-radius: 50%;
}

.slider-container input[type='range']::-moz-range-progress {
  background-color: #e74d3ce4;
}

.event-marker-callout {
  position: absolute;
  transform: translateX(-50%) translateY(7.5px);
  background-color: #e74d3ce4;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 5;
}

.time-markers {
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  top: 0px;
}

.time-markers span {
  position: absolute;
  font-size: 10px;
  color: #bdc3c7;
  transform: translateX(-50%);
}

.map-controls .controls-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this line */
  margin-bottom: 10px;
}

.map-controls .controls-left {
  display: flex;
  align-items: center;
}

.map-controls .circular-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  border: none;
}

.map-controls .circular-button:hover {
  background-color: rgba(128, 128, 128, 0.9);
}

.map-controls .circular-button svg {
  display: block; /* Ensures no extra space around the SVG */
}

.map-controls .event-info {
  text-align: left;
}

.map-controls .event-info p {
  margin: 0;
  font-size: 14px;
}

.map-controls .event-info .time-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-controls .event-info .time-display .period {
  font-size: 0.8em;
  text-transform: uppercase;
}

.event-time-marker {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background-color: #e74d3ce4;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  pointer-events: none; /* Make the dot not clickable */
}

.event-marker-callout:hover {
  cursor: pointer;
}

.map-controls input[type='range'] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  background: #555;
  height: 5px;
  border-radius: 2.5px;
  z-index: 3;
}

/* Add these new styles */
.toggle-route-button {
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  min-width: 102px;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-route-button:hover {
  background-color: rgba(128, 128, 128, 0.9);
}

.toggle-route-button.active {
  background-color: #e74d3ce4;
}

.toggle-play-button {
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.toggle-play-button:hover {
  background-color: rgba(128, 128, 128, 0.9);
}

.leaflet-interactive {
  transition: opacity 0.1s ease-in-out;
}

.time-display-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333;
  color: #bdc3c7;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.time-display-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  padding: 5px 10px;
  position: absolute;
}

.analog-clock {
  display: flex;
  align-items: center;
}

.digital-time {
  font-size: 14px;
}

.route-dropdown {
  position: relative;
  display: inline-block;
}

.map-button.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px; /* Increase right padding to make room for the chevron */
  position: relative;
}

.dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  transition: transform 0.3s ease;
}

.route-dropdown.open .dropdown-arrow {
  transform: translateY(-50%) rotate(180deg);
}

/* Update the existing .dropdown-content styles */
.dropdown-content {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  min-width: 120px;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(-10px);
  transition:
    opacity 0.2s ease,
    transform 0.2s ease,
    visibility 0.2s;
}

.route-dropdown.open .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-content button {
  color: #333;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

.dropdown-content button:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}
