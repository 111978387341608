.real-time-map-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.real-time-map-container {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.left-real-time-map-panel {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 25%;
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--bg-primary);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.left-real-time-map-panel.hidden {
  transform: translateX(-100%);
}

.right-real-time-map-panel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.real-time-map-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  box-shadow: 0 2px 4px var(--shadow-color);
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.real-time-map-header-left {
  display: flex;
  align-items: center;
}

.real-time-map-header h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
}

.left-real-time-map-panel-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  height: fit-content;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.left-real-time-map-panel-content {
  height: 100%;
  overflow-y: auto;
}

.left-real-time-map-panel-selector-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.left-real-time-map-panel-selector-controls {
  display: inline-flex;
  height: 40px;
  width: 100%;
  align-items: center;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  gap: 4px;
}

.left-real-time-map-panel-toggle-btn {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.left-real-time-map-panel-toggle-btn:hover {
  background: var(--hover-bg);
}

.left-real-time-map-panel-toggle-btn.active {
  background: var(--bg-primary);
  color: var(--text-primary);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Alert Panel Styles */
.left-panel-alerts-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  border-radius: 8px;
}

.left-panel-alerts-header {
  display: flex;
  align-items: center;
  padding: 13px;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.left-panel-alerts-content {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.left-panel-alerts-content .card {
  margin: 10px;
}

.left-panel-no-alerts-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-primary);
  height: 100%;
}

.left-panel-no-alerts-message svg {
  color: var(--text-secondary);
}

.left-panel-no-alerts-message p {
  font-size: 16px;
  margin: 0;
  color: var(--text-secondary);
}

.left-panel-alerts-error {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  color: #cb2d3e;
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
}

.left-panel-alerts-error .error-icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #cb2d3e;
}

.left-panel-alerts-error p {
  margin: 0 0 15px 0;
  color: #cb2d3e;
  line-height: 1.4;
}

.left-panel-alerts-reload-button {
  color: #666666;
  padding: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-panel-alerts-reload-button:hover {
  color: #333333;
}

.left-panel-alerts-reload-button .fa-sync {
  font-size: 1rem;
}

.left-panel-alerts-loader-text {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  padding: 20px 0;
  font-weight: 500;
}

.drawer-toggle-btn:hover {
  background-color: var(--hover-bg);
}

.drawer-open-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 400px;
  height: 400px;
  border: none;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  transition: background-color 0.2s ease;
}

.drawer-open-btn:hover {
  background-color: var(--hover-bg);
}

/* Drawer toggle button */
.drawer-toggle-button {
  position: absolute;
  left: min(25%, 450px);
  top: 20px;
  transform: none;
  width: 24px;
  height: 50px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: none;
  border-radius: 0 6px 6px 0;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  transition:
    left 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out,
    background-color 0.2s ease;
  padding: 0;
  outline: none;
  opacity: 1;
  visibility: visible;
}

.drawer-toggle-button:hover {
  background-color: var(--hover-bg);
}

.drawer-toggle-button:focus {
  outline: none;
}

.drawer-toggle-button.hidden-toggle,
.right-drawer-toggle-button.hidden-toggle {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  pointer-events: none;
}

.drawer-toggle-button.edit-mode {
  background-color: #f8d7da; /* Light red to indicate edit mode */
  color: #721c24; /* Darker red for the icon */
  pointer-events: none; /* Make it unclickable in edit mode */
  opacity: 0.5; /* Make it look more disabled */
  box-shadow: none; /* Remove shadow to indicate it's inactive */
  filter: grayscale(30%); /* Add grayscale effect */
}

.drawer-toggle-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  box-shadow: none;
}

.drawer-toggle-button.edit-mode:hover {
  background-color: #f8d7da; /* Stay the same color on hover when disabled */
}

/* Adjust position when drawer is closed */
.left-real-time-map-panel.hidden + .drawer-toggle-button,
.left-real-time-map-panel.hidden ~ .drawer-toggle-button {
  left: 0;
}

/* Right Drawer Styles */
.right-alerts-drawer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: auto;
  width: 25%;
  max-width: 450px;
  max-height: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--bg-primary);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  border-radius: 0 0 0 8px;
}

.right-alerts-drawer.hidden {
  transform: translateX(100%);
}

.right-alerts-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.right-alerts-drawer-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.right-alerts-drawer-content {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  padding: 10px;
  max-height: calc(85vh - 51px);
}

/* Right drawer toggle button */
.right-drawer-toggle-button {
  position: absolute;
  right: 25%;
  right: min(25%, 450px);
  top: 20px;
  width: 24px;
  height: 50px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: none;
  border-radius: 6px 0 0 6px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  transition:
    right 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out,
    background-color 0.2s ease;
  padding: 0;
  outline: none;
  opacity: 1;
  visibility: visible;
}

.right-drawer-toggle-button:hover {
  background-color: var(--hover-bg);
}

.right-drawer-toggle-button:focus {
  outline: none;
}

/* Adjust position when drawer is closed */
.right-alerts-drawer.hidden + .right-drawer-toggle-button,
.right-alerts-drawer.hidden ~ .right-drawer-toggle-button {
  right: 0;
}
