.device-management {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.device-list {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px;
  background-color: var(--bg-secondary);
}

.device-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  color: var(--text-primary);
}

.device-item:hover {
  background-color: var(--hover-bg);
}

.device-item input {
  margin-right: 10px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.user-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  color: var(--text-primary);
}

.user-input label {
  margin-right: 10px;
  min-width: 70px;
  color: var(--text-primary);
}

.user-input input {
  flex: 1;
  min-width: 200px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-right: 10px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.valid-user-id {
  margin-left: 10px;
  color: var(--success-color);
  font-weight: bold;
}

.org-name {
  margin-left: 5px;
  font-weight: bold;
  color: var(--text-primary);
}

.device-management-btn {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s;
}

.device-management-btn:hover {
  background-color: var(--button-hover-bg);
}

button:disabled {
  background-color: var(--bg-disabled);
  cursor: not-allowed;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  background: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  border: 1px solid var(--border-color) !important;
}

.ReactModal__Content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.ReactModal__Content button {
  margin-right: 10px;
}

.assigned-devices-section {
  margin-top: 20px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-secondary);
}

.collapsible-header {
  background-color: var(--bg-secondary);
  padding: 10px;
  cursor: pointer;
  user-select: none;
  color: var(--text-primary);
}

.collapsible-header:hover {
  background-color: var(--hover-bg);
}

.assigned-devices-container {
  padding: 10px;
  background-color: var(--bg-secondary);
}

.scrollable {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px;
  background-color: var(--bg-secondary);
}

/* Scrollbar styling */
.scrollable::-webkit-scrollbar {
  width: 8px;
}

.scrollable::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.scrollable::-webkit-scrollbar-thumb {
  background: var(--text-secondary);
  border-radius: 4px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
}

@media screen and (max-height: 600px) {
  .device-management {
    height: auto;
    min-height: 100vh;
  }
}

.device-management-shift-controls {
  margin: 20px 0;
  padding: 15px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.device-management-shift-controls button {
  margin-right: 15px;
}

.device-management-shift-controls button:last-child {
  margin-right: 0;
}

.device-management-confirmation-details {
  margin: 20px 0;
  color: var(--text-primary);
}

.device-management-confirmation-details p {
  margin: 10px 0;
  color: var(--text-primary);
}

.device-management-modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.device-management-confirmation-details .device-list {
  max-height: 200px;
  margin-top: 10px;
  background-color: var(--bg-secondary);
}

.device-management-confirmation-details .device-item {
  padding: 5px 10px;
  margin: 5px 0;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  color: var(--text-primary);
}
