.coaching-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.coaching-container::-webkit-scrollbar {
  display: none;
}

.coaching-header {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.coaching-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
  .coaching-title {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.coaching-title h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.coaching-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.summary-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 0.875rem;
  border-radius: 0.5rem;
  transition: transform 0.2s ease;
}

.summary-stat:hover {
  transform: translateY(-2px);
}

.summary-stat.positive {
  background: rgba(76, 175, 80, 0.15);
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.summary-stat.negative {
  background: rgba(244, 67, 54, 0.15);
  color: #f44336;
  border: 1px solid rgba(244, 67, 54, 0.2);
}

.summary-stat svg {
  font-size: 1.125rem;
}

.incident-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.875rem;
  color: var(--text-primary);
  align-items: center;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.info-icon {
  opacity: 0.8;
  font-size: 1rem;
  color: var(--text-primary);
}

.coaching-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 20px 24px;
}

@media (min-width: 1024px) {
  .coaching-grid {
    grid-template-columns: 7fr 5fr;
  }
}

.coaching-content-left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.video-card {
  background: var(--card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 16/9;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

.timeline-wrapper {
  background: var(--card-bg);
  border-radius: 0.75rem;
  padding: 0.75rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
  margin: 1rem 0;
}

.timeline-title {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.timeline-container {
  position: relative;
  width: 100%;
}

.timeline-axis {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  opacity: 0.7;
}

/* Simple track with minimal height */
.timeline-track {
  position: relative;
  height: 4px;
  background-color: var(--bg-secondary);
  border-radius: 2px;
  margin-bottom: 1.5rem;
  margin-top: 1.25rem;
}

/* Marker dots instead of bars */
.timeline-marker-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #607d8b;
  top: -3px; /* Center it on the track */
  transform: translateX(-5px); /* Center horizontally */
  cursor: pointer;
  z-index: 3;
  transition: transform 0.2s ease;
}

.timeline-marker-dot:hover,
.timeline-marker-dot.selected {
  transform: translateX(-5px) scale(1.3);
  z-index: 4;
}

/* Marker colors */
.timeline-marker-dot.positive {
  background-color: #4caf50;
}

.timeline-marker-dot.negative {
  background-color: #f44336;
}

/* Time label above dot */
.marker-time {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.6875rem;
  white-space: nowrap;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  padding: 0.125rem 0.25rem;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.timeline-marker-dot:hover .marker-time,
.timeline-marker-dot.selected .marker-time {
  opacity: 1;
}

/* Simple legend */
.timeline-legend {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
}

.legend-color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.legend-color.positive {
  background-color: #4caf50;
}

.legend-color.negative {
  background-color: #f44336;
}

.timeline-events {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: var(--bg-secondary);
  border-radius: 0.25rem;
  overflow: visible;
  margin-bottom: 2rem;
}

.timeline-event:hover {
  transform: translateY(-2px);
  z-index: 10;
}

.timeline-event:hover::after {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.timeline-event::after {
  content: attr(data-category);
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--card-bg);
  color: var(--text-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  white-space: nowrap;
  box-shadow: 0 2px 4px var(--shadow-color);
  opacity: 0;
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
  pointer-events: none;
  border: 1px solid var(--border-color);
  z-index: 100;
}

.timeline-event.selected {
  z-index: 10;
  box-shadow:
    0 0 0 1px white,
    0 0 0 2px var(--button-bg);
}

.timeline-event.selected::after {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
  font-weight: 600;
}

.outcome-event {
  width: 4px !important;
  background-color: #673ab7 !important;
  z-index: 5;
}

.outcome-event.positive {
  background-color: #4caf50 !important;
}

.outcome-event.negative {
  background-color: #f44336 !important;
}

.outcome-label {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #673ab7;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  white-space: nowrap;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  z-index: 100;
}

/* New styles for the improvement indicator */
.improvement-indicator {
  display: none;
}

.timeline-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  background: var(--bg-secondary);
  transition: transform 0.2s ease;
}

.legend-item:hover {
  transform: translateY(-1px);
}

.legend-icon {
  font-size: 0.875rem;
}

.legend-icon.negative {
  color: #f44336;
}

.legend-divider {
  width: 1px;
  height: 1.25rem;
  background-color: var(--border-color);
  margin: 0 0.375rem;
}

/* Timeline tooltip */
.timeline-tooltip {
  position: absolute;
  background-color: var(--card-bg);
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  box-shadow: 0 4px 8px var(--shadow-color);
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  transform: translateY(5px);
  z-index: 100;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.timeline-tooltip.visible {
  opacity: 1;
  transform: translateY(0);
}

.tooltip-title {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.tooltip-status {
  font-size: 0.625rem;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  display: inline-block;
  font-weight: 500;
}

.tooltip-status.positive {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.tooltip-status.negative {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

/* Update event card to match timeline selection */
.event-card.selected {
  transform: translateY(-2px);
  border-color: var(--button-bg);
  box-shadow:
    0 0 0 2px var(--button-bg),
    0 4px 12px var(--shadow-color);
  position: relative;
  animation: highlight-pulse 1s ease;
}

@keyframes highlight-pulse {
  0% {
    box-shadow:
      0 0 0 2px var(--button-bg),
      0 0 0 4px rgba(203, 45, 62, 0.4);
  }
  70% {
    box-shadow:
      0 0 0 2px var(--button-bg),
      0 0 0 8px rgba(203, 45, 62, 0);
  }
  100% {
    box-shadow:
      0 0 0 2px var(--button-bg),
      0 4px 12px var(--shadow-color);
  }
}

.context-card {
  background: var(--card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.context-header {
  padding: 1rem 1.25rem 0.5rem;
}

.context-header h2 {
  color: var(--text-primary);
  font-size: 1.125rem;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.context-icon {
  color: #2196f3;
}

.context-content {
  padding: 0 1.25rem 1.25rem;
}

.context-content p {
  line-height: 1.6;
  color: var(--text-primary);
  margin: 0;
}

.tabs {
  background: var(--card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.tabs-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid var(--border-color);
}

.tab {
  padding: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: var(--text-primary);
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.tab:hover {
  background-color: var(--bg-secondary);
}

.tab.active {
  color: var(--button-bg);
  background-color: var(--bg-primary);
  font-weight: 600;
  box-shadow: inset 0 -2px 0 var(--button-bg);
}

.tab-icon {
  font-size: 1rem;
}

.tabs-content {
  padding: 0;
}

.tab-pane {
  padding: 0;
}

.scroll-area {
  height: calc(100vh - 20rem);
  overflow-y: auto;
  padding: 1rem;
}

.events-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.event-card {
  background: var(--card-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-left-width: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.event-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.event-card.selected {
  box-shadow:
    0 0 0 2px var(--button-bg),
    0 4px 12px var(--shadow-color);
}

.event-card.border-red {
  border-left-color: #f44336;
}

.event-card.border-green {
  border-left-color: #4caf50;
}

.event-card.border-neutral {
  border-left-color: #607d8b;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.event-title {
  flex: 1;
  min-width: 0;
}

.event-title h3 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0.25rem 0;
}

.event-time {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  opacity: 0.8;
}

.event-time svg {
  font-size: 0.875rem;
  opacity: 0.7;
}

.event-duration {
  font-style: italic;
}

.event-description {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  opacity: 0.9;
  line-height: 1.5;
}

.event-card h4 {
  font-size: 0.875rem;
  margin: 0 0 0.5rem 0;
  font-weight: 500;
  color: var(--text-primary);
}

.outcome-card {
  background: var(--card-bg);
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-left-width: 4px;
  border-left-color: #673ab7;
}

.outcome-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.outcome-card.needs-improvement {
  border-left-color: #f44336;
}

.outcome-header {
  padding: 1rem 1.25rem 0.5rem;
}

.outcome-header h2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  color: var(--text-primary);
}

.outcome-icon {
  color: #673ab7;
}

.outcome-icon.negative {
  color: #f44336;
}

.outcome-content {
  padding: 0 1.25rem 1.25rem;
}

.outcome-content p {
  color: var(--text-primary);
  opacity: 0.9;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.timestamp-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  opacity: 0.8;
  margin-bottom: 1rem;
}

.outcome-content h4 {
  font-size: 0.875rem;
  margin: 0 0 0.5rem 0;
  font-weight: 500;
  color: var(--text-primary);
}

.coachable-moments-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.no-moments {
  font-size: 0.875rem;
  color: var(--text-primary);
  opacity: 0.8;
  padding: 0.5rem 0;
}

.coachable-moment {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: var(--bg-secondary);
  border-left: 3px solid transparent;
}

.coachable-moment.positive {
  border-left-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.08);
}

.coachable-moment.negative {
  border-left-color: #f44336;
  background-color: rgba(244, 67, 54, 0.08);
}

.moment-icon {
  flex-shrink: 0;
  font-size: 1rem;
  margin-top: 0.125rem;
}

.moment-icon.positive {
  color: #4caf50;
}

.moment-icon.negative {
  color: #f44336;
}

.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.play-button:hover {
  transform: scale(1.1);
  background-color: var(--button-hover-bg);
}

.play-button svg {
  font-size: 1rem;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
  }
  70% {
    box-shadow: 0 0 0 4px rgba(244, 67, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}

.scroll-area::-webkit-scrollbar {
  width: 8px;
}

.scroll-area::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}

.scroll-area::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
}

.scroll-area::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.event-card {
  animation: slideIn 0.3s ease forwards;
}

@media (max-width: 1024px) {
  .coaching-grid {
    padding: 16px;
  }

  .scroll-area {
    height: auto;
    max-height: 40vh;
  }

  .coaching-title h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .coaching-title h1 {
    font-size: 1.25rem;
  }

  .summary-stat {
    padding: 0.375rem 0.625rem;
    font-size: 0.75rem;
  }

  .incident-info {
    flex-direction: column;
    gap: 0.5rem;
  }

  .timeline-title {
    font-size: 1rem;
  }

  .context-header h2,
  .outcome-header h2 {
    font-size: 1rem;
  }
}

/* Dark mode specific overrides */
[data-theme='dark'] .coaching-container {
  background-color: var(--bg-primary);
}

[data-theme='dark'] .coaching-header {
  background-color: var(--bg-primary);
  border-color: var(--border-color);
}

[data-theme='dark'] .coachable-moment.positive {
  color: #81c784;
}

[data-theme='dark'] .coachable-moment.negative {
  color: #e57373;
}

/* Right side analysis styles */
.coaching-content-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.analysis-wrapper {
  background: var(--card-bg);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--shadow-color);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.analysis-header {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--border-color);
}

.analysis-header h2 {
  color: var(--text-primary);
  font-size: 1.25rem;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.analysis-header h2 svg {
  color: var(--button-bg);
  font-size: 1.25rem;
}

.analysis-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(100vh - 280px); /* Limit height and enable scrolling */
}

.analysis-content::-webkit-scrollbar {
  width: 8px;
}

.analysis-content::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}

.analysis-content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
}

.analysis-content::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Chronological Events */
.chronological-events-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chronological-events-title svg {
  color: var(--button-bg);
}

.events-chronological {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Compact Event Cards with Coachable Moments */
.event-compact {
  background: var(--card-bg);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
  position: relative;
  margin-bottom: 0.875rem;
}

.event-compact:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px var(--shadow-color);
}

.event-compact.selected {
  border-color: var(--button-bg);
  box-shadow: 0 0 0 1px var(--button-bg);
  background-color: rgba(203, 45, 62, 0.05);
}

.event-compact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.event-compact-title {
  font-weight: 600;
  font-size: 0.875rem;
}

.event-compact-time {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  opacity: 0.7;
  background: var(--bg-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  white-space: nowrap;
}

.event-compact-time svg {
  font-size: 0.875rem;
}

.event-description {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  opacity: 0.9;
  line-height: 1.5;
}

.event-coachable-moments {
  margin-bottom: 0.75rem;
  background-color: var(--bg-secondary);
  border-radius: 0.375rem;
  padding: 0.5rem;
}

/* Event Actions */
.event-compact-actions {
  display: none;
}

/* Outcome Actions */
.outcome-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.75rem;
}

.outcome-action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.outcome-action:hover {
  background: var(--button-bg);
  color: var(--button-text);
  border-color: var(--button-bg);
}

.outcome-action.share-btn svg {
  font-size: 0.875rem;
}

/* Clip Notification Toast */
.clip-notification {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 1000;
  animation: slideUp 0.3s ease forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 1rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.notification-icon {
  font-size: 1.25rem;
}

/* Dark mode adjustments */
[data-theme='dark'] .event-compact-action:hover,
[data-theme='dark'] .outcome-action:hover {
  background: var(--button-bg);
  color: white;
}

/* Update responsive layouts */
@media (max-width: 768px) {
  .event-compact-actions,
  .outcome-actions {
    flex-direction: column;
  }

  .event-compact-action,
  .outcome-action {
    width: 100%;
  }
}

/* Adjust responsive rules for the new layout */
@media (max-width: 1400px) {
  .coaching-grid {
    grid-template-columns: 1fr;
  }

  .coaching-content-right {
    margin-bottom: 2rem;
  }

  .severity-groups {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .event-compact-timeline {
    flex-wrap: wrap;
  }

  .event-compact-duration-bar {
    order: 1;
    width: 100%;
    margin: 0.5rem 0;
  }

  .event-compact-timestamp:first-of-type {
    order: 2;
  }

  .event-compact-timestamp:last-of-type {
    order: 3;
  }

  .event-compact-play {
    order: 4;
    margin-left: auto;
  }
}

/* Category badges - simplify for single badge */
.category-badge,
.outcome-category-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.875rem;
  border-radius: 2rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: fit-content;
  margin: 0;
}

.category-badge svg,
.outcome-category-badge svg {
  font-size: 0.875rem;
}

/* Updated color scheme based on positive/negative */
.category-badge.positive,
.outcome-category-badge.positive {
  background-color: #4caf50;
}

.category-badge.negative,
.outcome-category-badge.negative {
  background-color: #f44336;
}

.category-badge.default,
.outcome-category-badge.default {
  background-color: #607d8b;
}

/* Coachable advice - direct display with clear styling */
.coachable-advice {
  background-color: var(--bg-secondary);
  padding: 0.875rem 1rem;
  border-radius: 0.5rem;
  border-left: 3px solid #f44336;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text-primary);
}

.advice-text {
  margin: 0;
  padding: 0;
}

/* Clean outcome section */
.outcome-section {
  background: var(--card-bg);
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  border-left: 4px solid #673ab7;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  position: relative;
  padding: 0.75rem 1rem 0.5rem 0.75rem;
  margin-bottom: 0.875rem;
}

.outcome-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.outcome-header {
  padding: 1rem 1.25rem 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.outcome-header h2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.outcome-icon {
  color: #673ab7;
}

.outcome-time {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: var(--text-primary);
  opacity: 0.8;
  margin-top: 0.5rem;
}

.outcome-content {
  padding: 1rem 1.25rem;
}

.outcome-category-badge {
  margin-bottom: 1rem;
}

.outcome-advice {
  background-color: var(--bg-secondary);
  padding: 0.875rem 1rem;
  border-radius: 0.5rem;
  border-left: 3px solid #673ab7;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text-primary);
}

.outcome-advice.positive {
  border-left-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.08);
}

.outcome-advice.negative {
  border-left-color: #f44336;
  background-color: rgba(244, 67, 54, 0.08);
}

.outcome-section.positive {
  border-left-color: #4caf50;
}

.outcome-section.negative {
  border-left-color: #f44336;
}

/* Collapsible details - less prominent */
details.event-details,
details.outcome-details {
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid var(--border-color);
  background-color: transparent;
}

details.event-details summary,
details.outcome-details summary {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--bg-secondary);
}

details.event-details summary::after,
details.outcome-details summary::after {
  content: '▼';
  position: absolute;
  right: 1rem;
  transition: transform 0.2s ease;
  font-size: 0.625rem;
  opacity: 0.7;
}

details[open] summary::after {
  transform: rotate(180deg);
}

details.event-details p,
details.outcome-details p {
  padding: 0.75rem 1rem;
  margin: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
}

/* Event compact header with prominent timestamp */
.event-time-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.875rem;
}

/* Prominent timestamp */
.event-timestamp {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--bg-secondary);
  font-weight: 500;
  color: var(--text-primary);
}

.time-icon {
  color: var(--button-bg);
  font-size: 1rem;
}

.timestamp-value {
  font-size: 0.9375rem;
  font-weight: 600;
}

.duration-badge {
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  margin-left: 0.375rem;
  font-weight: 500;
}

/* Event description styling */
.event-description-content {
  margin-bottom: 0.875rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-left: 3px solid var(--bg-secondary);
}

.event-description {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text-primary);
}

.event-description em {
  font-style: italic;
  color: var(--text-primary);
  opacity: 0.9;
}

/* Coachable advice styling */
.coachable-advice {
  background-color: var(--bg-secondary);
  padding: 0.875rem 1rem;
  border-radius: 0.5rem;
  border-left: 3px solid #f44336;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text-primary);
}

/* Outcome actions style */
.outcome-actions {
  display: none;
}

/* Super prominent quote styling without quotation marks */
.event-quote {
  color: var(--text-primary);
  font-weight: 600;
  display: block;
  background-color: rgba(203, 45, 62, 0.08);
  padding: 0.75rem 1rem;
  margin: 0.75rem 0;
  border-radius: 0.375rem;
  border-left: 4px solid var(--button-bg);
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Add a vertical line on the left for emphasis */
.event-quote::before {
  content: '';
  position: absolute;
  left: -4px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: var(--button-bg);
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

/* Compact event card container with side-by-side layout */
.event-compact-container {
  display: flex;
  width: 100%;
}

/* Badge container for left side */
.event-badge-container {
  flex-shrink: 0;
  width: 50px;
  margin-right: 0.75rem;
}

/* Vertical badge for category */
.category-badge-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.25rem;
  border-radius: 0.5rem;
  height: calc(100% - 0.5rem);
  margin-top: 0.25rem;
  text-align: center;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.category-badge-vertical svg {
  font-size: 1.25rem;
  margin-bottom: 0.375rem;
}

.category-badge-vertical span {
  font-size: 0.6875rem;
  font-weight: 600;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  margin-top: 0.375rem;
}

/* Content container for right side */
.event-content-container {
  flex: 1;
  min-width: 0;
}

/* Make cards more compact */
.event-compact {
  background: var(--card-bg);
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
  position: relative;
  margin-bottom: 0.875rem;
}

.event-time-header {
  margin-bottom: 0.625rem;
}

.event-description-content {
  padding: 0.625rem;
}

.event-compact-actions {
  margin-top: 0.625rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .event-compact-container {
    flex-direction: column;
  }

  .event-badge-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .category-badge-vertical {
    flex-direction: row;
    height: auto;
    padding: 0.5rem 0.75rem;
    justify-content: flex-start;
  }

  .category-badge-vertical svg {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }

  .category-badge-vertical span {
    writing-mode: horizontal-tb;
    transform: none;
    margin-top: 0;
  }
}

/* Compact row layout with horizontal badge on the left */
.event-compact-row {
  display: flex;
  width: 100%;
  gap: 0.75rem;
  align-items: flex-start;
}

/* Keep content flexible */
.event-content {
  flex: 1;
  min-width: 0;
}

/* Make cards more compact */
.event-compact {
  background: var(--card-bg);
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
  position: relative;
  margin-bottom: 0.875rem;
}

.event-time-header {
  margin-bottom: 0.625rem;
}

.event-description-content {
  padding: 0.625rem;
}

.event-compact-actions {
  margin-top: 0.625rem;
}

/* Responsive adjustments */
@media (max-width: 500px) {
  .event-compact-row {
    flex-direction: column;
  }

  .category-badge {
    margin-bottom: 0.5rem;
  }
}

.timeline-start,
.timeline-end {
  padding: 0 10px; /* Adjust the values as needed */
}

/* Style for compact share button in top right corner */
.event-compact-action.share-btn,
.outcome-share-btn {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  z-index: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.event-compact-action.share-btn span,
.outcome-share-btn span {
  display: none;
}

.event-compact-action.share-btn svg,
.outcome-share-btn svg {
  font-size: 1.125rem;
}

.event-compact-action.share-btn:hover,
.outcome-share-btn:hover {
  transform: scale(1.1);
  background-color: var(--button-hover-bg);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

/* Make event content take up the full width since button is absolute positioned */
.event-content {
  width: 100%;
  padding-right: 1.5rem; /* Add space for the share button */
}

/* Reduce padding on compact cards */
.event-compact {
  padding: 0.75rem 1rem 0.5rem 0.75rem;
}

/* Adjust action container since button is now absolute positioned */
.event-compact-actions {
  display: none; /* Hide the action container as it's not needed anymore */
}

/* Make events list handle the scrolling */
.events-chronological {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Ensure analysis content has scrolling behavior */
.analysis-content {
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .analysis-content {
    max-height: 60vh; /* Limit height on smaller screens */
  }
}

/* Next incident navigation button */
.next-incident-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.875rem;
  border-radius: 0.5rem;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto; /* Push to the right side */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.next-incident-btn:hover {
  background-color: var(--button-hover-bg);
  transform: translateX(2px);
}

.next-incident-btn:active {
  transform: translateX(0);
}

.next-incident-btn svg {
  font-size: 1.125rem;
}

/* Update incident info to accommodate the button */
.incident-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.875rem;
  color: var(--text-primary);
  align-items: center; /* Center items vertically */
}

/* Remove unused demo toggle button styles */
.demo-toggle-btn {
  display: none;
}
