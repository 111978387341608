.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.home-container h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.modern-button {
  font-family: 'Montserrat', sans-serif;
  background-image: linear-gradient(to right, #cb2d3e 0%, #ef473a 100%);
  border: none;
  color: white;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.modern-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .home-container h1 {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .modern-button {
    padding: 8px 20px; /* Slightly smaller padding */
    font-size: 14px; /* Smaller font size */
  }
}
