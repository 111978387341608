.shift-expanded-container {
  border: 1px solid var(--border-color);
  padding: 10px 10px 0 10px;
  height: 100%;
  box-shadow: 0 0 5px var(--shadow-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  /* max-height: 700px; */
}

.shift-expanded-header {
  display: flex;
  /* padding: 10px; */
  margin-bottom: 15px;
  /* flex-direction: column; */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition:
    background-color 0.1s ease,
    transform 0.1s ease;
  color: var(--text-primary);
}

/* .shift-expanded-header:active {
  transform: scale(0.99);
} */

.shift-expanded-collapse {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  min-width: 4%;
  color: var(--text-primary);
}

.shift-expanded-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  padding: 10px;
  /* height: fit-content; */
}

.shift-expanded-left,
.shift-expanded-right {
  display: flex;
  align-items: center;
}

.shift-expanded-left {
  width: 80%;
  justify-content: flex-start;
  gap: 50px;
}

.shift-expanded-right {
  width: 15%;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 20px;
  /* align-items: flex-end; */
}

.shift-expanded-personnel {
  font-weight: bold;
  color: var(--text-primary);
}

.shift-expanded-personnel {
  width: 20%; /* Adjust width to maintain alignment */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.shift-expanded-date {
  width: fit-content; /* Adjust width to maintain alignment */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  color: var(--text-primary);
}

.shift-expanded-personnel,
.shift-expanded-date,
.shift-expanded-escalations,
.shift-expanded-time {
  margin-right: 10px;
  color: var(--text-primary);
}

.shift-expanded-escalations {
  display: flex;
  min-width: 40px;
  align-items: center;
  justify-content: flex-start;
}

/* .shift-expanded-flags {
  display: flex;
  align-items: center;
  margin-right: 10px;
} */

.shift-expanded-time {
  position: relative;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  z-index: 1;
}

.shift-expanded-time sup {
  position: absolute;
  top: 2px;
  right: 18px;
  font-size: 0.8em;
  line-height: 1;
  color: var(--text-secondary);
}
