.report-editor-fullpage {
  position: fixed;
  top: 0;
  left: 60px;
  right: 0;
  bottom: 0;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  color: var(--text-primary);
}

.report-editor-header {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--bg-primary);
  box-shadow: 0 2px 12px var(--shadow-color);
  position: relative;
  z-index: 10;
  gap: 24px;
  border-bottom: 1px solid var(--border-color);
}

.header-left {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  color: var(--text-secondary);
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.back-button:hover {
  background-color: var(--hover-bg);
  color: var(--text-primary);
}

.stepper {
  display: flex;
  gap: 20px;
  margin: 0 auto;
}

.step {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.step.active {
  background-color: #cb2d3e;
  color: white;
}

.step-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.step.active .step-number {
  background-color: #fff;
  color: #cb2d3e;
}

.report-editor-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 76px);
  background-color: #f9fafb;
}

.left-panel,
.right-panel {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.left-panel {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid var(--border-color);
  min-width: 400px;
  background-color: var(--bg-primary);
  box-shadow: 2px 0 8px var(--shadow-color);
}

.report-editor-fullpage .video-list-header {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 16px;
}

.report-editor-fullpage .video-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  /* justify-content: center; */
  overflow-y: auto;
  width: 100%;
  border: none;
}

.video-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: 20px;
  background-color: var(--bg-primary);
  border: 2px solid var(--border-color);
}

.video-card.video-event {
  background-color: var(--bg-secondary);
}

.video-card.non-video-event {
  background-color: transparent;
  border: 2px solid transparent;
}

.video-card.viewed {
  border: 2px solid #cb2d3e;
}

.video-assigned-to {
  width: 100px;
  font-size: 12px;
  padding-right: 10px;
  flex-shrink: 0;
}

.video-card-content {
  display: flex;
  flex-grow: 1;
  /* justify-content: space-between; */
  align-items: center;
  overflow: hidden;
}

.video-time-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* flex-shrink: 0; */
  width: 220px;
}

.report-editor-fullpage .event-info-container {
  display: flex;
  align-items: start;
  flex-grow: 1;
  overflow: hidden;
  width: 100px;
  height: fit-content;
  margin-left: 20px;
}

.event-address-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.event-address {
  padding: 0;
  border-radius: 4px;
  font-size: 0.9em;
  width: auto;
}

.event-name {
  font-size: 0.69em;
  color: var(--text-secondary);
}

.video-checkbox {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.video-checkbox input[type='checkbox'] {
  display: none;
}

.video-checkbox label {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #cb2d3e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: transparent;
  transition: all 0.2s ease;
}

.video-checkbox input[type='checkbox']:checked + label {
  background-color: #cb2d3e;
  color: white;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--bg-secondary);
  width: 50%;
  min-width: 600px;
  padding: 20px;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  box-sizing: border-box;
}

.report-editor-fullpage .video-player-container,
.report-editor-video-player-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  /* min-height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.report-editor-fullpage .video-player-container video,
.report-editor-fullpage .transcript-container > *,
.report-editor-video-player-container video,
.report-editor-transcript-container > * {
  max-width: 100%;
  box-sizing: border-box;
}

.report-editor-fullpage .video-player-container {
  margin: 0 auto;
  max-width: calc(100% - 40px);
}

.report-editor-fullpage .video-player-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.report-editor-fullpage .transcript-container,
.report-editor-transcript-container {
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 300px;
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--shadow-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.report-editor-fullpage .transcript-container > *,
.report-editor-transcript-container > * {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.report-editor-transcript-container .transcript-box {
  width: 100% !important;
  height: 100% !important;
  max-height: none !important;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: none;
}

.report-editor-transcript-container .alert-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.report-editor-transcript-container .alert-transcript-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Transcript specific elements */
.report-editor-transcript-container .transcript-line {
  padding: 8px 12px;
  margin: 4px 0;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  color: var(--text-primary);
}

.report-editor-transcript-container .transcript-line:hover {
  background-color: var(--hover-bg);
}

.report-editor-transcript-container .transcript-line.active {
  background-color: var(--bg-secondary);
  border-left: 3px solid var(--button-bg);
}

.report-editor-transcript-container .transcript-timestamp {
  color: var(--text-secondary);
  font-size: 0.85em;
  margin-right: 8px;
}

.report-editor-transcript-container .transcript-text {
  color: var(--text-primary);
}

/* Transcript controls */
.report-editor-transcript-container .transcript-controls {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.report-editor-transcript-container .transcript-search {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  margin-right: 8px;
}

.report-editor-transcript-container .transcript-search:focus {
  outline: none;
  border-color: var(--button-bg);
  box-shadow: 0 0 0 2px rgba(203, 45, 62, 0.1);
}

/* Scrollbar styling */
.report-editor-transcript-container *::-webkit-scrollbar {
  width: 8px;
}

.report-editor-transcript-container *::-webkit-scrollbar-track {
  background-color: var(--bg-secondary);
  border-radius: 4px;
}

.report-editor-transcript-container *::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
}

.report-editor-transcript-container *::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-secondary);
}

.timeline-events {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.timeline-event {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-type {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.event-time,
.event-relative-time {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.event-transcript {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.segmented-control {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.segment {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  transition: all 0.3s ease;
}

.segment.active {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.narrative-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 0 0 8px 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.narrative-editor .tiptap {
  flex-grow: 1;
  padding: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-primary);
  min-height: 300px;
  background-color: var(--bg-primary);
}

.narrative-editor .tiptap:focus {
  outline: none;
  background-color: var(--bg-primary);
}

/* Add styles for the editor content to ensure proper dark mode */
.narrative-editor .tiptap p {
  color: var(--text-primary);
}

.narrative-editor .tiptap::placeholder {
  color: var(--text-secondary);
}

.report-editor-fullpage .map-container {
  width: 100%;
  max-width: 800px;
  height: 600px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.report-editor-fullpage .map-container .map {
  width: 100%;
  height: 100%;
}

.report-editor-fullpage .map-view-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: none;
}

.map-view {
  width: 100%;
  height: 100%;
}

.custom-marker-icon {
  background-color: #cb2d3e;
  border-radius: 50%;
  border: 2px solid white;
  width: 12px !important;
  height: 12px !important;
  margin-left: -6px !important;
  margin-top: -6px !important;
}

.custom-marker-icon.focus {
  background-color: #4a90e2;
  width: 16px !important;
  height: 16px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.report-editor-fullpage .leaflet-popup-content-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.report-editor-fullpage .leaflet-popup-content {
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.leaflet-popup-tip-container {
  display: none;
}

/* Event groups */

.location-group-summary {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 0.9em;
  color: #666;
}

.location-group-summary:hover {
  color: #333;
}

.location-count {
  margin-right: 10px;
}

.expand-prompt {
  font-size: 0.8em;
  color: #0066cc;
}

/* Narrative editor */

.narrative-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* margin-right: 10px; */
  gap: 16px;
}

.simplified-video-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: solid 2px transparent;
}

.simplified-video-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.simplified-video-card .video-assigned-to {
  font-weight: bold;
  width: auto;
  margin-right: 20px;
}

.generate-narrative-button {
  margin-left: auto;
  background-color: #cb2d3e;
  color: white;
  padding: 8px 16px;
}

.generate-narrative-button:hover {
  background-color: #a82432;
}

.generate-narrative-button:disabled {
  background-color: #cb2d3e;
  opacity: 0.7;
  cursor: not-allowed;
}

.generate-narrative-button:disabled:hover {
  opacity: 0.7;
  background-color: #cb2d3e;
}

/* Save */

/* In ReportEditor.css */
.report-save-section {
  display: flex;
  align-items: center;
}

.report-editor-fullpage .unsaved-changes,
.save-status {
  margin-right: 15px;
  font-size: 14px;
}

.report-editor-fullpage .unsaved-changes {
  color: #cb2d3e;
}

.save-status {
  color: #4caf50;
}

/* Save button styling with higher specificity */
.report-editor-fullpage .editor-button.save-button,
.report-editor-fullpage .save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #cb2d3e !important; /* Added !important to override MUI */
  color: white !important;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.report-editor-fullpage .editor-button.save-button:hover,
.report-editor-fullpage .save-button:hover {
  background-color: #a82432 !important;
}

.report-editor-fullpage .editor-button.save-button:disabled,
.report-editor-fullpage .save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.report-editor-fullpage .save-button .svg-inline--fa {
  font-size: 14px;
}

@media (max-width: 768px) {
  .report-editor-fullpage .save-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .report-editor-fullpage .save-button .svg-inline--fa {
    font-size: 12px;
  }
}

/* Audit log */

/* Audit log styles */
.audit-log {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 0;
  margin-right: 20px;
}

.audit-log-heading {
  color: #666;
  font-size: 1.1em;
  font-weight: normal;
  text-align: left;
  padding: 20px;
  margin-bottom: 0;
}

.audit-log-table {
  width: 100%;
  margin-bottom: 0;
  margin-right: 20px;
}

.audit-log-table .MuiTable-root {
  background-color: #f7f7f7;
}

.audit-log-table .MuiTableHead-root {
  background-color: #e8e8e8;
}

.audit-log-table .MuiTableCell-head {
  font-weight: bold;
  color: #333;
  background-color: #e8e8e8;
}

.audit-log-table .MuiTableCell-body {
  color: #666;
  background-color: #f7f7f7;
}

.audit-log-table .MuiTableRow-root:hover {
  background-color: #f0f0f0;
}

/* Remove box-shadow and border from the Paper component */
.audit-log-table.MuiPaper-root {
  box-shadow: none;
  border: none;
}

/* Remove the border-radius from the table container */
.audit-log-table.MuiTableContainer-root {
  border-radius: 0;
}

/* Adjust cell padding */
.audit-log-table .MuiTableCell-root {
  padding: 12px 16px;
}

/* Style the table header */
.audit-log-table .MuiTableHead-root .MuiTableCell-root {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

/* Style alternating rows */
.audit-log-table .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
  background-color: #fafafa;
}

/* Remove last row's bottom border */
.audit-log-table .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

/* FAB for listening */

.simplified-video-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: solid 2px transparent;
}

.simplified-video-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.listening-fab,
.copy-to-clipboard-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #cb2d3e;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.listening-fab:hover,
.copy-to-clipboard-button:hover {
  background-color: #a82432;
}

.listening-fab:active,
.copy-to-clipboard-button:active {
  transform: scale(0.95);
}

.generate-narrative-button {
  padding: 8px 16px;
  background-color: #cb2d3e;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-narrative-button:hover {
  background-color: #a82432;
}

.generate-narrative-button:disabled {
  background-color: #cb2d3e;
  opacity: 0.7;
  cursor: not-allowed;
}

.generate-narrative-button:disabled:hover {
  opacity: 0.7;
  background-color: #cb2d3e;
}

/* Chat */

/* In ReportEditor.css */

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.chat-message {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #cb2d3e;
  color: white;
}

.chat-message.bot {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}

.chat-input-container {
  display: flex;
  padding: 0;
  background: none;
  margin-right: 20px;
}

.chat-input {
  flex-grow: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  background-color: #f0f0f0;
}

.chat-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(203, 45, 62, 0.2);
}

.chat-send-button {
  background-color: #cb2d3e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-send-button:hover {
  background-color: #a82432;
}

.chat-disclaimer {
  text-align: center;
  font-size: 12px;
  color: #666;
  padding: 10px 20px;
}

.chat-input-container {
  display: flex;
}

/* Mobile */

@media (max-width: 768px) {
  .report-editor-fullpage {
    left: 0;
  }

  .report-editor-header {
    padding: 12px 16px;
  }

  .report-editor-content {
    flex-direction: column;
  }

  .left-panel {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .right-panel {
    display: none;
  }

  .left-panel {
    margin-right: 0;
    padding-right: 0;
    padding-top: 10px;
  }

  .segmented-control {
    width: fit-content;
    margin: 0;
  }

  .video-card {
    margin-right: 0;
  }

  .narrative-section {
    padding: 0 10px;
    /* margin: 0; */
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .simplified-video-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .simplified-video-info {
    margin-bottom: 10px;
  }

  .action-buttons {
    width: 100%;
    justify-content: space-between;
  }

  .evidence-detail {
    padding: 0;
    gap: 0;
  }

  .evidence-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .evidence-header .back-button {
    margin-right: 10px;
  }

  .evidence-header .segmented-control {
    flex-grow: 1;
  }

  .chat-messages {
    padding: 10px;
  }

  .chat-input-container {
    margin: 10px;
    margin-top: 0px;
  }

  .chat-disclaimer {
    font-size: 10px;
  }

  .audit-log {
    margin: 10px;
    margin-top: 10px;
  }

  .report-editor-fullpage .video-card {
    margin: 5px 10px;
  }

  .report-editor-fullpage .video-list {
    padding: 0;
    margin: 0;
    width: 100%;
    gap: 0px;
    margin-top: 5px;
  }

  .video-assigned-to {
    width: 50px;
    padding-right: 5px;
  }

  .video-time-container {
    transform: scale(0.75);
    width: 200px;
  }

  .evidence-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  .evidence-header-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
  }

  .back-to-evidence {
    background: none;
    border: none;
    color: #cb2d3e;
    font-size: 12px;
    padding: 5px 10px 5px 0;
    cursor: pointer;
    white-space: nowrap;
  }

  .back-to-evidence .svg-inline--fa {
    margin-right: 5px;
  }

  .report-editor-fullpage .unsaved-changes,
  .save-status {
    margin-right: 10px;
    font-size: 10px;
  }

  .mobile-segment {
    flex-grow: 1;
    max-width: none;
    margin-left: 10px;
  }

  .mobile-segment .segment {
    flex: 1;
  }

  /* Video player */

  .evidence-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
  }

  .report-editor-fullpage .video-player-container {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .report-editor-fullpage .video-player-container > div {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Ensure the video controls are visible and clickable */
  .report-editor-fullpage .plyr__controls {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .report-editor-fullpage .plyr__control--overlaid {
    display: none;
  }

  /* Adjust control sizes for better touch targets */
  .report-editor-fullpage .plyr__controls button {
    padding: 8px;
  }

  .report-editor-fullpage .plyr__progress__container {
    min-width: 50px;
  }

  /* Hide some controls to save space if needed */
  .plyr__control[data-plyr='captions'],
  .plyr__control[data-plyr='settings'] {
    display: none;
  }

  .map-button {
    width: 70px;
  }

  .narrative-toolbar {
    padding: 8px;
    flex-wrap: wrap;
  }

  .narrative-toolbar button {
    font-size: 13px;
    padding: 6px 12px;
  }

  .narrative-editor {
    border-radius: 0;
  }

  .narrative-editor .tiptap {
    padding: 16px;
  }

  .right-panel {
    min-width: unset;
    width: 100%;
    padding: 10px;
  }

  .report-editor-fullpage .video-player-container {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }

  .report-editor-fullpage .transcript-container {
    max-width: none;
    margin-top: 10px;
  }
}

/* Citations */

.editable-narrative {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  min-height: 100px;
  white-space: pre-wrap;
  overflow-y: auto;
}

.editable-narrative:focus {
  outline: none;
  border-color: #cb2d3e;
}

.citation {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.citation:hover {
  color: darkblue;
}

.citation.last-clicked {
  background-color: yellow;
  transition: background-color 0.5s ease;
}
.citation {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.citation:hover {
  color: darkblue;
}

/* Chat citations */

.chat-citation {
  display: inline-block;
  background-color: #ffe5e5;
  color: #b22;
  border-radius: 3px;
  padding: 0 3px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s ease;
}

.chat-citation:hover {
  background-color: #ffe0e0;
}

/* New toolbar for editing actions */
.narrative-toolbar {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px 8px 0 0;
  gap: 12px;
}

.narrative-toolbar button {
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.copy-to-clipboard-button {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  width: auto;
  height: auto;
  border-radius: 4px;
}

.copy-to-clipboard-button:hover {
  background-color: var(--button-secondary-hover);
  color: var(--text-primary);
}

.generate-narrative-button {
  margin-left: auto; /* Pushes this button to the right */
  background-color: #cb2d3e;
  color: white;
  padding: 8px 16px;
}

/* Editor container updates */
.editor-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--bg-primary);
  border-radius: 12px;
  box-shadow: 0 2px 8px var(--shadow-color);
  overflow: hidden;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  gap: 8px;
}

.editor-actions-left,
.editor-actions-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Update save button styling to match project */
.editor-button.save-button {
  background-color: var(--button-bg);
  color: var(--button-text);
}

.editor-button.save-button:hover {
  background-color: var(--button-hover-bg);
}

.unsaved-changes {
  color: var(--error-text);
  font-size: 14px;
}

.save-status {
  color: var(--success-text);
  font-size: 14px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .editor-header {
    flex-wrap: wrap;
    gap: 8px;
  }

  .editor-actions-left,
  .editor-actions-right {
    flex-wrap: wrap;
  }

  .unsaved-changes,
  .save-status {
    font-size: 12px;
  }
}

.copy-to-clipboard-button {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.copy-to-clipboard-button:hover {
  background-color: var(--button-secondary-hover);
  color: var(--text-primary);
}

.generate-narrative-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.generate-narrative-button:hover {
  background-color: var(--button-hover-bg);
}

.generate-narrative-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* New shared button style */
.editor-button {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.editor-button:hover {
  background-color: var(--button-secondary-hover);
  color: var(--text-primary);
}

/* Add mobile responsiveness */
@media (max-width: 768px) {
  .editor-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .editor-header {
    padding: 6px 12px;
  }
}

/* Ensure VideoView fits properly */
.report-editor-fullpage .video-player-container .event-detail-video-view-container {
  width: 100%;
  height: 100%;
  min-height: unset;
  min-width: unset;
}

.report-editor-fullpage .video-player-container .event-detail-video-player-container {
  width: 100%;
  height: 100%;
}

/* Map container */
.report-editor-fullpage .map-container {
  width: 100%;
  max-width: 800px;
  height: 600px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Ensure the map view itself fills the container */
.report-editor-fullpage .map-container .map {
  width: 100%;
  height: 100%;
}

/* Override MapView container styles only within report editor */
.report-editor-fullpage .map-view-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: none;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .right-panel {
    min-width: unset;
    width: 100%;
    padding: 10px;
  }

  .report-editor-fullpage .video-player-container {
    min-height: 300px;
  }

  .report-editor-fullpage .transcript-container {
    min-height: 200px;
  }

  .report-editor-fullpage .map-container {
    height: 400px;
    max-width: none;
  }
}

/* Toast notification styling */
.toast-notification {
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 12px var(--shadow-color);
  z-index: 9999;
  animation: slideIn 0.3s ease-out forwards;
}

.toast-notification.success {
  background-color: #4caf50;
  color: #ffffff;
}

.toast-notification.error {
  background-color: #cb2d3e;
  color: #ffffff;
}

.toast-notification .icon {
  font-size: 16px;
}

.toast-notification .message {
  font-size: 14px;
  font-weight: 500;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.toast-notification.hiding {
  animation: slideOut 0.3s ease-in forwards;
}

@media (max-width: 768px) {
  .toast-notification {
    bottom: 16px;
    right: 16px;
    left: 16px;
    padding: 10px 20px;
  }
}

/* Add title section */
.report-title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 24px;
}

.report-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.report-metadata {
  display: flex;
  align-items: center;
  gap: 24px;
  color: var(--text-secondary);
  font-size: 13px;
}

.report-metadata-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Mobile header adjustments */
@media (max-width: 768px) {
  .report-editor-header {
    padding: 12px 16px;
  }

  .back-button {
    padding: 6px 10px;
    font-size: 13px;
  }

  .back-button span {
    display: none;
  }

  .report-title-section {
    margin-left: 12px;
  }

  .report-title {
    font-size: 16px;
  }

  .report-metadata {
    gap: 16px;
    font-size: 11px;
  }
}

/* Narrative Selector Styles */
.narrative-selector {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
}

.narrative-option {
  flex: 1;
  padding: 24px;
  background-color: var(--bg-primary);
  border: 2px solid var(--border-color);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.narrative-option:hover {
  border-color: var(--button-bg);
  box-shadow: 0 4px 12px rgba(203, 45, 62, 0.1);
}

.narrative-option.selected {
  border-color: var(--button-bg);
  box-shadow: 0 4px 16px rgba(203, 45, 62, 0.15);
}

.narrative-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-color);
}

.narrative-option-title {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 18px;
}

.narrative-option-badge {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.narrative-option-content {
  color: var(--text-primary);
  font-size: 14px;
  line-height: 1.6;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 12px;
  margin-bottom: 16px;
  position: relative;
}

.narrative-option-footer {
  margin-top: auto;
  text-align: center;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.select-narrative-button {
  width: 100%;
  background-color: var(--bg-secondary);
  color: var(--button-bg);
  border: 2px solid var(--button-bg);
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.select-narrative-button:hover {
  background-color: var(--button-bg);
  color: var(--button-text);
}

/* Update the checkmark style for better visibility in dark mode */
.narrative-option.selected::before {
  content: '✓';
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: var(--button-bg);
  color: var(--button-text);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  transform: rotate(10deg);
  box-shadow: 0 2px 8px var(--shadow-color);
}

/* Mobile-optimized narrative selector */
@media (max-width: 768px) {
  .narrative-selector {
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    margin: 0;
    position: relative;
    height: 48px;
    background: white;
    border-bottom: 1px solid #eee;
  }

  .narrative-option {
    flex: 1;
    padding: 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: static;
    overflow: visible;
    background: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .narrative-option:hover {
    border: none;
    box-shadow: none;
    background-color: #f5f5f5;
  }

  .narrative-option.selected {
    border: none;
    box-shadow: none;
    background-color: #f5f5f5;
    position: relative;
  }

  .narrative-option.selected::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #cb2d3e;
  }

  .narrative-option-header {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .narrative-option-title {
    font-size: 14px;
    font-weight: 500;
  }

  .narrative-option-badge {
    display: none;
  }

  .narrative-option-content {
    display: none;
  }

  .narrative-option-footer {
    display: none;
  }

  .narrative-option.selected::before {
    display: none;
  }

  /* Editor container mobile optimization */
  .editor-container {
    border-radius: 0;
    box-shadow: none;
  }

  .editor-header {
    padding: 8px;
    flex-wrap: wrap;
  }

  .editor-actions-left,
  .editor-actions-right {
    width: 100%;
    justify-content: space-between;
  }

  .editor-button {
    padding: 8px;
    font-size: 12px;
  }

  .editor-button.save-button {
    width: 100%;
    justify-content: center;
    margin-top: 8px;
  }

  .unsaved-changes,
  .save-status {
    font-size: 12px;
  }

  /* Toast notification mobile optimization */
  .toast-notification {
    left: 16px;
    right: 16px;
    bottom: 16px;
    border-radius: 8px;
    padding: 12px;
  }

  .toast-notification .message {
    font-size: 13px;
  }
}

.report-editor-video-player-container {
  padding: 60px;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: var(--bg-secondary);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  position: relative;
}

/* Add these new styles for Plyr video specifically in report editor */
.report-editor-video-player-container .plyr {
  width: 100%;
  height: 100%;
  /* aspect-ratio: 16/9; */
}

.report-editor-video-player-container .plyr video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.report-editor-video-player-container .event-detail-video-view-container {
  width: 70%;
  height: 100%;
  min-width: unset;
  min-height: unset;
}

.report-editor-video-player-container .event-detail-video-player-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .report-editor-video-player-container {
    padding: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.report-editor-transcript-container {
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 300px;
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--shadow-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.report-editor-transcript-container .transcript-box {
  width: 100% !important;
  height: 100% !important;
  max-height: none !important;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: none;
}

.report-editor-transcript-container .alert-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.report-editor-transcript-container .alert-transcript-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Update editor button styles for better visibility */
.editor-button {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.editor-button:hover {
  background-color: var(--button-secondary-hover);
  color: var(--text-primary);
}

.editor-button.save-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
}

.editor-button.save-button:hover {
  background-color: var(--button-hover-bg);
}

.copy-to-clipboard-button {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.copy-to-clipboard-button:hover {
  background-color: var(--button-secondary-hover);
  color: var(--text-primary);
}

.generate-narrative-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.generate-narrative-button:hover {
  background-color: var(--button-hover-bg);
}

.generate-narrative-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Mobile floating copy button */
.mobile-copy-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #cb2d3e;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1000;
}

.mobile-copy-button:hover {
  background-color: #a82432;
  transform: scale(1.05);
}

.mobile-copy-button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .mobile-copy-button {
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    font-size: 20px;
  }
}

/* Mobile floating copy button */
.mobile-copy-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #cb2d3e;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1000;
}

.mobile-copy-button:hover {
  background-color: #a82432;
  transform: scale(1.05);
}

.mobile-copy-button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .mobile-copy-button {
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    font-size: 20px;
  }
}
