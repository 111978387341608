/* Styles for PersonnelAccounts component */

.personnel-accounts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--shadow-color);
}

.personnel-accounts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personnel-accounts-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.add-account-btn {
  padding: 8px 16px;
  background-color: var(--button-bg);
  color: white !important;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.add-account-btn:hover {
  background-color: var(--button-hover-bg);
  color: white !important;
}

.personnel-management-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-container {
  width: 300px;
}

.personnel-accounts-search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.personnel-table {
  width: 100%;
  /* max-height: 370px; */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--bg-secondary);
}

.personnel-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.personnel-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-secondary);
}

.personnel-table th,
.personnel-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.personnel-table th {
  font-weight: 500;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
}

/* Add this new style to ensure all table headers have consistent styling */
.personnel-table th .MuiTableSortLabel-root {
  color: var(--text-primary);
}

.personnel-table th .MuiTableSortLabel-root.Mui-active {
  color: var(--text-primary);
}

.personnel-table th .MuiTableSortLabel-icon {
  color: var(--text-primary) !important;
}

.personnel-table th:first-child {
  border-top-left-radius: 6px;
}

.personnel-table th:last-child {
  border-top-right-radius: 6px;
}

.personnel-table tr:last-child td {
  border-bottom: none;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.device-chips-container {
  display: flex;
  gap: 4px;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
}

.device-chip {
  background-color: #f0f0f0;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.875rem;
  white-space: nowrap;
}

.device-chip-more {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  color: #666666;
  white-space: nowrap;
}

/* MUI Button Override */
.MuiButton-containedPrimary {
  color: white !important;
}

.MuiButton-containedPrimary:hover {
  color: white !important;
}

.account-count {
  color: var(--text-secondary);
  font-size: 14px;
}

.personnel-device-tooltip {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  box-shadow: 0px 2px 6px var(--shadow-color) !important;
  font-size: 0.875rem !important;
  border: 1px solid var(--border-color) !important;
  padding: 8px 12px !important;
}

.personnel-device-tooltip .MuiTooltip-arrow {
  color: var(--bg-primary) !important;
}

.personnel-device-chip {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  color: var(--text-primary);
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

/* Add these new styles for the device ID column */
.personnel-table td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.device-id-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  max-width: 100%;
  overflow: hidden;
}

.personnel-form-dialog {
  padding: 24px;
  /* width: auto; */
}

.personnel-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 400px;
}

.personnel-info {
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  padding: 20px;
  gap: 15px;
  border-radius: 5px;
  /* margin-bottom: 30px; */
  border: 1px solid var(--border-color);
}

.personnel-icon {
  color: var(--text-secondary);
  margin-right: 10px;
  font-size: 1.5rem;
}

.personnel-info span {
  color: var(--text-primary);
}

.edit-btn .MuiIconButton-root {
  color: var(--border-inverse-color);
}

.edit-btn .MuiIconButton-root:hover {
  background-color: var(--shadow-color);
}

/* Replace pagination-controls with account-count-info */
.account-count-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.account-count {
  color: var(--text-secondary);
  font-size: 14px;
}

/* You can remove these pagination styles if you want
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.pagination-buttons {
  display: flex;
  gap: 8px;
}

.pagination-btn {
  padding: 6px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--bg-primary);
  color: var(--text-primary);
  cursor: pointer;
}

.pagination-btn:hover {
  background: var(--hover-bg);
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: var(--bg-secondary);
  color: var(--text-secondary);
}
*/

/* Style the device selection table in add/edit dialogs */
.device-selection .MuiTableContainer-root {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
}

.device-selection .MuiTable-root {
  background-color: var(--bg-primary);
}

.device-selection .MuiTableHead-root {
  background-color: var(--bg-secondary);
}

.device-selection .MuiTableHead-root .MuiTableCell-root {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.device-selection .MuiTableBody-root .MuiTableCell-root {
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
}

.device-selection .MuiTableBody-root .MuiTableRow-root:hover {
  background-color: var(--hover-bg);
}

.device-selection .MuiCheckbox-root {
  color: var(--text-secondary);
}

.device-selection .MuiCheckbox-root.Mui-checked {
  color: var(--button-bg);
}
