/* Styles for SectionsTab component */

.sections-tab-container {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  background: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
}

.section-tab {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.section-tab i {
  font-size: 16px;
  opacity: 0.8;
}

.section-tab:hover {
  background: var(--hover-bg);
}

.section-tab.active {
  background: var(--bg-primary);
  color: var(--text-primary);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.section-tab.active i {
  opacity: 1;
}

@media (max-width: 768px) {
  .sections-tab-container {
    width: 100%;
    flex-wrap: wrap;
  }

  .section-tab {
    flex: 1 1 auto;
    justify-content: center;
  }
}
