.shift-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 25px 0 25px;
  width: auto;
  border-bottom: 2px solid var(--border-color);
  background-color: var(--bg-primary);
}

.shift-toggle-buttons {
  display: flex;
}

.shift-toggle-button {
  background: none;
  border: none;
  font-size: 16px;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 20px;
  position: relative;
  transition: all 0.2s ease;
  text-align: left;
}

.shift-toggle-button:hover {
  color: var(--text-primary);
}

.shift-toggle-button.selected {
  color: var(--text-primary);
}

.shift-toggle-button.selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--button-bg);
  transition: width 0.2s ease;
}

.shift-toggle-button:focus {
  outline: none;
}

.shift-controls {
  position: relative;
}

.shift-controls-button {
  background: none;
  border: none;
  font-size: 16px;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.shift-controls-button:hover {
  color: var(--text-primary);
}

/* .shift-controls-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 10;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
} */

.dropdown-header {
  padding: 15px;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: var(--hover-bg);
}

.dropdown-item.selected {
  background-color: var(--hover-bg);
  font-weight: 600;
  color: var(--button-bg);
}

.dropdown-item-check {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  color: var(--button-bg);
}

.dropdown-item-name {
  flex-grow: 1;
}

/* Add a custom scrollbar for webkit browsers */
.shift-controls-dropdown::-webkit-scrollbar {
  width: 8px;
}

.shift-controls-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.shift-controls-dropdown::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.shift-controls-dropdown::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.dropdown-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.dropdown-action-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-secondary);
  padding: 5px 10px;
  transition: all 0.2s ease;
}

.dropdown-action-button:hover {
  background-color: var(--hover-bg);
  color: var(--text-primary);
}
